import React, { Component } from 'react';
import DataGrid,
{
    Column,
    Paging,
    FilterRow,
    Export,
    SearchPanel
} from 'devextreme-react/data-grid';
import { Button } from 'react-bootstrap';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import { sendRequest } from '../../_helper/util.js';
import { Workbook, numFmt } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import moment from 'moment';
import './report_overview.scss';


const url = process.env.REACT_APP_API_URL + "/api";

class reconciliationreport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // reportData: new CustomStore({
            //     key: 'reportCode',
            //     load: this.generateReport,
            // }),
            startDate: new Date(),
            endDate: new Date(),
            isVisible: false
        };

        this.generateReport = this.generateReport.bind(this);
        this.onExporting = this.onExporting.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.minChangeHandler = this.minChangeHandler.bind(this);
        this.maxChangeHandler = this.maxChangeHandler.bind(this);
    }

    generateReport = () => {
        var { startDate, endDate } = this.state;

        return sendRequest(url + "/Lookup/GenerateReportReconciliation?startDate=" + startDate.toJSON() + "&endDate=" + endDate.toJSON(), 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    reportData: myJson.report
                });
                this.dataGrid.instance.refresh();
            }
        })
        .catch(err => console.error(err));;
    }

    onExporting = (e) => {
        var { startDate, endDate } = this.state;

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet');

        const currencyColumns = [9, 10, 11, 12, 13, 14, 15];
        for (let i = 0; i < currencyColumns.length; i++) {
            worksheet.getColumn(currencyColumns[i]).numFmt = '0.00';
        };
        
        var filename = "report_reconciliation" + moment(startDate).format('YYYYMMDD') + "_" + moment(endDate).format('YYYYMMDD') + "_" + Date.now().toString() + ".xlsx";
    
        exportDataGrid({
            component: this.dataGrid.instance,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
            })
            .catch(err => console.error(err));;
        })
        .catch(err => console.error(err));;
        // this.dataGrid.cancel = true;
    }

    minChangeHandler = function(e) {
        this.setState({ 
            startDate: e.value
        });
    }
 
    maxChangeHandler = function(e) { 
        this.setState({ 
            endDate: e.value
        });
    }

    onContentReady = (e) => {
        if (e.component.getVisibleRows().length === 0) {
            this.setState({ isVisible: false });
        }
        else {
            this.setState({ isVisible: true });
        }
    }

    onToolbarPepraring = (e) => {
        let toolbarItems = e.toolbarOptions.items;

        // Modifies an existing item
        toolbarItems.forEach(function(item) {                    
            if (item.name === "searchPanel") {
                item.location = "before";
            }
        });
    }

    formatDate(e) {
        
        if (e.valueText != "")
        {
            return e.value.split("T")[0];
        }
        else
        {
            return "";
        }
    }

    render() {
        const { reportData, isVisible } = this.state;

        return (
            <div>
                <br />
                <div className="row align-items-center justify-content-start">
                    <div className="col-auto" style={{ fontWeight: "bold", fontSize: "16px", color: "#616161" }}> From: </div>
                    <div className="col-2" style={{ padding: "0px 20px 0px 0px" }}>
                        <DateBox
                            defaultValue={new Date().getTime()}
                            placeholder="00/00/0000"
                            useMaskBehavior={true}
                            type="date"
                            displayFormat="shortdate"
                            applyValueMode="useButtons"
                            openOnFieldClick={true}
                            stylingMode="filled"
                            value={this.state.startDate}
                            max={this.state.endDate}
                            onValueChanged={this.minChangeHandler}
                        >
                            <DateBoxButton name="dropDown" />
                        </DateBox>
                    </div>

                    <div className="col-auto" style={{ fontWeight: "bold", fontSize: "16px", color: "#616161" }}> To: </div>
                    <div className="col-2" style={{ padding: "0px 20px 0px 0px" }}>
                        <DateBox
                            defaultValue={new Date().getTime()}
                            placeholder="00/00/0000"
                            useMaskBehavior={true}
                            type="date"
                            displayFormat="shortdate"
                            applyValueMode="useButtons"
                            openOnFieldClick={true}
                            stylingMode="filled"
                            value={this.state.endDate}
                            min={this.state.startDate}
                            onValueChanged={this.maxChangeHandler}
                        >
                            <DateBoxButton name="dropDown" />
                        </DateBox>
                    </div>
                    <div className="col-auto">
                        <Button variant="secondary" onClick={this.generateReport}>Generate Report</Button>
                    </div>
                    {isVisible && <div className="col" style={{textAlign: "right"}}>
                        <Button className="green-button" onClick={this.onExporting} >Export Report to Excel</Button>
                    </div>}
                </div>
                <DataGrid
                    id="gridContainer"
                    dataSource={reportData}
                    showBorders={true}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    allowSorting={true}
                    columnResizingMode="widget"
                    onContentReady={this.onContentReady}
                    onToolbarPreparing={this.onToolbarPepraring}
                    ref={ref=>this.dataGrid=ref}
                    style={{paddingTop: "13px"}}
                >
                    <SearchPanel visible={true} />
                    <Export enabled={false} />
                    <FilterRow visible={false} />
                    <Paging enabled={true} pageSize={25} />
                    
                    <Column dataField="certNumber" caption="CertNumber" />
                    <Column dataField="apn" caption="APN" />
                    <Column dataField="ownerName" caption="OwnerName" width={150} />
                    <Column dataField="busMgmtName" caption="BusMgmtName" width={150}/>
                    <Column dataField="rentalCity" caption="RentalCity" />
                    <Column dataField="mailingCity" caption="MailingCity" />
                    <Column dataField="reportingPeriod" />
                    <Column dataField="accountingPeriod" />
                    <Column dataField="remitToHOST" caption="Tax Collected and remitted through Online Platform - Remitted to HOST" width={100} allowSorting={false} />
                    <Column dataField="remitToCounty" caption="Tax Collected and remitted through Online Platform - Remitted to County" width={100} allowSorting={false} />
                    <Column dataField="taxCollectedOnlinePlatformAmt" caption="TaxCollectedOnlinePlatformAmt" width={100} allowSorting={false} />
                    {/*<Column dataField="taxPayableByOperator" width={100} allowSorting={false} />*/}
                    <Column dataField="taxPayableAmt" caption="Tax Payable By Operator" />
                    {/*<Column dataField="totalPaymentDue" allowSorting={false} />*/}
                    {/*<Column dataField="totalPaymentAmt" caption="Total Payment Due" />
                    <Column dataField="totalAmountPaid" caption="TotalAmountPaid" allowSorting={false} />*/}
                    <Column dataField="totalPaymentAmt" caption="Total Payment Due" />
                    <Column dataField="paymentAmount" caption="Payment Amount" allowSorting={false} />
                    {/*<Column dataField="paidDate" caption="Date Paid" customizeText={this.formatDate}/>
                    <Column dataField="receiptDate" caption="Date Receipted" customizeText={this.formatDate}/>*/}
                    <Column dataField="paidDate" caption="Date Paid" />
                    <Column dataField="receiptDate" caption="Date Receipted" />
                    <Column dataField="sourceSystem" caption="SourceSystem" />
                </DataGrid>
                
            </div>
        );
    }
}

export default reconciliationreport;