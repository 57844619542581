import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import TOnlinePlatformList from '../../components/online-platforms/onlineplatform_overview';

function ManageOnlinePlatform(props) {
    return (
        <div className="content-block">        
            <PageHeader header="Manage Online Platform" />
            <TOnlinePlatformList></TOnlinePlatformList>
        </div>
    );
}

export default ManageOnlinePlatform;