import React from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
//import { sendRequest } from '../../_helper/util.js';
import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";
//const url = process.env.REACT_APP_API_URL + "/api";

export default class UserPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //contacts: []
            userinfo: {}
        };
    }

    componentDidMount() {
        //debugger;
        //sendRequest(url + '/Users/Authenticate', 'POST')
        //    .then(data => {

        //        this.setState({ contacts: data });
        //       // debugger;
        //    })
        //    .catch(console.log)

        //get data from localstorage and decrypt it
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');
        this.setState({ userinfo: userinfo });
    }


    render() {
        const { menuMode, menuItems } = this.props;
        //const { contacts } = this.state;
        const { userinfo } = this.state;
        const userName = userinfo.user ? userinfo.user.firstName+" "+userinfo.user.lastName : 'User Name';
        return (
            <div className={'user-panel'}>
                <div className={'user-info'}>
                    <div className={'image-container'}>
                        <div className={'user-image'} />
                    </div>
                    <div className={'user-name'}>{userName}</div>
                </div>

                {menuMode === 'context' && (
                    <ContextMenu
                        items={menuItems}
                        target={'.user-button'}
                        showEvent={'dxclick'}
                        width={170}
                        cssClass={'user-menu'}
                    >
                        <Position my={'top center'} at={'bottom center'} />
                    </ContextMenu>
                )}
                {menuMode === 'list' && (
                    <List className={'dx-toolbar-menu-action'} items={menuItems} />
                )}
            </div>
        );
    }
}
