import DataGrid, { Column, Button as GridButton,
    Paging,
    FilterRow,
    MasterDetail } from 'devextreme-react/data-grid'
import React, { useEffect, useState } from 'react';
import { sendRequest } from '../../_helper/util';
import CustomStore from 'devextreme/data/custom_store';
import PageHeader from '../header/PageHeader/PageHeader';
//import amountRemainingCell from './amountRemainingCell';
import CheckBox from 'devextreme-react/check-box';
import OverPaymentDetails from './OverPaymentDetails.js';

function OverpaymentsList(props) {
    const [overPaymentsData, setoverPaymentsData] = useState();
    const [includeExhausted, setIncludeExhausted] = useState(false);

    useEffect(() => {
        setIncludeExhausted(true);
        setoverPaymentsData(new CustomStore({
            key: "",
            load: loadAllOverpayments
        }))
    }, [])

    const loadAllOverpayments = () => {
        const endpointUrl = process.env.REACT_APP_API_URL + `/api/Overpayments/GetAllOverpayments/${includeExhausted}`;
        return sendRequest(endpointUrl, "GET", '')
            .then(response => response);
    }

    const includeExhaustedOverpaymentsValueChanged = e => {
        setIncludeExhausted(!includeExhausted);
        setoverPaymentsData(new CustomStore({
            key: "",
            load: loadAllOverpayments
        }));
    }

    return (
        <>
            <PageHeader header="Overpayments (Credits)" />
            <div className="option">
                <CheckBox text="Exclude exhausted overpayment records" style={{marginLeft: "0"}}
                value={includeExhausted}
                onValueChanged={includeExhaustedOverpaymentsValueChanged} />
            </div>
            <br />
            <DataGrid
                id="gridOverPayments"
                keyExpr="overPaymentId"
                dataSource={overPaymentsData}
                showBorders={true}
                showRowLines={true}
                rowAlternationEnabled={true}
            >
                <FilterRow visible={true} />
                <Paging enabled={true} />

                <Column dataField="certNumber" caption="Cert #" allowEditing={false} width="100px" />
                <Column dataField="taxReturnReportingQuarter" caption="Reporting Quarter" allowEditing={false} width="200px" />
                <Column dataField="taxReturnStatus" caption="Tax Return Status" width="200px" ></Column>
                <Column dataField="paymentAmount" caption="Payment Amount" dataType="number" format={{ type: "currency", precision: 2 }} ></Column>
                <Column dataField="overPaymentAmount" caption="Overpaid Amount" dataType="number" format={{ type: "currency", precision: 2 }} ></Column>
                <Column dataField="creditAmountUsed" caption="Credit Amount Used" dataType="number" format={{ type: "currency", precision: 2 }} ></Column>
                {/*<Column caption="Credit Amount Remaining" cellRender={amountRemainingCell} alignment="right"></Column>*/}
                <Column dataField="amountRemaining" caption="Credit Amount Remaining" dataType="number" format={{ type: "currency", precision: 2 }}></Column>
                <MasterDetail
                    enabled={true}
                    component={OverPaymentDetails}
                />
            </DataGrid>
        </>
    );
}

export default OverpaymentsList;