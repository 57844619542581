import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import ReportList from '../../components/report/report_mail_merge_overview';

function ReportMailMerge(props) {
    return (
        <div className="content-block">
        
            <PageHeader header="Mail Merge Report" />
            <ReportList></ReportList>
        </div>
    );
}

export default ReportMailMerge;