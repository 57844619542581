import React from 'react';
import TemplateList from '../../components/Template/TemplateList';

function ManageTemplates(props) {
    return (
        <div className="content-block">        
             <TemplateList></TemplateList>
        </div>
    );
}

export default ManageTemplates;