import React, { Component } from 'react';
import DataGrid,
{
    Column,
    Paging,
    FilterRow,
    Export,
    SearchPanel
} from 'devextreme-react/data-grid';
import { Button } from 'react-bootstrap';
import { sendRequest } from '../../_helper/util.js';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import CustomStore from 'devextreme/data/custom_store';
import './report_overview.scss';


const url = process.env.REACT_APP_API_URL + "/api";

class mailmergereport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reportData: new CustomStore({
                key: 'reportCode',
                load: this.generateReport,
            })
        };

        this.generateReport = this.generateReport.bind(this);
        this.onExporting = this.onExporting.bind(this);
    }

    generateReport = () => {
        return sendRequest(url + "/Lookup/Generatemailmergereport", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    reportData: myJson.report
                });
                this.dataGrid.instance.refresh();
            }
        })
        .catch(err => console.error(err));;
    }

    onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet');
        var filename = "report_mailmerge" + "_" + Date.now().toString() + ".xlsx";
    
        exportDataGrid({
            component: this.dataGrid.instance,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
            })
            .catch(err => console.error(err));;
        })
        .catch(err => console.error(err));;
        // this.dataGrid.cancel = true;
    }

    onToolbarPepraring = (e) => {
        let toolbarItems = e.toolbarOptions.items;

        // Modifies an existing item
        toolbarItems.forEach(function(item) {                    
            if (item.name === "searchPanel") {
                item.location = "before";
            }
        });
    }

    render() {
        const { reportData } = this.state;

        return (
            <div>
                <br />
                <div className="row align-items-center justify-content-start">
                    {/* <div className="col-auto">
                        <Button variant="secondary" onClick={this.generateReport}>Generate Report</Button>
                    </div> */}
                    <div className="col" style={{textAlign: "right"}}>
                        <Button className="green-button" onClick={this.onExporting} >Export Report to Excel</Button>
                    </div>
                </div>
                <DataGrid
                    id="gridContainer"
                    dataSource={reportData}
                    showBorders={true}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    allowSorting={true}
                    columnResizingMode="widget"
                    onToolbarPreparing={this.onToolbarPepraring}
                    ref={ref=>this.dataGrid=ref}
                    style={{paddingTop: "13px"}}
                >
                    <SearchPanel visible={true} />
                    <Export enabled={false} />
                    <FilterRow visible={false} />
                    <Paging enabled={true} pageSize={25} />

                    <Column dataField="status"/>
                    <Column dataField="facilityOwner" caption="FacilityOwner" width={210} />
                    <Column dataField="certNumber" caption="Cert#"/>
                    <Column dataField="addressNameDBA" caption="AddressNameDBA" width={190} />
                    <Column dataField="attention" />
                    <Column dataField="address" width={230}  />
                    <Column dataField="city" />
                    <Column dataField="state" />
                    <Column dataField="zipCode" caption="ZIPCode" />
                    <Column dataField="situsCommunity" width={170} caption="SitusCommunity" />
                    <Column dataField="taxRateArea" />
                </DataGrid>
                
            </div>
        );
    }
}

export default mailmergereport;