import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ScrollView, TabPanel } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
// import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import { Col, Row } from 'react-bootstrap';
import TaxReturnAttachments from './TaxReturnAttachments';
import { Info, InfoBold, InfoTotal, InfoSpecial, Section } from './TaxReturnComponents';
import { sendRequest, sendPublicRequest } from '../../_helper/util';
import { formatCurrency, formatPhoneNumber, formatAPN } from '../../_helper/helpers';
import AuthService from '../../services/AuthService';
import TaxReturnPayments from './TaxReturnPayments';
import TaxReturnInfo from '../TOTTaxReturn/TaxReturn_Info';
import TaxReturnNotes from './TaxReturnNotes';
import { confirm } from 'devextreme/ui/dialog';
import '../../pages/CertApp/CertAppPage.scss';
import { Item } from 'devextreme-react/tab-panel';
import PropTypes from 'prop-types';
import EffectiveDate from './EffectiveDate';


TaxReturnDetails.propTypes = {
    taxReturnId: PropTypes.number.isRequired,
    fromPage: PropTypes.string.isRequired
};

const apiUrl = process.env.REACT_APP_API_URL;
// var effectiveDate = new Date();

function TaxReturnDetails(props) {
    const history = useHistory();
    const { taxReturnId, fromPage } = props;
    const userId = AuthService.getTOTUser().user.employeeId;
    const [details, setDetails] = useState();
    const [latestDateOfFee, setLatestDateOfFee] = useState(new Date(1800, 1, 1));
    const [listTaxReturnOnlinePlatformAmounts, setListTaxReturnOnlinePlatformAmounts] = useState([]);
    const [effectiveDate, setEffectiveDate] = useState(new Date());
    const [isRefreshPayments, setIsRefreshPayments] = useState(false);

    useEffect(() => {
        setEffectiveDate(new Date());
        refreshDetails();
    }, [])

    const onChangeEffectiveDate = (e) => {
        var newDate = new Date(e.value);
        setEffectiveDate(newDate);
    }

    const getDetails = () => {
        const endpointUrl = apiUrl + "/api/TaxReturn/GetTaxReturn?taxReturnId=" + taxReturnId + "&effectiveDate=" + effectiveDate.toJSON();
        return sendRequest(endpointUrl, "GET", '').then(res => setDetails(res));
    }

    const getLatestDateOfFee = () => {
        const endpointUrl = apiUrl + `/api/TaxReturn/GetLatestDateOfFee/${taxReturnId}`;
        return sendPublicRequest(endpointUrl, "GET", '')
            .then(res => setLatestDateOfFee(new Date(res.latestDateOfFee.split("T")[0].replace(/-/g, "/"))));
    }

    const getListTaxReturnOnlinePlatformAmounts = () => {
        const endpointUrl = apiUrl + `/api/TaxReturn/GetListTaxReturnOnlinePlatformAmounts/${taxReturnId}`;
        return sendPublicRequest(endpointUrl, "GET", '')
            .then(res => setListTaxReturnOnlinePlatformAmounts(res.listTaxReturnOnlinePlatformAmounts));
    }

    const handleMarkAsReviewed = () => {
        const endpointUrl = apiUrl + `/api/TaxReturn/MarkAsReviewed/${taxReturnId}/${userId}`;
        return sendRequest(endpointUrl, "POST", '').then(res => getDetails(taxReturnId));
    }

    const handleReject = (e) => {
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to reject this tax return?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {
                    const endpointUrl = apiUrl + `/api/TaxReturn/UpdateStatus/${taxReturnId}/${userId}/RPN`;
                    return sendRequest(endpointUrl, "POST", '')
                        .then(() => history.push(`/RejectTaxReturn/${taxReturnId}/RPN/${fromPage}`))

                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    const handleDateRefresh = () => {
        // recalculate total amount, interest, penalty using new effective Date
        if (details.paymentStatusCode !== "PA" && details.paymentStatusCode !== "RE") {
            sendPublicRequest(apiUrl + "/api/TaxReturn/CalculateAmts?propertyId=" + details.propertyId +
                "&taxReturnId=" + details.taxReturnId +
                "&taxPayableAmt=" + details.taxPayableAmt +
                "&yearQuarterId=" + details.reportingYearQuarterId +
                "&effectiveDate=" + effectiveDate.toJSON(), 'GET', '').then(myJson => {
                    if (typeof myJson.message !== "undefined") {
                        throw myJson.message;
                    }
                    else {
                        let latePaymentPenaltyAmt = myJson.penaltyAmt;
                        let interestAmt = myJson.interestAmt;
                        let previousReturnCreditChargeAmt = myJson.previousReturnCreditChargeAmt;
                        let sumPaymentItems = myJson.sumPaymentItems;
                        let totalPaymentAmt = details.taxPayableAmt + interestAmt + latePaymentPenaltyAmt + previousReturnCreditChargeAmt;
                        let outstandingBalanceAmt = totalPaymentAmt - sumPaymentItems;
                        setDetails({
                            ...details,
                            penaltyFee: latePaymentPenaltyAmt,
                            interestFee: interestAmt,
                            previousReturnCreditChargeAmt: previousReturnCreditChargeAmt,
                            totalPaymentAmt: totalPaymentAmt,
                            outstandingBalanceDue: outstandingBalanceAmt
                        });
                    }
                });
        }
    }

    const onInitialized = (e) => {
        handleDateRefresh();
    }

    const refreshDetails = () => {
        getDetails();
        getLatestDateOfFee();
        getListTaxReturnOnlinePlatformAmounts();
    }

    const refreshPaymentsComponent = () => {
        // This is here to allow the parent (TaxReturnDetails) to call this component and 
        // refresh the payments.   The situation of when this would need to happen is when 
        // a credit is applied to this tax return.  Which is triggered by a button on the (TaxReturnDetails)
        // screen.
        setIsRefreshPayments(true);
        setIsRefreshPayments(false);
    }

    const saveAmounts = () => {
        const endpointUrl = apiUrl + `/api/TaxReturn/SaveAmounts/${taxReturnId}
                                                                /${userId}
                                                                /${details.reportingYearQuarterId}
                                                                /${details.totalPaymentAmt}
                                                                /${details.penaltyFee}
                                                                /${details.interestFee}
                                                                /${effectiveDate.toJSON()}`;
        return sendRequest(endpointUrl, "POST", '');
    }

    const handleClickPending = (e) => {
        var statusCode = "PNT";
        const endpointUrl = apiUrl + `/api/TaxReturn/UpdateStatus/${taxReturnId}/${userId}/${statusCode}`;
        return sendRequest(endpointUrl, "POST", '')
            .then(res => history.push(`/RejectTaxReturn/${taxReturnId}/${statusCode}/${fromPage}`));
    }

    const handleApprove = (e) => {
        var statusCode = "ACT";
        const endpointUrl = apiUrl + `/api/TaxReturn/UpdateStatus/${taxReturnId}/${userId}/${statusCode}`;
        return sendRequest(endpointUrl, "POST", '')
        .then(res => refreshDetails());
    }

    const handleApplyAvailableCredit = (e) => {
        var amountDue = (details.outstandingBalanceDue - details.previousReturnCreditChargeAmt);
        const endpointUrl = apiUrl + `/api/TaxReturn/ApplyAvailableCredit/${taxReturnId}/${amountDue}/${userId}/${effectiveDate.toJSON()}`;
        //sendRequest(endpointUrl, "POST", '');
        //return sendRequest(endpointUrl, "POST", '')
        //    .then(res => { refreshDetails(); return true; }).then(ref => refreshPaymentsComponent());



        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to apply available Credits to this Tax Return?", "Confirm Credit");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    return sendRequest(endpointUrl, "POST", '')
                        .then(res => { refreshDetails(); return true; }).then(ref => refreshPaymentsComponent());
                } else {
                    return resolve(true);
                }
            });
        });    
    }

    const showApplyAvailableCreditButton = (previousReturnCreditChargeAmt, taxReturnStatus) => {
        if (taxReturnStatus !== "Paid" && previousReturnCreditChargeAmt < 0) {
            return true;
        }
        else {
            return false;
        }

    }

    const effectiveDateBox = () => {
        return <>
            {details.paymentStatusCode !== "PA" && details.paymentStatusCode !== "RE" && // can only have effective date option if unpaid or partially paid
                <div className="row align-items-center justify-content-end">
                    <div className="col-md-auto" style={{ fontWeight: "bold", color: "#616161" }}> Effective Date </div>
                    <div className={(details.paymentStatusCode !== "PA" && details.paymentStatusCode !== "RE") ? "col-4" : "col-4"}
                        style={{ padding: (details.paymentStatusCode !== "PA" && details.paymentStatusCode !== "RE") ? "0px" : "0px 12px 0px 0px" }}
                    >
                        <EffectiveDate
                            latestDateOfFee={latestDateOfFee}
                            effectiveDate={effectiveDate}
                            setEffectiveDate={setEffectiveDate}
                            readOnly={details.paymentStatusCode === "PA" || details.paymentStatusCode === "RE"}
                            height="29px"
                            onChangeEffectiveDate={onChangeEffectiveDate}
                        />
                    </div>
                    <div className="col-md-auto" style={{ padding: "0px 12px 0px 1px" }}>
                        <Button
                            type="default"
                            name="refresh"
                            className="btn-sm blueButton"
                            onClick={handleDateRefresh}
                        >Refresh</Button>
                    </div>
                </div>
            }
        </>
    }

    const taxReturnInfo = () => {
        return (<>
            <Section subtitle={<>&nbsp;</>} subtitle_right={effectiveDateBox()}>
                <br />
                <TaxReturnInfo
                    data={details}
                    taxRate={details.taxRate}
                    penaltyAmt={details.penaltyFee}
                    interestAmt={details.interestFee}
                    taxReturnOnlinePlatform={listTaxReturnOnlinePlatformAmounts}
                    displayUploadedFiles={false}
                />
                <br />
                <hr className="separateLine" />
                <Row>

                    <Col>
                        <div style={{ marginBottom: '5px' }}><b>Prepared By: </b>{details.preparedBy}</div>
                        <div style={{ marginBottom: '5px' }}><b>Phone Number: </b>{formatPhoneNumber(details.phoneNumber)}</div>
                    </Col>
                    <Col>
                        <div><b>Title: </b>{details.title != null && details.title}</div>
                    </Col>
                    <Col className="col-5">
                        {/* <Info label="Status" value={details.status} /> */}
                        <div className="row align-items-center justify-content-end">

                            {!details.hasBeenReviewed &&
                                <div className="col-md-auto">
                                    <div className="d-flex justify-content-end">
                                        <Button className="btn btn-sm float-right approveButton" onClick={handleMarkAsReviewed}>Reviewed</Button>
                                    </div>
                                </div>
                            }
                            {details.taxReturnStatus.code !== "APV" &&
                                <div className="col-md-auto" >
                                    <div className="d-flex justify-content-end">
                                        <Button className="btn btn-sm float-right denialButton" onClick={handleReject}>Reject</Button>
                                    </div>
                                </div>
                            }
                            {details.taxReturnStatus.code !== "PND" && details.taxReturnStatus.code !== "REJ" && details.taxReturnStatus.code !== "APV" && details.taxReturnStatus.code !== "RPN" &&
                                <div className="col-md-auto ">
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-sm float-right btn-warning" onClick={handleClickPending}>Pending</button>
                                    </div>
                                </div>
                            }
                            {details.taxReturnStatus.code !== "ACT" && details.taxReturnStatus.code !== "REJ" && details.taxReturnStatus.code !== "PNT" && details.taxReturnStatus.code !== "RPN" &&
                                <div className="col-md-auto ">
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-sm float-right btn-primary" onClick={handleApprove}>Approve</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Section>
        </>)
    }

    const paymentInfo = () => {
        return <>
            <Section
                subtitle={"Outstanding Balance Due"}
                subtitle_left={
                    <div>
                        <div style={{ fontSize: "24px" }}><b>{formatCurrency(details.outstandingBalanceDue)}</b></div>
                        <i>{details.status}</i>
                    </div>
                }
                subtitle_right={effectiveDateBox()}
            >
                <InfoTotal label="Total Amount from Current Return" value={formatCurrency(details.taxPayableAmt + details.penaltyFee + details.interestFee)} />
                <Info label="Tax payable by Operator" value={formatCurrency(details.taxPayableAmt)} />
                <Info label="Penalty for late payment:" value={formatCurrency(details.penaltyFee)} />
                <Info label="Interest" value={formatCurrency(details.interestFee)} />
                <hr />
                <InfoTotal label="Credit or charge from previous Return" value={formatCurrency(details.previousReturnCreditChargeAmt)} />
                <div className="d-flex justify-content-end">
                    <Button icon="check"
                        text="Apply Available Credit"
                        type="success"
                        stylingMode="outlined"
                        onClick={handleApplyAvailableCredit}
                        visible={showApplyAvailableCreditButton(details.previousReturnCreditChargeAmt, details.status)} />
                </div>
                <InfoSpecial label="TOTAL PAYMENT DUE" value={formatCurrency(details.outstandingBalanceDue)} />
            </Section>
            <br />
            <br />
            <Section subtitle="Payments">
                <TaxReturnPayments
                    taxReturnId={details.taxReturnId}
                    paymentStatusCode={details.paymentStatusCode}
                    refreshDetails={refreshDetails}
                    saveAmounts={saveAmounts}
                    effectiveDate={effectiveDate}
                    isRefreshPayments={isRefreshPayments}
                />
            </Section>

        </>
    }

    const attachmentsInfo = () => {
        return <>
            <Section subtitle="Attachments">
                <TaxReturnAttachments taxReturnId={details.taxReturnId} />
            </Section>
        </>
    }

    const notesInfo = () => {
        return <>
            <Section subtitle="Notes">
                <TaxReturnNotes taxReturnId={details.taxReturnId} />
            </Section>
        </>
    }

    return (
        <>
            {details && <ScrollView onInitialized={onInitialized}>
                <div className='mx-4 mb-4'>
                    <Row>
                        <Col md={4}>
                            <InfoBold label="Owner" value={details.property.ownerName}></InfoBold>
                        </Col>
                        <Col md={4}>
                            <InfoBold label="Certificate Number" value={details.property.certNumber}></InfoBold>
                        </Col>
                        <Col md={4}>
                            <InfoBold label="Reporting Period" value={details.reportingPeriod}></InfoBold>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <InfoBold
                                label="Address"
                                value={details.property.mailingAddress + " " + (details.property.mailingAddress2 === "null" ? details.property.mailingAddress2 : "")}
                                subvalue={details.property.mailingCity + " " + details.property.mailingState + ", " + details.property.mailingZip}
                            ></InfoBold>
                        </Col>
                        <Col md={4}>
                            <InfoBold label="APN" value={formatAPN(details.property.apn)}></InfoBold>
                        </Col>
                    </Row>
                </div>
                <TabPanel>
                    <Item title="Tax Return" render={taxReturnInfo} />
                    <Item title="Payments" render={paymentInfo} />
                    <Item title="Attachments" render={attachmentsInfo} />
                    <Item title="Notes" render={notesInfo} />
                </TabPanel>
            </ScrollView>}
        </>
    );
}

export default TaxReturnDetails;