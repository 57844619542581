import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import TypeOfRentalList from '../../components/typeofrental/typeofrental_overview';

function ManageTypeOfRental(props) {
    return (
        <div className="content-block">
        
             <PageHeader header="Manage Type Of Rental" />
            <TypeOfRentalList></TypeOfRentalList>
        </div>
    );
}

export default ManageTypeOfRental;