import React from 'react';
import { sendRequest } from '../../_helper/util.js';
import TextArea from 'devextreme-react/text-area';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import './NotesHistory.scss';
import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";

const url = process.env.REACT_APP_API_URL + "/api";
class NotesHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userinfo: {},
            notesHistory: "",
            note:""
        }

    }
    componentDidMount() {
        //debugger;
        this.getNotesHistory();

        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');
        this.setState({ userinfo: userinfo });
    }

    getNotesHistory = () => {
        sendRequest(url + "/Registration/GetNotesByPropertyId?propertyId=" + this.props.propertyId, 'GET', '').then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                let strNotes = "";
                for (var i = 0; i < myJson.listNotes.length; i++) {
                    strNotes += myJson.listNotes[i].createdBy + "  " + myJson.listNotes[i].createdDate + "  " + myJson.listNotes[i].note + "\n\r";
                }

                this.setState({
                    notesHistory: strNotes
                });

            }
        });
    }

    onValueChangedTextArea = (e) => {
        this.setState({ note: e.value });
    }

    onClickSaveNote = (e) => {
        //debugger;
        const { note, userinfo } = this.state;
        
        let employeeId = userinfo.user.employeeId;
        if (note !== "") {
            const myObj = {
                PropertyId: this.props.propertyId,
                Note: note,
                CreatedBy: employeeId
            }
            const body = JSON.stringify(myObj);

            sendRequest(url + "/Registration/SaveNote?propertyId=" + this.props.propertyId, 'POST', body).then(myJson => {
                //debugger;
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                }
                else {
                    this.setState({ note: "" });
                    this.getNotesHistory();

                }
            });
        }
        else {
            notify("Please enter note.", "error", 3000);
        }
    }

    render() {
        const { note, notesHistory } = this.state;

        return (
            <div>
                <div className="noteContainer">
                    <div className="noteTextArea">
                        <TextArea height={100}
                            maxLength={1000}
                            value={note}
                            onValueChanged={this.onValueChangedTextArea}
                        />
                    </div>
                    <div className="noteButton">
                        <Button text="Save Note" type="default" onClick={this.onClickSaveNote} />
                    </div>
                </div>
                Notes History:
                   <TextArea
                    value={notesHistory}
                    height={300}
                    readOnly={true}
                    style={{ backgroundColor: "lightgray" }}
                />
            </div>
        );
    }
}

export default NotesHistory;