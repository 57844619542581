import React, { Component } from 'react';
import DataGrid,
{
    Column,
    Editing,
    Button,
    Paging,
    FilterRow
} from 'devextreme-react/data-grid';

import { confirm } from 'devextreme/ui/dialog';

import CustomStore from 'devextreme/data/custom_store';

import { sendRequest } from '../../_helper/util.js';
import SecureLS from "secure-ls";

const url = process.env.REACT_APP_API_URL + "/api";

class community extends Component {
    constructor(props) {
        super(props);

        this.state = {

            communityData: new CustomStore({
                key: 'communityId',
                load: this.loadAllCommunities,
                update: this.updateCommunity,
                insert: this.insertCommunity
            }),
        };
    }

    // componentDidMount() {
    //    //debugger;               
    //    sendRequest(url + "/Lookup/GetSupervisorDistricts", 'GET', '', getAdalToken()).then(myJson => {
    //        if (typeof myJson.message !== "undefined") {
    //            throw myJson.message;
    //        }
    //        else {
    //            this.setState({
    //                supervisorDistrictsData: myJson.listSupervisorDistrict,
    //            });
    //        }
    //    })
    //    .catch(err=>{
    //        console.error(err);
    //    });
    // }

    loadAllCommunities() {

        return sendRequest(url + "/Lookup/GetAllCommunities", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                return myJson.listCommunity;
            }
        });
    }

    updateCommunity = (key, values) => {
        //debugger;
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        values.CommunityId = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);

        return sendRequest(url + "/Lookup/UpdateCommunity", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });        
    }

    insertCommunity = (values) => {

        const body = JSON.stringify(values);
        
        return sendRequest(url + "/Lookup/InsertCommunity", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    disableCommunity = (e) => {
        //debugger;
        let params;
        let description = e.row.data.description;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to disable this Community: " + description + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { CommunityId: e.row.data.communityId };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/DisableCommunity", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    enableCommunity = (e) => {
        //debugger;
        let params;
        let description = e.row.data.description;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to enable this Community: " + description + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { CommunityId: e.row.data.communityId };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/EnableCommunity", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    onInitNewRow = e => {        
        //debugger;
        e.data.isActive = true;
    }

    onEditorPreparing = e => {
        //if (e.dataField === "tra" && e.parentType === "dataRow" && !e.row.isNewRow)
        //{
        //    e.editorOptions.disabled = true;
        //}
    }

    disableButtonIsVisible = e => {
        //debugger;
        if (!e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    enableButtonIsVisible = e => {
        //debugger;
        if (e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { communityData} = this.state;

        return (
            <div className={'content-block'}>
                <DataGrid
                    id="gridContainer"
                    dataSource={communityData}
                    allowColumnReordering={true}
                    showBorders={true}
                    onInitNewRow={this.onInitNewRow}
                    onEditorPreparing={this.onEditorPreparing}
                    ref={ref=>this.dataGrid=ref}                    
                >

                    <FilterRow visible={true} />
                    <Paging enabled={true} />

                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true} />

                    <Column dataField="description" dataType="string" caption="Community" width={150} defaultSortOrder="asc" />

                    <Column dataField="isActive" dataType="boolean" caption="Status" width={130} filterValue={true} trueText="Active" falseText="Inactive" />

                    <Column type="buttons">
                        <Button name="save" hint="Save" />
                        <Button name="edit" hint="Edit" />
                        <Button text="Disable" hint="Disable" onClick={this.disableCommunity} visible={this.disableButtonIsVisible} />
                        <Button text="Enable" hint="Enable" onClick={this.enableCommunity} visible={this.enableButtonIsVisible} />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}

export default community;