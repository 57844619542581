import React, { Component } from 'react';
//import CertificateRegistration from '../public/CertificateRegistration/CertificateRegistration'
import TOTRegistration from '../../components/TOTRegistration/TOTRegistration'
import PageHeader from '../../components/header/PageHeader/PageHeader';

class CertificateRegistrationPage extends Component {

    render() {
        return (
            <div className='content-block'>
               <PageHeader header="Certificate Registration"></PageHeader>
               <TOTRegistration />
            </div>
        );
    }
}

export default CertificateRegistrationPage;
