import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../../_helper/util';
import { LoadPanel } from 'devextreme-react';
import AuthService from '../../services/AuthService';

import DenialTemplate from './DenialTemplate';

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

function DenialWrapper(props) {
    const { id } = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState();

    useEffect(() => {
        const  getDenialTemplate = (propertyId) => {
            const { user } = AuthService.getTOTUser();
        
            const endpointUrl = `${apiUrl}/Registration/DenialTemplate?propertyId=${propertyId}&employeeId=${user.employeeId}`;
            return sendRequest(endpointUrl, "GET", '')
                .then(response => {
                    if (response.status === 404) {
                        history.push("/Home")
                    }
                    return response;
                })
                .catch(err => console.error(err));
        }

        getDenialTemplate(id)
            .then((res) => {
                const { user } = AuthService.getTOTUser();

                setFormData({
                    DenialTemplateContent: res.templateContent,
                    PaperlessCommunication: res.paperlessCommunication,
                    OwnerEmail: res.ownerEmail,
                    ModifiedBy: user.employeeId,
                    PropertyId: parseInt(id)
                });
                setIsLoading(false);
            });

    }, [id, history])

    const handleCancelDenialTemplate = () => {
        history.goBack();
    }

    const handleDenialContentChange = (e) => {
        
        setFormData({
            ...formData,
            DenialTemplateContent: e.value,
            
        })
    }

    const handleDenialSend = () => {
        //debugger;

        const body = JSON.stringify(formData);
        return sendRequest(apiUrl + "/Registration/DenialSend", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                history.push("/CertAppPage");
            }
        });

    }

    return (
        <div>
            {!isLoading?(
                
                <DenialTemplate formData={formData}
                    handleDenialContentChange={handleDenialContentChange}
                    handleCancelDenialTemplate={handleCancelDenialTemplate}
                    handleDenialSend={handleDenialSend}
                />
              )
                : <LoadPanel></LoadPanel>
            }
        </div>
        );
}

export default DenialWrapper;