export const formatCurrency = (val)=>{
    var formatter  =   new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',});;

      return formatter.format(val)
}

export const formatDate = (val)=>{
    if(val == null) return null;
    var date = new Date(val);
    return date.toLocaleDateString();
}

export const formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');
    
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
  
    return null
  };

export const formatAPN = (str) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/[^a-zA-Z0-9]/gi,'');
    
    //Check if the input is of correct length
    let match = cleaned.match(/^([a-zA-Z0-9]{4})([a-zA-Z0-9]{3})([a-zA-Z0-9]{2})([a-zA-Z0-9]{4})$/);

    if (match)
      return match[1] + '-' + match[2] + '-' + match[3] + '-' + match[4];

    return null;
}