
import SecureLS from 'secure-ls';
import AdalConfig from '../config/AdalConfig';
import { sendRequest } from '../_helper/util';
import authContext from './Auth'
const url = process.env.REACT_APP_API_URL + "/api";
class AuthService {

    clearUserLocalStorageInfo() {
        const ls = new SecureLS();
        ls.remove('userinfo');
        ls.remove('isAuthorized');
    }

    getAuthorization() {
        const acquireToken = new Promise((resolve, reject) => {
            authContext.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
                resolve(token)

            });
        });

        return acquireToken.then(token => {
            if (token) {
                const userinfo = authContext.getCachedUser();
                if (userinfo) {
                    //check userName is in Admin
                    const userNameAD = userinfo.userName;
                    const myObj = { EmailAddress: userNameAD };
                    const body = JSON.stringify(myObj);
                    return sendRequest(url + '/Users/authenticate', 'POST', body, token)
                        .then(response => {
                            //debugger;
                            console.log("auth service", response);
                            if (response && response.message) {
                                console.error(response.message, "ERROR", 3000);
                            }
                            else {

                                // debugger;

                                if (response.user.isActive) {
                                    const ls = new SecureLS();
                                    ls.set('userinfo', response);
                                    ls.set('isAuthorized', true);
                                    //return true;
                                }
                                else {
                                    // TODO: how to handle this( logged in to AD but not authorized to access app)
                                    this.logout();
                                    this.clearUserLocalStorageInfo();
                                    //     window.location.href="/#/notauthorized"
                                    // useHistory.push("/NotAuthorized")
                                    console.log("NOT AUTH")
                                    // return false;
                                }

                            }
                        })
                }
                else {
                    return;
                }
            }
        })

    }

    getToken() {
        return authContext.getCachedToken(authContext.endpoints);
    }

    getADUser() {
        return authContext.getCachedUser();
    }

    getTOTUser() {
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');
        return userinfo;
    }

    isAuthorized() {
        return localStorage.getItem("isAuthorized") && this.loggedIn();
    }

    login() {

        authContext.login();

    }

    loggedIn() {
        return this.getADUser() ? true : false;
    }

    logout() {
        authContext.logOut();
        this.clearUserLocalStorageInfo();

    }

   
}

export default new AuthService();