import React, { useEffect, useState } from 'react';
import { DataGrid, Column, Button, HeaderFilter, Editing, Lookup } from 'devextreme-react/data-grid';
import { sendRequest } from '../../_helper/util.js';

const url = process.env.REACT_APP_API_URL + "/api";

function OverPaymentsDetails(props) {
    const [overPaymentDetailsData, setoverPaymentDetailsData] = useState();

    useEffect(() => {
        loadOverpaymentsDetails(props.data.key.overPaymentID).then(ret => setoverPaymentDetailsData(ret));
    }, [])

    const loadOverpaymentsDetails = (selectedOverpaymentId) => {
        const endpointUrl = process.env.REACT_APP_API_URL + `/api/Overpayments/GetOverpaymentDetails/${selectedOverpaymentId}`;
        return sendRequest(endpointUrl, "GET", '')
            .then(response => response);
    }

    return (
        <>            
            <div className="master-detail-caption">
                {`Tax Returns utilizing overpayments (Credits)`}
            </div>
            <DataGrid
                dataSource={overPaymentDetailsData}
                showBorders={true}
                columnAutoWidth={false}
            >
                <HeaderFilter visible={true} />
                <Column dataField="certNumber" caption="Cert #" width="100px" />
                <Column dataField="taxReturnReportingQuarter" caption="Reporting Quarter" width="200px" />
                <Column dataField="taxReturnStatus" caption="Tax Return Status" width="150px" ></Column>
                <Column dataField="paymentAmount" caption="Payment Amount" dataType="number" format={{ type: "currency", precision: 2 }} visible={false} ></Column>
                <Column dataField="creditAmount" caption="Credit Amount Used" dataType="number" format={{ type: "currency", precision: 2 }} width="200px"></Column>
            </DataGrid>   
        </>
    );
}

export default OverPaymentsDetails;
