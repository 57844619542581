import {
    adalGetToken//, AuthenticationContext
} from 'react-adal';
import AdalConfig from '../config/AdalConfig';
import AuthContext from '../services/Auth';

export const util = {
    sendRequest,
    sendPublicRequest
};

export function sendRequest(url, method, body) {
    let tokenKeys = sessionStorage.getItem('adal.token.keys').split("|");
    let currentTokenExpKey = sessionStorage.getItem(`adal.expiration.key${tokenKeys[0]}`);
    let currentTokenExpDate = currentTokenExpKey * 1000;

    if (Date.now() > currentTokenExpDate) {
        return new Promise(()=>{
            AuthContext.logOut();
            window.location.reload();
        })       
    }
    else {
        return adalGetToken(AuthContext, AdalConfig.clientId)
            .then(token => {
             
                method = method || 'GET';

                if (method === 'GET') {
                    return fetch(url, {
                        method: method,
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(result => {

                        if (result.ok) {
                            return result.json();
                        }
                        else {
                            if (result.status === 401) {
                                AuthContext.logOut();
                                window.location.reload();
                            }
                            return result;
                        }
                    })
                }
                else {
                    body = body || {};

                    return fetch(url, {
                        method: method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: body
                    }).then(result => {
                        if (result.ok) {
                            return result.json();
                        } else {
                            if (result.status === 401) {
                                AuthContext.logOut();
                                window.location.reload();

                            }
                            throw new Error(result)
                        }
                    })
                }
            })
            .catch(err => {
                console.error("Error", err)
                // AuthContext.logOut();
                // window.location.reload();
            })
    }
}

export function sendPublicRequest(url, method, body) {
    method = method || 'GET';

    if (method === 'GET') {
        return fetch(url, {
            method: method,

        }).then(result => {
            if (result.ok) {
                return result.json();
            }
            else {
                throw new Error("ERROR occured:" + result.status)
            }
        })
            .catch(err => {
                console.error(err);
                return err;
            });
    }
    else {
        body = body || {};

        return fetch(url, {
            method: method,
            body: body,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(result => {
            if (result.ok) {
                return result.json();
            }
            else {
                throw new Error("ERROR occured:" + result.status)
            }
        })
            .catch(err => {
                console.error(err);
                return err;
            });
    }
}