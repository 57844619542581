import React from 'react';
import "./PageHeader.scss"

function PageHeader(props) {
    const { header, subHeader } = props;
    return (
        <>
            {header && <div className={'page-header'}>{header}</div>}
            {subHeader && <div className={'page-subheader'}>{subHeader}</div>}
            <hr></hr>
        </>
    );
}

export default PageHeader;