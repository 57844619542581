import React from 'react';
import OverpaymentList from '../../components/Overpayments/OverPaymentOverview';

function OverpaymentsOverview(props) {
    return (
        <div className="content-block">    
            <OverpaymentList></OverpaymentList>
        </div>
    );
}

export default OverpaymentsOverview;