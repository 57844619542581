import React, {  useEffect, useState } from 'react';
import { Popup } from 'devextreme-react';
import {  Button } from 'react-bootstrap';
// import { DataGrid, Button } from 'devextreme-react';
import TaxReturnDetails from '../TaxReturnDetails/TaxReturnDetails';
import AuthService from '../../services/AuthService';
import { useHistory } from 'react-router-dom';

import ExportPdfComponentForTaxReturn from '../exportpdfcomponent/ExportPdfComponentForTaxReturn';

//const url = process.env.REACT_APP_API_URL + "/api";

function TaxReturnPayment(props) {
    const [previewPopupVisible, setPreviewPopupVisible] = useState(false);
    const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
    const [selectedTaxReturnId, setSelectedTaxReturnId] = useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // check if user is an admin
        if (AuthService.isAuthorized()) {
            setIsAdmin(true);
        }
    }, []);

    const onClickPrint = (e) => {
        //debugger;
        setSelectedTaxReturnId(props.formData.taxReturnId);
        setPreviewPopupVisible(true);
    }

    const onClickClose = () => {
        setSelectedTaxReturnId(0);
        setPreviewPopupVisible(false);
    }

    const onClickDetails = () => {
        setSelectedTaxReturnId(props.formData.taxReturnId);
        setDetailsPopupVisible(true);
    }

    const onHideDetails = () => {
        setSelectedTaxReturnId(0);
        setDetailsPopupVisible(false);
    }
    
    const routeChange = () =>{ 
        let path = `/TOTReturnPage`; 
        history.push(path);
        window.location.reload();
      }

    return (
        <div>
            <div>
                <br /><br />
                {/*<h2 className='step-title'><b>Payment</b> </h2>
                <h1>Redirect to Grant Street Secure Payment Processing...</h1>*/}
                <h2>Thank you for submitting your TOT Tax Return form. If there is a tax due, please print and return a copy of your Tax Return form along with your payment postmarked by the last day of this month to avoid additional costs.</h2>
                <div className={"action-button-wrapper"} style={{textAlign:"center"}}>
                    <Button size="lg" variant="primary" className={'action-button'} style={{margin:"10px"}} onClick={onClickPrint}> Print Return Form </Button>
                    {/* {isAdmin && <Button size="lg" variant="primary" className={'action-button'} style={{margin:"10px"}} onClick={onClickDetails}> Make a Payment </Button>} */}
                    {isAdmin && <Button size="lg" variant="primary" className={'action-button'} style={{margin:"10px"}} onClick={routeChange}> Fill Another Tax Return </Button>}
                </div>
            </div>

            {previewPopupVisible && 
                <Popup visible={previewPopupVisible} onHiding={onClickClose} title={`View Tax Return Form`} >
                    <ExportPdfComponentForTaxReturn taxReturnId={selectedTaxReturnId} height="100%" width="100%"></ExportPdfComponentForTaxReturn>
                </Popup>
            }

            {detailsPopupVisible && isAdmin &&
                <Popup visible={detailsPopupVisible} onHiding={onHideDetails} maxWidth="1000px">
                    <TaxReturnDetails taxReturnId={selectedTaxReturnId} />
                </Popup>
            }
        </div>
    )
}

export default TaxReturnPayment;