import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid, FileUploader, List, LoadIndicator, SelectBox } from 'devextreme-react';
// import { Button } from 'react-bootstrap';
import { getAdalToken } from '../../_helper/adalToken';
import { sendRequest } from '../../_helper/util';
import { Column, HeaderFilter, Button as GridButton } from 'devextreme-react/data-grid';
import { RequiredRule, Validator } from 'devextreme-react/validator';
//import _ from 'lodash';
import Button from 'devextreme-react/button';
//import { Info } from './TaxReturnComponents';
import "./PopupDetails.scss";


TaxReturnAttachments.propTypes = {
    taxReturnId: PropTypes.number.isRequired
};

const apiUrl = process.env.REACT_APP_API_URL;

function TaxReturnAttachments(props) {
    const uploaderRef = useRef();
    const { taxReturnId } = props;
    const [attachments, setAttachments] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [uploadDisabled, setUploadDisabled] = useState(selectedFiles.length > 0 ? false : true);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        refreshAttachments();
        getAttachmentTypes("TaxReturn")
            .then(response => setAttachmentTypes(response))
    }, [])

    useEffect(() => {
        setUploadDisabled(selectedFiles.length > 0 ? false : true);
        setError(null);
    }, [selectedFiles])

    const getAttachments = () => {
        const endpointUrl = process.env.REACT_APP_API_URL + `/api/TaxReturn/Attachments/${taxReturnId}`;
        return sendRequest(endpointUrl, "GET", '');
    }

    const getAttachmentTypes = (sourceTable) => {
        const endpointUrl = apiUrl + `/api/Lookup/GetAttachmentTypesBySourceTable/${sourceTable}`;
        return sendRequest(endpointUrl, "GET", '');
    }

    const onSelectedFilesChanged = (e) => {
        // var fileArr = [];
        // e.value.map((v, i) => {
        //     var file = v;
        //     let t = {
        //         attachmentTypeId: null,
        //         fileName: file.name,
        //         file: file
        //     }
        //     fileArr.push(t);
        // });
        // setSelectedFiles(fileArr)

        if (e.value.length !== 0) {
            var file = e.value.slice(-1)[0];
            let newFile = {
                attachmentTypeId: null,
                fileName: file.name,
                file: file
            }
            let tempSelectedFiles = [...selectedFiles];
            tempSelectedFiles.push(newFile);
            setSelectedFiles(tempSelectedFiles);
        }
    }

    const handleClearSelectedFiles = () => {
        setSelectedFiles([]);
        uploaderRef.current.instance.reset();
    }

    const handleUpload = (e) => {
        setError(null);
        setIsUploading(true);
        e.preventDefault();
        let formData = new FormData();

        selectedFiles.map((s, i) => {
            formData.append(`Types[][${i}][SourceTableId]`, taxReturnId)
            formData.append(`Types[][${i}][AttachmentTypeId]`, s.attachmentTypeId)
            formData.append(`Types[][${i}][FileName]`, s.fileName)
            formData.append(`Types[][${i}][File]`, s.file)
        });

        const endpointUrl = apiUrl + `/api/TaxReturn/Attachments`;
        return fetch(endpointUrl, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getAdalToken()
            },
            body: formData
        })
            .then(res => {
                setIsUploading(false);
                if (!res.ok) {
                    throw Error(`${res.status} ${res.statusText}`);
                }
                else {
                    handleClearSelectedFiles();
                    refreshAttachments();
                }
            })
            .catch(err => {
                setError(`An error has occured ${err}`)
            });
    }

    const handleDocTypeChange = (value, i) => {
        let tempSelectedFiles = [...selectedFiles];
        tempSelectedFiles[i].attachmentType = value;
        tempSelectedFiles[i].attachmentTypeId = value.attachmentTypeId;
        setSelectedFiles(tempSelectedFiles);
    }

    const refreshAttachments = () => {
        getAttachments().then(res => setAttachments(res))
    }

    const handleViewAttachment = (e) => {
        const endpointUrl = apiUrl + `/api/Registration/GetAttachmentByAttachmentId?attachmentId=${e.row.data.attachmentId}`;
        window.open(endpointUrl)
    }

    const ListItem = (file, index) => {
        return <div className="d-flex justify-content-between align-items-center" >
            <p>{file.fileName}</p>
            <SelectBox
                className="attachmentDocType"
                colSpan={2}
                dataField="attachmentTypeId"
                editorType="dxSelectBox"
                label="Document type"
                labelMode='static'
                dataSource={attachmentTypes}
                displayExpr="description"
                value={file.attachmentType}
                validationMessageMode='always' 
                onValueChange={(val) => handleDocTypeChange(val, index)}>
                <Validator>
                    <RequiredRule message="Choose a type"></RequiredRule>
                </Validator>
            </SelectBox>
        </div>
    }
    const onItemDeleting = (e) => {
        // delete the deleted item from selectedFiles
        if (e.itemIndex > -1) {
            let tempSelectedFiles = [...selectedFiles];
            tempSelectedFiles.splice(e.itemIndex, 1);
            setSelectedFiles(tempSelectedFiles);
        }
    }

    return (
        <div className='mt-3 mb-3 p-3 bg-light rounded' >
            {/* <Info label="Attachments:" value="" /> */}
            {attachments && attachments.length > 0 &&
                <DataGrid
                    kay="attachmentId"
                    dataSource={attachments}
                    showRowLines={true}
                    rowAlternationEnabled={true}>
                    <HeaderFilter visible={true} />
                    <Column dataField="fileName"></Column>
                    <Column dataField="attachmentDate" dataType="date"></Column>
                    <Column dataField="attachmentTypeDescription" caption="Attachment Type"></Column>
                    <Column type="buttons" caption="Attachment">
                        <GridButton icon="download" onClick={handleViewAttachment} hint="Click to download"></GridButton>
                    </Column>
                </DataGrid>
            }
            <FileUploader
                ref={uploaderRef}
                selectButtonText="Click to Select Files"
                labelText="Or Drop Files Here"
                multiple={true}
                uploadMode={'useButtons'}
                accept={"*"}
                allowedFileExtensions={['.pdf', '.jpg', '.png', '.docx', '.xlsx']}
                onValueChanged={onSelectedFilesChanged}
                showFileList={false}
            ></FileUploader>
            {selectedFiles.length > 0 && !isUploading &&
                <>  <form onSubmit={handleUpload}>
                    <List
                        className="attachmentDocType"
                        dataSource={selectedFiles}
                        noDataText=""
                        itemRender={ListItem}
                        allowItemDeleting={true}
                        onItemDeleting={onItemDeleting}>
                    </List>
                    <div className='m-2 p-2 d-flex justify-content-end gap-2'>
                        <Button className='btn btn-sm' disabled={uploadDisabled} onClick={handleClearSelectedFiles}>Clear</Button>
                        <Button type="success" useSubmitBehavior={true} className="btn btn-sm" variant="primary" disabled={uploadDisabled}  >Upload</Button>

                    </div>
                </form>
                    {error && <span className="text-danger small">{error}</span>}
                </>
            }
            {isUploading &&
                <div className="d-flex justify-content-center"><LoadIndicator></LoadIndicator></div>
            }
        </div>
    );
}

export default TaxReturnAttachments;