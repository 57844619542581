import React from 'react';
import { Route } from 'react-router-dom';
import appRoutes from './app-routes';

function ProtectedRoutes(props) {
    return (
        <>
            {appRoutes.map(item => (
                <Route
                    exact
                    key={item.path}
                    path={item.path}
                    component={item.component}
                />
            ))}

        </>
    );
}

export default ProtectedRoutes;