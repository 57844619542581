// Charles's MSDN Tenant
//export default {
//    clientId: '15459413-b427-4306-9fd1-0ca2a9792a65',
//    endpoints: {
//        api: "15459413-b427-4306-9fd1-0ca2a9792a65" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
//    },
//    // 'tenant' is the Azure AD instance.
//    tenant: '1ea2397d-0f5e-41cb-a391-e438aebb6b5b',
//    // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
//    // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
//    cacheLocation: 'sessionStorage'
//}

//ATC Transient Occupancy Tax Tenant
export default {
    clientId: 'e2f89a5d-89ba-4f88-bb96-900e629264d3',
    endpoints: {
        api: "e2f89a5d-89ba-4f88-bb96-900e629264d3" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
    },
    // 'tenant' is the Azure AD instance.
    tenant: 'd508ed90-bedd-4084-9083-d6ef55ea07f5',
    // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
    // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
    cacheLocation: 'sessionStorage'
}