//import React, { useState } from 'react';
import React from 'react';

//import Form2, {
//    ButtonItem,
//    GroupItem,
//    SimpleItem,
//    Label,
//    CompareRule,
//    EmailRule,
//    PatternRule,
//    RangeRule,
//    RequiredRule,
//    StringLengthRule,
//    AsyncRule,
//} from 'devextreme-react/form';
//import notify from 'devextreme/ui/notify';
import 'devextreme-react/autocomplete';

import InputMask from 'react-input-mask';

import Form from "react-bootstrap/Form";
//import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
//import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
//import { Box } from 'devextreme-react';
import RegisterCertWrapper from './registercert_wrapper';
import { withRouter } from 'react-router-dom';


class OwnerDetails extends React.Component {
    /*export default function OwnerDetails(props) {*/
    //constructor() {
    //    super();

        //this.phoneEditorOptions = {
        //    mask: '+1 (X00) 000-0000',
        //    maskRules: {
        //        X: /[02-9]/,
        //    },
        //    maskInvalidMessage: 'The phone must have a correct USA phone format',
        //};
    //}
    
    back = (e) => {
        e.preventDefault();
        //this.props.prevStep();
        this.props.history.push("/TransientOccupancyTax");
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        let errors = [];
        //owner name
        if (this.props.inputValues.ownerName === "") {
            errors.push("ownerName");
        }
        //email
        const expression = /\S+@\S+\.\S+/;
        let validEmail = expression.test(String(this.props.inputValues.email).toLowerCase());
        if (!validEmail) {
            errors.push("email");
        }

        //owner phone number
        var phoneToCheck = this.props.inputValues.ownerPhoneNumber;

        phoneToCheck = phoneToCheck.replace("+1 (", "");
        phoneToCheck = phoneToCheck.replace(") ", "");
        phoneToCheck = phoneToCheck.replace("-", "");
        phoneToCheck = phoneToCheck.replaceAll(" ", "");

        if (phoneToCheck.length !== 10) {
            errors.push("ownerPhoneNumber");
        }

        this.props.handleHasError(errors);

        if (errors.length > 0) {
            return false;
        } else {
            this.props.nextStep();  /*All good*/
        }
    };

    hasError(key) {
        return this.props.hasError(key); 
    }

    render() {
        return (
            <RegisterCertWrapper title='Register for a Certificate' subtitle="OWNER INFORMATION" step="Step 1 of 4" onNextClick={this.saveAndContinue} onBackClick={this.back}>
                

                <Form>
                    <Row>                        
                        <Form.Group as={Col} md="4" lg="4" controlId="formOwnerName">
                            <Form.Label className="label"><b>Owner Name</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("ownerName")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="text"
                                defaultValue={this.props.inputValues.ownerName}
                                name="ownerName"
                                required
                                onChange={this.props.handleChange}
                                maxLength="255"
                            />
                            <div className={"inline-errormsg"}>{this.hasError("ownerName") ? "Please enter a value" : null}</div>
                        </Form.Group>
                        <Form.Group as={Col} md="3" lg="3" controlId="formOwnerPhoneNumber">
                            <Form.Label className="label"><b>Phone Number</b></Form.Label>
                            <InputMask
                                className={
                                    this.hasError("ownerPhoneNumber")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                formatChars={{
                                    "9": "[0-9]"
                                }}
                                mask="+1 (999) 999-9999"
                                maskChar=" "
                                onChange={this.props.handleOwnerPhoneNumberChange}
                                value={this.props.inputValues.ownerPhoneNumber}
                            ></InputMask>
                            <div className={"inline-errormsg"}>{this.hasError("ownerPhoneNumber") ? "Please enter a value" : null}</div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formBusMgmtCoName">
                            <Form.Label className="label">Business/Management Co. Name</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={this.props.inputValues.busMgmtCoName}
                                name="busMgmtCoName"
                                required
                                onChange={this.props.handleChange}
                                maxLength="255"
                            />
                        </Form.Group>
                    </Row>                   
                    <br />
                    <Row>
                        <Form.Group as={Col} lg="4" controlId="formEmail">
                            <Form.Label className="label"><b>Email Address</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("email")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="email"
                                defaultValue={this.props.inputValues.email}
                                name="email"
                                required
                                onChange={this.props.handleChange}
                                maxLength="255"
                            />
                            <div className={"inline-errormsg"}>{this.hasError("email") ? "Invalid email address.  Please verify you have entered a valid email address" : null}</div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} lg="4" controlId="formPaperlessComm">
                            <Form.Check name="paperlessComm" type="checkbox" label="I agree to Paperless Communication" defaultChecked={this.props.inputValues.paperlessCommChecked} onChange={this.props.handleChange} />
                        </Form.Group>
                    </Row>
                   
                </Form>
            </RegisterCertWrapper>
        );
    }
}

//function sendPublicRequest(value) {
//    const validEmail = 'test@dx-email.com';
//    return new Promise((resolve) => {
//        setTimeout(() => {
//            resolve(value === validEmail);
//        }, 1000);
//    });
//}

//function asyncValidation(params) {
//    return sendPublicRequest(params.value);
//}

export default withRouter(OwnerDetails);