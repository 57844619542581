import React, { Component } from 'react';
import DataGrid,
{
    Column,
    Editing,
    Button,
    Paging,
    FilterRow,
    RowDragging
} from 'devextreme-react/data-grid';

import { confirm } from 'devextreme/ui/dialog';
import CheckBox from 'devextreme-react/check-box';

import CustomStore from 'devextreme/data/custom_store';

import { sendRequest } from '../../_helper/util.js';
import SecureLS from "secure-ls";

import './typeofrental_overview.scss';

const url = process.env.REACT_APP_API_URL + "/api";

class typeofrental extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeofrentalData: new CustomStore({
                key: 'typeOfRentalCode',
                load: this.loadTypeOfRentals,
                update: this.updateTypeOfRental,
                insert: this.insertTypeOfRental
            }),
            includeInactiveRecords: false
        };

        this.onReorder = this.onReorder.bind(this);
    }

    onReorder(e) {
        e.promise = this.processReorder(e);
    }

    async processReorder(e) {
        var toIndex = e.toIndex + 1;
        var typeOfRentalCode = e.itemData.typeOfRentalCode;
        //debugger;

        await sendRequest(url + "/Lookup/ReorderTypeOfRental?typeOfRentalCode=" + typeOfRentalCode + "&toIndex=" + toIndex + "&includeInactive=" + this.state.includeInactiveRecords, 'POST', '').then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
            }
        });

        await e.component.refresh();
      }

    loadTypeOfRentals = () => {
        //debugger;
        var { includeInactiveRecords } = this.state;
        
        return sendRequest(url + "/Lookup/GeTypeOfRentals_ForLookup?includeInactive=" + includeInactiveRecords, 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                return myJson.listTypeOfRentals;
            }
        });
    }

    updateTypeOfRental = (key, values) => {
        //debugger;
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        values.typeOfRentalCode = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);

        return sendRequest(url + "/Lookup/UpdateTypeOfRental", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });        
    }

    insertTypeOfRental = (values) => {

        const body = JSON.stringify(values);
        
        return sendRequest(url + "/Lookup/InsertTypeOfRental", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    disableTypeOfRental = (e) => {
        //debugger;
        let params;
        let description = e.row.data.description;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to disable this Type Of Rental: " + description + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { typeOfRentalCode: e.row.data.typeOfRentalCode };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/DisableTypeOfRental", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    enableTypeOfRental = (e) => {
        //debugger;
        let params;
        let description = e.row.data.description;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to enable this Type Of Rental: " + description + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { typeOfRentalCode: e.row.data.typeOfRentalCode };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/EnableTypeOfRental", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    onInitNewRow = e => {        
        //debugger;
        e.data.isActive = true;
    }

    onEditorPreparing = e => {
        if (e.dataField === "typeOfRentalCode" && e.parentType === "dataRow" && !e.row.isNewRow)
        {
           e.editorOptions.disabled = true;
        }
    }

    disableButtonIsVisible = e => {
        //debugger;
        if (!e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    enableButtonIsVisible = e => {
        //debugger;
        if (e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    includeInactiveRecordsValueChanged = e => {
        this.setState({ includeInactiveRecords: e.value });
        this.loadTypeOfRentals();
        this.dataGrid.instance.refresh();
    }

    render() {
        const { typeofrentalData } = this.state;

        return (            
            <div className={'content-block'}>   
                <div className="option">
                    <CheckBox text="Include InActive Records" style={{marginLeft: "0"}}
                    value={this.state.includeInactiveRecords}
                    onValueChanged={this.includeInactiveRecordsValueChanged} />
                </div>             
                <DataGrid
                    id="gridContainer"
                    dataSource={typeofrentalData}
                    allowColumnReordering={true}
                    showBorders={true}
                    onInitNewRow={this.onInitNewRow}
                    onEditorPreparing={this.onEditorPreparing}
                    ref={ref=>this.dataGrid=ref}                                      
                >

                    <RowDragging
                        allowReordering={true}
                        onReorder={this.onReorder}
                        dropFeedbackMode="push"
                    />

                    <FilterRow visible={false} />
                    <Paging enabled={false} />

                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true} />

                    <Column dataField="typeOfRentalCode" dataType="string" caption="Code" width={100} allowSorting={false}  />
                    <Column dataField="description" dataType="string" caption="Type Of Rental" width={150} allowSorting={false}  />
                    <Column dataField="isActive" dataType="boolean" width={130} allowSorting={false} />
                    <Column dataField="sortOrder" dataType="integer" width={100} caption="Sort Order" allowSorting={false} visible={false} />

                    <Column type="buttons">
                        <Button name="save" hint="Save" />
                        <Button name="edit" hint="Edit" />
                        <Button text="Disable" hint="Disable" onClick={this.disableTypeOfRental} visible={this.disableButtonIsVisible} />
                        <Button text="Enable" hint="Enable" onClick={this.enableTypeOfRental} visible={this.enableButtonIsVisible} />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}

export default typeofrental;