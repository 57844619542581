import React from 'react';
import FileUploader from 'devextreme-react/file-uploader';

import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import './Attachment.scss';

import { sendRequest } from '../../_helper/util.js';
import { getAdalToken } from '../../_helper/adalToken';


const url = process.env.REACT_APP_API_URL + "/api";

class Attachment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attachments: [],
            selectedFiles: []
        }

    }
    componentDidMount() {
        this.getAttachments();

    }

    getAttachments = () => {
        let propertyId = this.props.propertyId;
        if (propertyId !== 0) {
            sendRequest(url + "/Registration/GetAttachmentsByPropertyId?propertyId=" + propertyId, 'GET', '').then(myJson => {
                //debugger;
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                }
                else {

                    this.setState({
                        attachments: myJson.listAttachment
                    });

                }
            });
        }
    }

    onClickDelete = (attachmentId) => {
        //debugger;
        let result = confirm("Are you sure you want to delete this attachment?", "Confirm delete");
        result.then((dialogResult) => {
            if (dialogResult) {
                sendRequest(url + "/Registration/DeleteAttachmentsByAttachmentId?attachmentId=" + attachmentId, 'POST', '').then(myJson => {
                    //debugger;
                    if (typeof myJson.message !== "undefined") {
                        throw myJson.message;
                    }
                    else {

                        this.getAttachments();

                    }
                });
            }
        });


    }


    onClickUpload = (e) => {
        let formData = new FormData();
        let files = this.state.selectedFiles;

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('file_' + i, files[i]);
            }
            formData.append('propertyId', this.props.propertyId);

            fetch(url + "/Registration/UploadAttachments", {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + getAdalToken()
                },
                body: formData,

            })
                .then(response => response.json())
                .then(result => {
                    if (typeof result.message !== "undefined") {
                        console.log('error:', result.message);
                    } else {
                        this.getAttachments();
                        this.setState({ selectedFiles: [] });
                        this.FileUploader.instance.reset();
                        notify("Files have been uploaded successfully.", "success", 3000);
                        
                    }
                })
                .catch(error => {
                    notify(error, "error", 3000);
                });
        }
        else {
            notify("Please select or drop files.", "error", 3000);
        }
    }

    onSelectedFilesChanged = (e) => {
        //debugger;
        this.setState({ selectedFiles: e.value });
    }

    render() {
        const { attachments } = this.state;

        return (
            <div>

                <div className="attachmentContainer">
                    {
                        attachments.map((attach, i) =>
                            <div className="attachmentColumn" key={attach.attachmentId}>
                                <div>
                                    <i className="dx-icon-file fileIcon" ></i>
                                </div>
                                <div className="attachmentDesc">
                                    <a target="_blank" rel="noopener noreferrer" href={url + "/Registration/GetAttachmentByAttachmentId?attachmentId=" + attach.attachmentId}>{attach.description}</a>
                                </div>
                                <div>
                                    <Button text="Delete" type="default" stylingMode="outlined" width={100}
                                        onClick={() => this.onClickDelete(attach.attachmentId)}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>


                <div className="uploadContainer">
                    <FileUploader
                        selectButtonText="Click to Select Files"
                        labelText="Or Drop Files Here"
                        multiple={true}
                        uploadMode={'useForm'}
                        accept={"*"}
                        allowedFileExtensions={['.pdf', '.jpg', '.png', '.docx', '.xlsx']}
                        onValueChanged={this.onSelectedFilesChanged}
                        ref={ref=>this.FileUploader=ref} 
                    />
                </div>
                <Button text="Upload" type="default" onClick={this.onClickUpload} />

            </div>
        );
    }
}

export default Attachment;