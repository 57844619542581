import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Info = ({ label, value, ...props }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: '8px' }}>
            <span style={{ textTransform: 'uppercase', color: "#616161" }}><b>{label}</b></span>
            <span style={{ color: "#616161" }}>{value || <>&nbsp;</>}</span>
        </div>
    )
}

const Section = ({ subtitle, children, ...props }) => {
    return (
        <>
            <hr style={{ borderTop: "2px solid rgba(0,45,115,.9)"}} />
            <h5>{subtitle}</h5>

            <div style={{ marginLeft: "20px" }}> {children}</div>
        </>
    )
}


function ReviewInfo(props) {
    const { info } = props;
    return (
        <>
            <Section subtitle="Owner Information">
                <Row>
                    <Col md={4}>
                        <Info label="Owner Name" value={info.ownerName}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Owner Phone Number" value={info.ownerPhoneNumber}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Email Address" value={info.email}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Business/Management Co.Name" value={info.busMgmtCoName}></Info>
                    </Col>
                </Row>
            </Section>
            <Section subtitle="Rental Property Information">
                <Row>
                    <Col md={4}>
                        <Info label="Address" value={info.rentalPropAddress}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Address 2" value={info.rentalPropAddress2}></Info>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Info label="City" value={info.rentalPropCity}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="State" value={info.rentalPropState}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Zip Code" value={info.rentalPropZip}></Info>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Info label="Assessor Parcel Number" value={info.apn}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="# of Occupancy Units" value={info.occUnitsNo}></Info>
                    </Col>

                </Row>
            </Section>
            <Section subtitle="Mailing Address Information">
                <Row>
                    <Col md={4}>
                        <Info label="Address" value={info.mailingAddress}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Address 2" value={info.mailingAddress2}></Info>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Info label="City" value={info.mailingCity}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="State" value={info.mailingState}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Zip Code" value={info.mailingZip}></Info>
                    </Col>
                </Row>

            </Section>
            <Section subtitle="Business Information">
                <Row>
                    <Col>
                        <Info label="How long have you owned/operated this business" value={info.ownedBusLength}></Info>
                    </Col>

                </Row>
                <Row>
                    <Col md={4}>
                        <Info label="Type of Rental" value={info.typeOfRental}></Info>
                    </Col>
                    <Col md={4}>
                        <Info label="Other" value={info.typeOfRentalOther}></Info>
                    </Col>
                </Row>
            </Section>
        </>
    );
}

export default ReviewInfo;