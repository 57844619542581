import React, { Component } from 'react';
import DataGrid,
{
    Column,
    Editing,
    Button,
    Paging,
    FilterRow
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import { confirm } from 'devextreme/ui/dialog';

import { sendRequest } from '../../_helper/util.js';
import SecureLS from "secure-ls";

import './quarters_overview.scss';

const url = process.env.REACT_APP_API_URL + "/api";
// var isSaveTaxRate = false;

class quarters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yearQuarterData: new CustomStore({
                key: 'yearQuarterId',
                load: this.loadYearQuarters,
                update: this.updateYearQuarter//,
                //insert: this.insertTypeOfRental
            }),
            includeInactiveRecords: false,
            isSaveTaxRate: false
        };

    }

    loadYearQuarters = () => {
        // debugger;
        //var { includeInactiveRecords } = this.state;
        
        return sendRequest(url + "/Lookup/GetAllYearQuarters_ForOverview", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                return myJson.listAllYearQuarters;
            }
        });
    }

    updateYearQuarter = (key, values) => {
        //debugger;
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        values.yearQuarterId = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);

        return sendRequest(url + "/Lookup/UpdateYearQuarter", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });        
    }

    //insertTypeOfRental = (values) => {

    //    const body = JSON.stringify(values);
        
    //    return sendRequest(url + "/Lookup/InsertTypeOfRental", 'POST', body, getAdalToken()).then(myJson => {
            //debugger;
    //        if (typeof myJson.message !== "undefined") {
    //            throw myJson.message;
    //        }
    //        else {
    //            return myJson;
    //        }
    //    });
    //}

    

    //onInitNewRow = e => {        
        //debugger;
    //    e.data.isActive = true;
    //}

    onEditorPreparing = e => {
        // debugger;
    }

    disableButtonIsVisible = e => {
        //debugger;
        if (!e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    enableButtonIsVisible = e => {
        //debugger;
        if (e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    /*
    includeInactiveRecordsValueChanged = e => {
        this.setState({ includeInactiveRecords: e.value });
        this.loadTypeOfRentals();
        this.dataGrid.instance.refresh();
    }
    */

    onRowValidating = (e) => {
        if (e.newData.taxRate) {
            e.newData.taxRate = parseFloat(e.newData.taxRate);
            if (!e.newData.taxRate) {
                e.isValid = false;
                e.errorText = "Please enter a valid number for Tax Rate.";
            }
        }
        if (!e.isValid) {
            this.dataGrid.instance.getCellElement(e.component.getRowIndexByKey(e.key), "taxRate").style.border = "solid 2px rgb(232,152,150)";
        }
    }

    taxRatecustomizeText(cell) {
        return (cell.value * 100).toFixed(2).replace(/[.,]00$/, "").toString() + '%'; 
    }

    onSaving = (e) => {
        //debugger;
        var { isSaveTaxRate } = this.state;

        if (!isSaveTaxRate) {
            for (const c of e.changes) {
                if (c.data.taxRate) {
                    if (c.data.taxRate > 0.4) {
                        const isCanceled = new Promise((resolve, reject) => {
                            const promptPromise = confirm("You have entered a Tax Rate larger than 40%. Are you sure you wish to use this Tax Rate?", "Confirm changes");
                            promptPromise.then((dialogResult) => {
                                if (!dialogResult) {  
                                    return resolve(true);
                                } else {
                                    isSaveTaxRate = true;
                                    this.dataGrid.instance.saveEditData();
                                }
                            });
                        });
                        e.cancel = isCanceled;
                    }
                    break;
                }
            }
        }
        isSaveTaxRate = false;
    }

    render() {
        const { yearQuarterData } = this.state;

        return (            
            <div className={'content-block'}>   
                {/*<div className="option">
                    <CheckBox text="Include InActive Records" style={{marginLeft: "0"}}
                    value={this.state.includeInactiveRecords}
                    onValueChanged={this.includeInactiveRecordsValueChanged} />
                </div>*/}             
                <DataGrid
                    id="gridContainer"
                    dataSource={yearQuarterData}
                    allowColumnReordering={false}
                    showBorders={true}
                    //onInitNewRow={this.onInitNewRow}
                    onEditorPreparing={this.onEditorPreparing}
                    onRowValidating={this.onRowValidating}
                    onSaving={this.onSaving}
                    ref={ref=>this.dataGrid=ref}
                >
                   
                    <FilterRow visible={false} />
                    <Paging enabled={true} pageSize={25} />

                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={false}
                        allowAdding={false} />
                    
                    <Column dataField="year" dataType="string" caption="Year" width={140} allowEditing={false} allowSorting={false} />
                    <Column dataField="fiscalYearDesc" dataType="string" caption="FY" width={140} allowEditing={false} allowSorting={false} />
                    <Column dataField="quarterDescription" dataType="string" caption="Quarter Description" width={180} allowEditing={false} allowSorting={false} />
                    <Column dataField="thruDescription" dataType="string" caption="Thru Description" width={180} allowEditing={false} allowSorting={false} />
                    <Column dataField="accountingCloseDate" dataType="date" caption="Accounting Close Date" width={180} allowSorting={false} />
                    <Column dataField="dueDate" dataType="date" caption="Due Date" width={180} allowSorting={false} />
                    <Column dataField="taxRate" dataType="decimal(18,2)" caption="Tax Rate" width={140} allowSorting={false} customizeText={this.taxRatecustomizeText}/>

                    {/*<Column dataField="isActive" dataType="boolean" width={130} allowSorting={false} />
                    <Column dataField="sortOrder" dataType="integer" width={100} caption="Sort Order" allowSorting={false} visible={false} />*/}  

                    <Column type="buttons">
                        <Button name="save" hint="Save" />
                        <Button name="edit" hint="Edit" />
                        {/*<Button text="Disable" hint="Disable" onClick={this.disableTypeOfRental} visible={this.disableButtonIsVisible} />*/}
                        {/*<Button text="Enable" hint="Enable" onClick={this.enableTypeOfRental} visible={this.enableButtonIsVisible} />*/}
                    </Column>
                </DataGrid>
            </div>
        );
    }
}

export default quarters;