import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './utils/bootstrap/bootstrap.min.css';
import React, { Component } from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import './App.scss';
import './dx-styles.scss';
import { LoginForm } from './components';
import CertificateRegistration from './pages/public/CertificateRegistration/CertificateRegistration';
import { sizes, subscribe, unsubscribe } from './utils/media-query';
import { TOTLandingPad } from './pages';
import ProtectedRoutes from './_routes/ProtectedRoutes';
import PublicRoute from './_routes/PublicRoute';
import PrivateRoute from './_routes/PrivateRoute';
import AuthLayout from './layouts/AuthLayout';
import AuthService from './services/AuthService';
import CertNumberSearch from './pages/public/TOTReturn/CertNumberSearch';
import TaxReturnRejectTemplate from './components/TaxReturnDetails/TaxReturnRejectTemplate';


class App extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loggedIn: AuthService.isAuthorized(),
            screenSizeClass: this.getScreenSizeClass()
        };
    }

    componentDidMount() {
        subscribe(this.screenSizeChanged);
        if (!this.state.loggedIn)
            AuthService.getAuthorization().then((res) => this.setState({ loggedIn: AuthService.isAuthorized() }))
    }

    componentWillUnmount() {
        unsubscribe(this.screenSizeChanged);
    }

    getScreenSizeClass() {
        const screenSizes = sizes();
        return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
    }

    screenSizeChanged = () => {
        this.setState({
            screenSizeClass: this.getScreenSizeClass()
        });
    }

    render() {
        const { loggedIn } = this.state;
        return (
            <div className={`app ${this.state.screenSizeClass}`}>
                {
                   (process.env.REACT_APP_ENV !== "PROD") && <div className={'env-alert'}>{process.env.REACT_APP_ENV} ENVIRONMENT</div>
                }
                <Router>
                    <Switch>
                         <PublicRoute exact path="/login" isLoggedIn={loggedIn}>
                            <LoginForm />
                        </PublicRoute>
                        <PublicRoute exact path="/TransientOccupancyTax">
                            <TOTLandingPad />
                        </PublicRoute>
                        <PublicRoute exact path="/CertificateRegistration">
                            <CertificateRegistration></CertificateRegistration>
                        </PublicRoute>
                        <PublicRoute exact path="/CertNumberSearch">
                            <CertNumberSearch></CertNumberSearch>
                        </PublicRoute>
                        <PrivateRoute isLoggedIn={loggedIn}>
                            <AuthLayout>
                                <ProtectedRoutes></ProtectedRoutes>
                            </AuthLayout>
                        </PrivateRoute>
                      
                    </Switch>

                </Router>
            </div>
        );
    }
}

export default App;
