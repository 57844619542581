import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import ReportList from '../../components/report/report_reconciliation_overview';

function ReportReconciliation(props) {
    return (
        <div className="content-block">
        
            <PageHeader header="Reconciliation Report" />
            <ReportList></ReportList>
        </div>
    );
}

export default ReportReconciliation;