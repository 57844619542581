import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import CommunityList from '../../components/community/community_overview';

function ManageCommunity(props) {
    return (
        <div className="content-block">
        
             <PageHeader header="Manage Community" />
            <CommunityList></CommunityList>
        </div>
    );
}

export default ManageCommunity;