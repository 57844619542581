import React from 'react';
import appInfo from '../app-info';
import AuthService from '../services/AuthService';
import { navigation } from '../_routes/app-navigation';
import Footer from '../components/footer/footer';
import SideNavInnerToolbar from './side-nav-inner-toolbar/side-nav-inner-toolbar';

function AuthLayout(props) {

    const userMenuItems = [
        {
            text: 'Logout',
            icon: 'runner',
            onClick: () => AuthService.logout()
        }
    ];
    return (
        <SideNavInnerToolbar menuItems={navigation} title={appInfo.title} userMenuItems={userMenuItems} {...props}>
            {props.children}
            <Footer>
                Copyright © {(new Date().getFullYear())} San Bernardino County Auditor-Controller/Treasurer/Tax Collector - All Rights Reserved - {(process.env.REACT_APP_ENV !== "PROD") ? process.env.REACT_APP_ENV : ""}
                { }
            </Footer>
        </SideNavInnerToolbar>
    );
}

export default AuthLayout;
