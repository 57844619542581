import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../../_helper/util';
import { LoadPanel } from 'devextreme-react';
import AuthService from '../../services/AuthService';

import CertTemplate from './CertTemplate';
import ApprovalTemplate from './ApprovalTemplate';

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;

function CertWrapper(props) {
    const { id } = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState();
    const [step, setStep] = useState("Cert");
    const { user } = AuthService.getTOTUser();

    useEffect(() => {
        //debugger;

        const getCertTemplate = (propertyId) => {

            const endpointUrl = `${apiUrl}/Registration/CertTemplate?propertyId=${propertyId}&employeeId=${user.employeeId}`;
            return sendRequest(endpointUrl, "GET", '')
                .then(response => {
                    //debugger;
                    if (response.status === 404) {
                        history.push("/Home")
                    }
                    return response;
                })
                .catch(err => console.error(err));
        }

        getCertTemplate(id)
            .then((res) => {
                //debugger;
                const { user } = AuthService.getTOTUser();

                setFormData({
                    CertTemplateContent: res.templateContent,
                    PaperlessCommunication: res.paperlessCommunication,
                    OwnerEmail: res.ownerEmail,
                    ModifiedBy: user.employeeId,
                    PropertyId: parseInt(id)
                });
                setIsLoading(false);
            });

    }, [id, history])

    const getApprovalTemplate = (propertyId) => {
        

        const endpointUrl = `${apiUrl}/Registration/ApprovalTemplate?propertyId=${propertyId}&employeeId=${user.employeeId}`;
        return sendRequest(endpointUrl, "GET", '')
            .then(response => {
                //debugger;
                if (response.status === 404) {
                    history.push("/Home")
                }
                return response;
            })
            .catch(err => console.error(err));
    }

    const handleNext = () => {

        getApprovalTemplate(id)
            .then((res) => {
                //debugger;

                setFormData({
                    ...formData,
                    ApprovalTemplateContent: res.templateContent
                });
                setStep("Approval");
            });      
    }

    const handleCancelCertTemplate = () => {
        history.goBack();
    }

    const handleCertContentChange = (e) => {
        
        setFormData({
            ...formData,
            CertTemplateContent: e.value,            
        })
    }

    const handleAttachAndSend = () => {
        //debugger;

        const body = JSON.stringify(formData);

        return sendRequest(apiUrl + "/Registration/AttachAndSend", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                history.push("/CertAppPage");
            }
        });
    }

    const handleCancelApprovalTemplate = () => {
        setStep("Cert");
    }

    const handleApprovalContentChange = (e) => {
        //debugger;
        setFormData({
            ...formData,
            ApprovalTemplateContent: e.value,            
        })
    }

    return (
        <div>

            {!isLoading ? (
                <div>
                    {step === "Cert" &&
                        <CertTemplate formData={formData}
                            handleCertContentChange={handleCertContentChange}
                            handleNext={handleNext}
                            handleCancelCertTemplate={handleCancelCertTemplate} />}
                
                    {step === "Approval" &&
                        <ApprovalTemplate formData={formData}
                            handleApprovalContentChange={handleApprovalContentChange}
                            handleCancelApprovalTemplate={handleCancelApprovalTemplate}
                            handleAttachAndSend={handleAttachAndSend}
                        />}
                </div>
                )
                : <LoadPanel></LoadPanel>
            }
        </div>
      );
}

export default CertWrapper;