import React, { useEffect, useState } from 'react';
//import { Button } from 'react-bootstrap';
import TemplateEditor from './TemplateEditor';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../../_helper/util';
import { LoadPanel } from 'devextreme-react';
import AuthService from '../../services/AuthService';
import ExportPdfComponent from '../exportpdfcomponent/ExportPdfComponent'
import _, { isEqual } from 'underscore';
import PageHeader from '../header/PageHeader/PageHeader';
import TemplateButtons from '../Template/TemplateButtons';

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
function EditTemplate(props) {
    const { id } = useParams();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [templateDetails, setTemplateDetails] = useState();
    const [formData, setFormData] = useState();
    const [message, setMessage] = useState();
    const [definitions, setDefinitions] = useState([]);

    useEffect(() => {
        const getTemplate = (templateId) => {
            const endpointUrl = `${apiUrl}/Template/${templateId}`;
            return sendRequest(endpointUrl, "GET", '')
                .then(response => {
                    if (response.status === 404) {
                        history.push("/ManageTemplates")
                    }
                    return response;
                })
                .catch(err => console.error(err));
        }

        getTemplate(id)
            .then((res) => {
                setTemplateDetails(res);
                setFormData(res);
                setIsLoading(false);
            });

        getDictionary()
            .then(response => {
                setDefinitions(response);
            });
    }, [id, history])

    const getDictionary = () => {
        return sendRequest(`${apiUrl}/Template/getVariables`, "GET", '');
    }

    const handleCancel = () => {
        history.push("/ManageTemplates")
    }

    const handleContentChange = (e) => {
        const { user } = AuthService.getTOTUser();
        setMessage(null);
        setFormData({
            ...formData,
            templateContent: e.value,
            modifiedBy: user.employeeId,
            modifiedDate: new Date()
        })
    }

    const handleSave = () => {
        if (isEqual(templateDetails.templateContent, formData.templateContent)) {
            setMessage("There are no changes to be made")
        }
        else {
            return sendRequest(`${apiUrl}/Template/${id}`, "PUT", JSON.stringify(formData))
                .then(() => {
                    history.push("/ManageTemplates")
                })
                .catch(err => console.error(err));
        }
    }

    return (

        <div className={'content-block'}>

            {!isLoading ?
                <>
                    <PageHeader header="Manage Templates" subHeader={`Edit Template - ${templateDetails.type}`} />
                    <div style={{ minHeight: "1080px", position: "relative" }}>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='card'>
                                    <div className='card-body'>
                                        {/* <h5 className='card-title'>Legend</h5> */}
                                        <p className='fs-6 lh-1'>{`The items below can be used in the template to designate values that will need to be replaced when generating document. They can be automatically inserted
                                    by clicking the`}<b>{` <#> `}</b>{`button on the editor toolbar. `}</p>
                                        <div className='row'>
                                            {_.map(definitions, (val, key) => {
                                                return <span style={{ fontSize: "14px" }} key={val} className='fw-light col-md-4 col-xl-3'>{val}</span>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row h-100 mt-2' style={{ minHeight: "650px" }}>
                            <div className='col-md-6'>
                                <TemplateEditor
                                    templateContent={templateDetails.templateContent}
                                    height="100%"
                                    valueType="html"
                                    onContentChange={handleContentChange}
                                    variables={definitions}
                                >
                                </TemplateEditor>
                            </div>
                            <div className='col-md-6' style={{ position: "relative" }}>
                                <ExportPdfComponent
                                    templateContent={formData.templateContent}
                                    allowRefresh={true} height="100%" width="100%" >
                                </ExportPdfComponent>
                                <TemplateButtons
                                    handleCancel={handleCancel}
                                    handleSave={handleSave}
                                    saveButtonLabel="Save"
                                    message={message && <div className='mx-2' style={{ fontSize: '14px', color: '#d32f2f', position: "absolute", bottom: "-27px", left: "-45px" }}>{message}</div>}>
                                </TemplateButtons>
                            </div>
                        </div>
                    </div>
                </>
                : <LoadPanel></LoadPanel>}
        </div>

    );
}

export default EditTemplate;