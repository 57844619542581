import React from 'react';
import { Button } from 'react-bootstrap';

function RegisterCertWrapper(props) {
    const { children, title, subtitle, step, onBackClick, onNextClick, onSubmit,isSubmitting } = props;

    return (
        <div>            
            <h2 className='step-title'><b>{title || "Register for a Certificate"}</b> - {step}</h2>
            <h4><b>{subtitle}</b></h4>
            <hr style={{ borderTop: "2px solid rgba(0,45,115,.9)"}} />
            <>
                {children}
            </>
            <div className='action-button-wrapper float-end'>
                {onBackClick &&
                    <Button variant="secondary" className='action-button' onClick={(e) => onBackClick(e)}>Back</Button>
                }
                {onNextClick &&
                    <Button variant="primary" className='action-button' onClick={(e) => onNextClick(e)}>Next</Button>
                }
                {onSubmit &&
                    <Button variant="primary" className='action-button' onClick={(e) => onSubmit(e)} disabled={isSubmitting}>
                    {isSubmitting ? " Submitting..." : " Submit"}
                    </Button>
                }
            </div>
        </div>
    );
}

export default RegisterCertWrapper;