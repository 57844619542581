//import React, { useEffect } from 'react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({children, isLoggedIn, ...props}) {
   
    return (
        <>
        {isLoggedIn && <Route exact path="/">
            <Redirect to="/CertAppPage" />
        </Route>}
        <Route
        {...props}
        render={
          ({ location }) => (
            isLoggedIn 
              ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: location }
                  }}
                />
              
              ))
        }></Route></>
    );
}

export default PrivateRoute;