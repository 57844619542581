import React from 'react';
import ValidationGroup from 'devextreme-react/validation-group';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import './login-form.scss';
import sbclogo from "../../img/sb_cologo-full_color_300.png"
import { SingleCard } from '../../layouts';
import AuthService from '../../services/AuthService';
import authContext from '../../services/Auth'

require('dotenv').config({ debug: false })
//const url = process.env.REACT_APP_API_URL + "/api";
authContext.handleWindowCallback()
export default class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { loggingIn: false }
        this.login = this.login.bind(this);
    }

    login(){    
        this.setState({ loggingIn: true });         
        AuthService.login(); 
    }
  
    render() {
        const { loggingIn } = this.state;
        return (
            <SingleCard>

                <ValidationGroup >
                    <div id="divLogin">
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={{ of: '#root' }}
                            visible={loggingIn}
                            showIndicator={true}
                            message={"Please wait.."}
                            shading={true}
                            showPane={true}
                        />
                        <div className={'login-header'}>
                            <img src={sbclogo} alt="logo" />
                            <div className={'TOTTitle'}>Transient Occupancy Tax</div>
                            <div className={'AdminTitle'}>Administrative Login</div>
                            <div className={'AdminInstruction'}></div>
                        </div>

                        <div className={'dx-field'}>
                            <Button
                                type={'default'}
                                icon={'user'}
                                text={'Click to log in'}
                                onClick={this.login}
                                width={'100%'}
                                height={'50px'}
                            />
                        </div>
                    </div>
                </ValidationGroup>
            </SingleCard>
        );
    }

}
