import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from 'devextreme-react';
import { Column, Editing, HeaderFilter } from 'devextreme-react/data-grid';
import { sendRequest } from '../../_helper/util';
import AuthService from '../../services/AuthService';
import CustomStore from 'devextreme/data/custom_store';
import { Info } from './TaxReturnComponents';

TaxReturnNotes.propTypes = {
taxReturnId: PropTypes.number.isRequired
};

const apiUrl = process.env.REACT_APP_API_URL;

function TaxReturnNotes({ taxReturnId, ...props }) {
    const [notes, setNotes] = useState([]);
    const userId = AuthService.getTOTUser().user.employeeId;
    useEffect(() => {
        refreshAll();
    }, [taxReturnId])

    const getNotes = () => {
        const endpointUrl = apiUrl + `/api/TaxReturn/Notes/${taxReturnId}`;
        return sendRequest(endpointUrl, "GET", '');
    }

    const handleAddNotes = (note) => {
        note.createdDate = new Date();
        note.createdBy = userId;
        note.taxReturnId = taxReturnId;

        const endpointUrl = apiUrl + `/api/TaxReturn/Notes/${taxReturnId}`;
        return sendRequest(endpointUrl, "POST", JSON.stringify(note));
    }

    const refreshAll = () => {

        setNotes(new CustomStore({
            key: 'taxReturnNoteId',
            load: getNotes,
            insert: handleAddNotes
        }))
    }

    return (
        <div className='mt-3 mb-3 p-3 bg-light rounded'>
            {/* <Info label="Notes" /> */}
            <DataGrid dataSource={notes} >
                <HeaderFilter visible={true} />
                <Editing
                    mode="row"
                    allowAdding={true} />
                <Column dataField="createdDate" dataType="datetime" allowEditing={false}></Column>
                <Column dataField="createdBy" allowEditing={false}></Column>
                <Column dataField="note" allowAdding={true}></Column>
            </DataGrid>
        </div>
    );
}

export default TaxReturnNotes;