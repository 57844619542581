import React from "react";
import { Col, Row } from 'react-bootstrap';

export const Info = ({ label, value, ...props }) => {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: '8px', marginBottom: '8px' }}>
                <span style={{ color: "#616161" }}>{label}</span> 
                <span style={{ color: "#616161" }}>{value || <>&nbsp;</>}</span>
            </div>
        </div>
    )
}

export const InfoBold = ({ label, value, subvalue, ...props }) => {
    return (
        // <div>
        //     <div style={{ display: "flex", justifyContent: "start", flexDirection: "row", marginTop: '8px', marginBottom: '8px' }}>
        //         <span style={{ color: "#616161" }}><b>{label}</b></span> 
        //         <span style={{ color: "#616161", textAlign: "left" }}>
        //             {value || <>&nbsp;</>} 
        //             {subvalue ? <br/> : ""}
        //             {subvalue || <>&nbsp;</>}
        //         </span>
        //     </div>
        // </div>
        <div>
            <div style={{ marginTop: '8px', marginBottom: '4px', color: "#616161" }}>
                <b>{label}</b>
            </div>
            <div style={{ marginTop: '4px', marginBottom: '8px', color: "#616161" }}>
                {value || <>&nbsp;</>} 
                {subvalue ? <br/> : ""}
                {subvalue || <>&nbsp;</>}
            </div>
        </div>
    )
}

export const InfoTotal = ({ label, value, ...props }) => {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: '8px', marginBottom: '8px' }}>
                <span style={{ color: "#616161", fontSize: "15px" }}><b>{label}</b></span> 
                <span style={{ color: "#616161", fontSize: "15px" }}><b>{value || <>&nbsp;</>}</b></span>
            </div>
        </div>
    )
}

export const InfoSpecial = ({ label, value, ...props }) => {
    return (
        <div style={{ display: "flex", 
                      justifyContent: "space-between", 
                      flexDirection: "row", 
                      paddingTop: '4px',
                      paddingBottom: '4px',
                      marginTop: '16px', 
                      marginBottom: '16px', 
                      backgroundColor: '#f8f9fa',
                      borderStyle: 'solid hidden solid hidden',
                      borderWidth: '1px',
                      borderColor: '#e0e0e0'
                    }}>
            <span style={{ color: "#616161" }}><b>{label}</b></span>
            <span style={{ color: "#616161" }}><b>{value || <>&nbsp;</>}</b></span>
        </div>
    )
}

export const Section = ({ subtitle, subtitle_left, subtitle_right, children, ...props }) => {
    return (
        <div>
            <div className="row justify-content-between" style={{position:"relative"}}>
                <div className="col" style={{padding: "10px 12px 0px 12px"}}>
                    <div style={{color: "rgba(97,97,97)", fontSize: "18px"}}><b>{subtitle}</b></div>
                    <div>{subtitle_left}</div>
                </div>
                <div className="col-md-auto" >
                    <div style={{position:"absolute", right:"12px", bottom: "0px"}}>{subtitle_right}</div>
                </div>
            </div>
            <hr style={{ borderBottom: "2px solid rgba(0,45,115,.9)", margin: "12px 0 16px 0"}} />
            <div> {children}</div>
        </div>
    )
}

export const DetailsInfo = ({ label, value, ...props }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: '8px' }}>
            <span style={{color: "#757575", fontStyle: "italic", fontSize: '12px' }} className="fw-bold">{label}</span>
            <span style={{ color: "#757575" }}>{value || <>&nbsp;</>}</span>
        </div>
    )
}

export const DetailsSection = ({ subtitle, children, ...props }) => {
    return (
        <div {...props}>          
            <h6 style={{color: "#757575"}}>{subtitle}</h6>
            <hr style={{ borderTop: "1px solid rgba(0,45,115,.9)", margin: 0}} />
            <div style={{backgroundColor: "white", padding: '8px' }}> {children}</div>
        </div>
    )
}