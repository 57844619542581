import React from 'react';
import Container from "react-bootstrap/Container";

class ThankYou extends React.Component {
    //constructor() {
    //    super();
    //}

    render() {
        return (
            <Container>
                <h1>Application has been submitted</h1>
                <h2>Thank you for submitting your application for a TOT Certificate. The Tax Collector staff will review your application. Please check your email for any correspondence.</h2>
            </Container>
        );
    }
}

export default ThankYou;