import React from 'react';
import "../../utils/bootstrap/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import SearchCertWrapper from './searchcert_wrapper';
import InputMask from 'react-input-mask';
import { sendPublicRequest } from '../../_helper/util.js';

const url = process.env.REACT_APP_API_URL + "/api";
//const history = useHistory();

class SearchCertNumber extends React.Component {   
    constructor(props) {
        super(props);

        this.state = {
            divisionPhoneNumber: ""
        };
    }
    back = (e) => {
        e.preventDefault();
        this.props.handleSetStepTo2();
        this.props.prevStep();
    }

    saveAndContinue = (e) => {
        //debugger;
        e.preventDefault();

        let errors = [];
        //owner name
        if (this.props.inputValues.apn === "") {
            errors.push("enteredAPN");
        }
       
        this.props.handleHasError(errors);

        if (errors.length > 0) {
            return false;
        } else {
            //debugger;
            // Retrieve the property ID if apn exists, otherwise error.
            this.retrievePropertyInfoByAPN(errors).then((errors) =>
            {
                if (errors.length !== 0) {
                    this.props.handleHasError(errors);
                } else {
                    //this.props.nextStep();

                    if (this.props.inputValues.unpaidTaxReturnCount > 0) {
                        this.props.nextStep();
                    }
                    else {
                        //check if this property has new tax return year quarters
                        sendPublicRequest(url + "/TaxReturn/CheckNewTaxReturnYearQuarter?propertyId=" + this.props.inputValues.propertyId, 'GET', '').then(myJson => {
                            if (typeof myJson.message !== "undefined") {
                                throw myJson.message;
                            }
                            else {
                              
                                if (myJson.hasNewTaxReturnYearQuarter) {
                                    this.props.nextStep();
                                }
                                else {
                                    this.setState({ divisionPhoneNumber: myJson.divisionPhoneNumber });

                                    this.props.handleHasError("HasNoNewTaxReturnQuarter");
                                }

                            }
                        });
                    }
                }       
            })
        }
    };

    hasError(key) {
        return this.props.hasError(key); 
    }

    retrievePropertyInfoByAPN = (errors) => {
        //debugger;
        return sendPublicRequest(url + "/Property/RetrievePropertyIdUnpaidTaxReturnCountByAPN?apn=" + this.props.inputValues.apn, 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                if(myJson.PropertyId === undefined)
                {
                    errors.push("apnNotFound");
                } else {
                    this.props.handlePropertyIdChange(myJson.PropertyId);
                    this.props.handleUnpaidTaxReturnCountChange(myJson.UnpaidTaxReturnCount);

                    if(myJson.StatusDescription === "CLOSED")
                    {
                        errors.push("certClosed");
                    }
                    else if(myJson.PropertyId === 0)
                    {
                        errors.push("apnNotFound");
                    }
                }
                
                return errors;
            }
        });
    }

    render() {
        const { divisionPhoneNumber } = this.state;
        return (
            <SearchCertWrapper title='Search for a Certificate' subtitle="" step="" onBackClick={this.back} onNextClick={this.saveAndContinue}>
                <Container className="pt-5">
                    <div className="mt-4 p-5 rounded text-left border border-secondary" style={{}}>
                        <Container>
                            <h3 className='mb-3'>Enter assessor parcel number (APN) of the property you will be making a payment for:</h3>
                            <InputMask
                                className={
                                    this.hasError("enteredAPN")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                formatChars={{
                                    "9": "[0-9]"
                                }}
                                mask="9999-999-99-9999"
                                maskChar=" "
                                onChange={this.props.handleAPNChange}
                                value={this.props.inputValues.apn}
                            ></InputMask>
                            <div className={"inline-errormsg"}>{this.hasError("enteredAPN") ? "Please enter a value" : null}</div>
                            <div className={"inline-errormsg"}>{this.hasError("certClosed") ? "Certificate is closed. Tax returns cannot be filed for this certificate." : null}</div>
                            <div className={"inline-errormsg"}>{this.hasError("apnNotFound") ? "Assessor parcel number was not found.  Please enter another assessor parcel number or click the [back] button to search by certificate number." : null}</div>
                            <div className={"inline-infomsg"}>{this.hasError("HasNoNewTaxReturnQuarter") ? "There are no outstanding unpaid Tax Returns and no reporting period to file. If you have any questions, please call: " + divisionPhoneNumber + "." : null}</div>

                        </Container>
                    </div>
                </Container>
            </SearchCertWrapper>
        )        
    }
}

export default SearchCertNumber;