import React from 'react';
//import ContextMenu, { Position } from 'devextreme-react/context-menu';
import './TOTRegistration.scss';
import OwnerDetails from "./registercert_ownerdetails";
import RentalPropDetails from "./registercert_rentalpropdetails";
//import MailingAddressDetails from "./registercert_mailingaddressdetails";
import MiscDetails from "./registercert_miscdetails";
import ReviewDetails from "./registercert_review";
import ThankYou from "./registercert_thankyou";
//import AdalConfig from '../../config/AdalConfig';
//import { getAdalToken } from '../../_helper/adalToken';
//import AuthContext from '../../services/Auth';
//import notify from 'devextreme/ui/notify';
import { sendPublicRequest } from '../../_helper/util.js';
import "../../utils/bootstrap/bootstrap.min.css";

require('dotenv').config({ debug: false })
const url = process.env.REACT_APP_API_URL + "/api";

export default class TOTRegistration extends React.Component {

    state = {
        step: 1,
        ownerName: '',
        ownerPhoneNumber: '',
        busMgmtCoName: '',
        email: '',
        rentalPropAddress: '',
        rentalPropAddress2: '',
        rentalPropCity: '',
        rentalPropState: 'CA',
        rentalPropZip: '',
        apn: '',
        occUnitsNo: 1,
        mailingAddress: '',
        mailingAddress2: '',
        mailingCity: '',
        mailingState: '',
        mailingZip: '',
        paperlessCommChecked: true,
        ownedBusLength: '',
        typeOfRentalId: 0,
        typeOfRental: '',
        typeOfRentalOther: '',
        typeOfRentalOther_Disabled: true,
        certified: false,
        preparedBy: '',
        title: '',
        preparedByPhoneNumber: '',
        errors: [],
        saveErrorEncountered: false,
        saveError: '',
        isSubmitting:false
    }

    //state = {
    //    step: 1,
    //    ownerName: 'Jason H',
    //    ownerPhoneNumber: '9095551212',
    //    busMgmtCoName: '',
    //    email: 'me@email.com',
    //    rentalPropAddress: '123',
    //    rentalPropAddress2: '',
    //    rentalPropCity: 'Mentone',
    //    rentalPropState: 'CA',
    //    rentalPropZip: '92373',
    //    apn: '',
    //    occUnitsNo: 0,
    //    mailingAddress: '321',
    //    mailingAddress2: '',
    //    mailingCity: 'Redlands',
    //    mailingState: 'CA',
    //    mailingZip: '92456',
    //    paperlessCommChecked: true,
    //    ownedBusLength: '',
    //    typeOfRentalId: 1,
    //    typeOfRental: '',
    //    typeOfRentalOther: '',
    //    typeOfRentalOther_Disabled: true,
    //    certified: false,
    //    preparedBy: 'Me',
    //    title: '',
    //    preparedByPhoneNumber: '9098881212',
    //    errors: [],
    //    saveErrorEncountered: false,
    //    saveError: '',
    //}
  
    nextStep = () => {
        const { step } = this.state
        this.setState({
            step: step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step: step - 1
        })
    }

    handleChange = (event) => {
        switch (event.target.name) {
            case 'paperlessComm':
                this.setState({ paperlessCommChecked: !this.state.paperlessCommChecked });
                break;
            case 'certified':
                this.setState({ certified: !this.state.certified });
                break;
            default:
                this.setState({ [event.target.name]: event.target.value });
                break;
        }        
        var index = this.state.errors.indexOf(event.target.name);
        if (index > -1) { 
            // Additional Checks
            switch (event.target.name) {
                case 'email':
                    const expression = /\S+@\S+\.\S+/;
                    let validEmail = expression.test(String(event.target.value).toLowerCase());
                    if (validEmail) {
                        this.state.errors.splice(index, 1);
                    } else {
                        this.state.errors.push("email");
                    }
                    break;
                default:
                    this.state.errors.splice(index, 1);
                    break;
            }            
        }
    }

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    }

    handleHasError = (errors) => {
        this.setState({ errors })
    }

    handleOwnerPhoneNumberChange = (event) => {
        this.setState({ ownerPhoneNumber: event.target.value });
        var index = this.state.errors.indexOf("ownerPhoneNumber");
        if (index > -1) { 
            var phoneToCheck = event.target.value;

            phoneToCheck = phoneToCheck.replace("+1 (", "");
            phoneToCheck = phoneToCheck.replace(") ", "");
            phoneToCheck = phoneToCheck.replace("-", "");
            phoneToCheck = phoneToCheck.replaceAll(" ", "");
    
            if (phoneToCheck.length === 10) {
                this.state.errors.splice(index, 1);
            }            
        }
    }

    handleMailingZipChange = (event) => {
        this.setState({ mailingZip: event.target.value });

        var mailingZipToCheck = event.target.value;
        
        mailingZipToCheck = mailingZipToCheck.replaceAll(" ", "");

        var index = this.state.errors.indexOf("mailingZip");

        if (index > -1) {   
            if ((mailingZipToCheck.length < 5) || (mailingZipToCheck.length > 5 && mailingZipToCheck.length < 10)) {
                this.state.errors.push("mailingZip");
            } else {
                this.state.errors.splice(index, 1);
            }
        }
    }

    handleRentalZipChange = (event) => {
        this.setState({ rentalPropZip: event.target.value });

        var rentalZipToCheck = event.target.value;
        
        rentalZipToCheck = rentalZipToCheck.replaceAll(" ", "");

        var index = this.state.errors.indexOf("rentalPropZip");

        if (index > -1) {   
            if ((rentalZipToCheck.length < 5) || (rentalZipToCheck.length > 5 && rentalZipToCheck.length < 10)) {
                this.state.errors.push("rentalPropZip");
            } else {
                this.state.errors.splice(index, 1);
            }
        }
    }

    handleTypeOfRentalChange = (event) => {
        this.setState({ typeOfRentalId: event.value });
        this.setState({ typeOfRental: event.event.currentTarget.textContent });
        if (event.event.currentTarget.textContent.toLowerCase() === 'other') {
            this.setState({ typeOfRentalOther_Disabled: false });
        } else {
            this.setState({ typeOfRentalOther_Disabled: true });
        }

        var index = this.state.errors.indexOf("typeOfRental");

        if (index > -1) {
            let errors = this.state.errors;
            errors.splice(index, 1);
            this.setState({ errors: [...errors] });
        }
    }

    handleUnitNumberChange = (event) => {
        this.setState({ occUnitsNo: event.target.value });
    }

    handlePreparedByPhoneNumberChange = (event) => {
        this.setState({ preparedByPhoneNumber: event.target.value });

        var index = this.state.errors.indexOf("preparedByPhoneNumber");
        if (index > -1) { 
            var phoneToCheck = event.target.value;

            phoneToCheck = phoneToCheck.replace("+1 (", "");
            phoneToCheck = phoneToCheck.replace(") ", "");
            phoneToCheck = phoneToCheck.replace("-", "");
            phoneToCheck = phoneToCheck.replaceAll(" ", "");
    
            if (phoneToCheck.length === 10) {
                this.state.errors.splice(index, 1);
            }            
        }
    }

    handleAPNChange = (event) => {
        this.setState({ apn: event.target.value });
    }

    handleSubmit = () => {
        this.setState({ isSubmitting: true });
        this.setState({ saveError: '' });
        this.setState({ saveErrorEncountered: false });

        const myObj = {
            OwnerName: this.state.ownerName,
            OwnerPhoneNumber: this.state.ownerPhoneNumber,
            Apn: this.state.apn,
            RentalAddress: this.state.rentalPropAddress,
            RentalAddress2: this.state.rentalPropAddress2,
            RentalCity: this.state.rentalPropCity,
            RentalState: this.state.rentalPropState,
            RentalZip: this.state.rentalPropZip,
            BusMgmtName: this.state.busMgmtCoName,
            MailingAddress: this.state.mailingAddress,
            MailingAddress2: this.state.mailingAddress2,
            MailingCity: this.state.mailingCity,
            MailingState: this.state.mailingState,
            MailingZip: this.state.mailingZip,
            OwnerEmail: this.state.email,
            PaperlessCommunication: this.state.paperlessCommChecked,
            TypeOfRentalId: this.state.typeOfRentalId,
            TypeOfRentalOther: this.state.typeOfRentalOther,
            LengthOfOwnership: this.state.ownedBusLength,
            OccupancyUnitsNumber: parseInt(this.state.occUnitsNo),
            PreparedBy: this.state.preparedBy,
            Title: this.state.title,
            PreparedByPhoneNumber: this.state.preparedByPhoneNumber
        };
        
        const body = JSON.stringify(myObj);

        
        sendPublicRequest(url + '/Registration/CreateRegistration', 'POST', body).then(myJson => {
            this.setState({ isSubmitting: false });
            if (typeof myJson.message !== "undefined") {
                this.setState({ saveError: myJson.message });
                this.setState({ saveErrorEncountered: true });
            }
            else {
                this.nextStep();
            }
        });
    }

    render() {
        const { step, ownerName, ownerPhoneNumber, busMgmtCoName, email, rentalPropAddress, rentalPropAddress2, rentalPropCity, rentalPropState, rentalPropZip, apn, occUnitsNo, mailingAddress, mailingAddress2, mailingCity, mailingState, mailingZip, paperlessCommChecked, ownedBusLength, typeOfRental, typeOfRentalId, typeOfRentalOther, typeOfRentalOther_Disabled, certified, preparedBy, title, preparedByPhoneNumber, saveErrorEncountered, saveError,isSubmitting } = this.state;
        const inputValues = { ownerName, ownerPhoneNumber, busMgmtCoName, email, rentalPropAddress, rentalPropAddress2, rentalPropCity, rentalPropState, rentalPropZip, apn, occUnitsNo, mailingAddress, mailingAddress2, mailingCity, mailingState, mailingZip, paperlessCommChecked, ownedBusLength, typeOfRental, typeOfRentalId, typeOfRentalOther, typeOfRentalOther_Disabled, certified, preparedBy, title, preparedByPhoneNumber, saveErrorEncountered, saveError,isSubmitting };
        switch (step) {
            case 1:
                return <OwnerDetails
                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                    handleOwnerPhoneNumberChange={this.handleOwnerPhoneNumberChange}
                    inputValues={inputValues}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                />
            case 2:
                return <RentalPropDetails
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    inputValues={inputValues}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                    handleRentalZipChange={this.handleRentalZipChange}
                    handleUnitNumberChange={this.handleUnitNumberChange}
                    handleAPNChange={this.handleAPNChange}
                    handleMailingZipChange={this.handleMailingZipChange}
                />
            /*case 99:
                return <MailingAddressDetails
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    inputValues={inputValues}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                    handleMailingZipChange={this.handleMailingZipChange}
                />*/
            case 3:
                return <MiscDetails
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    handleTypeOfRentalChange={this.handleTypeOfRentalChange}
                    inputValues={inputValues}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                />
            case 4:
                return <ReviewDetails
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    inputValues={inputValues}
                    handlePreparedByPhoneNumberChange={this.handlePreparedByPhoneNumberChange}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                    handleSubmit={this.handleSubmit}
                />
            case 5:
                return <ThankYou />
            default:
                return;
        }
    }
}
