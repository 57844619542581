import React, { Component } from 'react';
import DataGrid,
{
    Column,
    Editing,
    Button,
    Paging,
    FilterRow,
    RowDragging
} from 'devextreme-react/data-grid';

import { confirm } from 'devextreme/ui/dialog';
import CheckBox from 'devextreme-react/check-box';

import CustomStore from 'devextreme/data/custom_store';

import { sendRequest } from '../../_helper/util.js';
import SecureLS from "secure-ls";

import './onlineplatform_overview.scss';

const url = process.env.REACT_APP_API_URL + "/api";

class onlineplatform extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onlinePlatformData: new CustomStore({
                key: 'onlinePlatformCode',
                load: this.loadOnlinePlatforms,
                update: this.updateOnlinePlatform,
                insert: this.insertOnlinePlatform
            }),
            includeInactiveRecords: false
        };

        this.onReorder = this.onReorder.bind(this);
    }

    onReorder(e) {
        e.promise = this.processReorder(e);
    }

    async processReorder(e) {
        //const newOrderIndex = visibleRows[e.toIndex].data.OrderIndex;
        var toIndex = e.toIndex + 1;
        var onlinePlatformCode = e.itemData.onlinePlatformCode;
        //debugger;

        await sendRequest(url + "/Lookup/ReorderOnlinePlatform?onlinePlatformCode=" + onlinePlatformCode + "&toIndex=" + toIndex + "&includeInactive=" + this.state.includeInactiveRecords, 'POST', '').then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
            }
        });

        await e.component.refresh();
    }

    loadOnlinePlatforms = () => {
        //debugger;
        var { includeInactiveRecords } = this.state;

        return sendRequest(url + "/Lookup/GetOnlinePlatforms_ForLookup?includeInactive=" + includeInactiveRecords, 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                return myJson.listOnlinePlatforms;
            }
        });
    }

    updateOnlinePlatform = (key, values) => {
        //debugger;
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        values.onlinePlatformCode = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);

        return sendRequest(url + "/Lookup/UpdateOnlinePlatform", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    insertOnlinePlatform = (values) => {

        const body = JSON.stringify(values);

        return sendRequest(url + "/Lookup/InsertOnlinePlatform", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    disableOnlinePlatform = (e) => {
        //debugger;
        let params;
        let description = e.row.data.description;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to disable this online platform: " + description + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {
                    params = { onlinePlatformCode: e.row.data.onlinePlatformCode };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/DisableOnlinePlatform", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    enableOnlinePlatform = (e) => {
        //debugger;
        let params;
        let description = e.row.data.description;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to enable this online platform: " + description + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {
                    params = { onlinePlatformCode: e.row.data.onlinePlatformCode };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/EnableOnlinePlatform", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    onInitNewRow = e => {
        //debugger;
        e.data.isActive = true;
    }

    onEditorPreparing = e => {
        if (e.dataField === "onlinePlatformCode" && e.parentType === "dataRow" && !e.row.isNewRow)
        {
           e.editorOptions.disabled = true;
        }
    }

    onRowValidating = (e) => {
        // debugger;
        e.isValid = true;
        e.errorText = "At least one box must be checked.";

        if (!e.oldData) { // new row
            e.isValid = (e.newData.remittedToCounty || e.newData.remittedToHost);
        } else {
            if (e.newData.remittedToCounty != null) {
                if (e.newData.remittedToHost != null) 
                    e.isValid = (e.newData.remittedToCounty || e.newData.remittedToHost);
                else 
                    e.isValid = (e.newData.remittedToCounty || e.oldData.remittedToHost);
            } else if (e.newData.remittedToHost != null) { // remittedToCounty is confirmed to be unchanged
                e.isValid = (e.oldData.remittedToCounty || e.newData.remittedToHost);
            }
        }
    
        if (!e.isValid) {
            this.dataGrid.instance.getCellElement(e.component.getRowIndexByKey(e.key), "remittedToCounty").style.border = "solid 2px rgb(232,152,150)";
            this.dataGrid.instance.getCellElement(e.component.getRowIndexByKey(e.key), "remittedToHost").style.border = "solid 2px rgb(232,152,150)";
        }
    }

    disableButtonIsVisible = e => {
        //debugger;
        if (!e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    enableButtonIsVisible = e => {
        //debugger;
        if (e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    includeInactiveRecordsValueChanged = e => {
        this.setState({ includeInactiveRecords: e.value });
        this.loadOnlinePlatforms();
        this.dataGrid.instance.refresh();
    }

    render() {
        const { onlinePlatformData } = this.state;

        return (
            <div className={'content-block'}>
                <div className="option">
                    <CheckBox text="Include InActive Records" style={{ marginLeft: "0" }}
                        value={this.state.includeInactiveRecords}
                        onValueChanged={this.includeInactiveRecordsValueChanged} />
                </div>
                <DataGrid
                    id="gridContainer"
                    dataSource={onlinePlatformData}
                    allowColumnReordering={true}
                    showBorders={true}
                    onInitNewRow={this.onInitNewRow}
                    onEditorPreparing={this.onEditorPreparing}
                    onRowValidating={this.onRowValidating}
                    ref={ref => this.dataGrid = ref}
                >

                    <RowDragging
                        allowReordering={true}
                        onReorder={this.onReorder}
                        dropFeedbackMode="push"
                    />

                    <FilterRow visible={false} />
                    <Paging enabled={false} />

                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true} />

                    <Column dataField="onlinePlatformCode" dataType="string" caption="Code" width={100} allowSorting={false} />
                    <Column dataField="description" dataType="string" caption="Online Platform" width={150} allowSorting={false} />

                    <Column dataField="isActive" dataType="boolean" width={130} allowSorting={false} />
                    <Column dataField="sortOrder" dataType="integer" width={100} caption="Sort Order" visible={false} />

                    <Column dataField="remittedToCounty" dataType="boolean" caption="Remitted To County" width={150} allowSorting={false} />
                    <Column dataField="remittedToHost" dataType="boolean" caption="Remitted To Host" width={150} allowSorting={false} />

                    <Column type="buttons">
                        <Button name="save" hint="Save" />
                        <Button name="edit" hint="Edit" />
                        <Button text="Disable" hint="Disable" onClick={this.disableOnlinePlatform} visible={this.disableButtonIsVisible} />
                        <Button text="Enable" hint="Enable" onClick={this.enableOnlinePlatform} visible={this.enableButtonIsVisible} />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}

export default onlineplatform;