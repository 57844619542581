import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import UserList from '../../components/users/users_overview';

function ManageUsers(props) {
    return (
        <div className="content-block">
        
             <PageHeader header="Manage Users" />
            <UserList></UserList>
        </div>
    );
}

export default ManageUsers;