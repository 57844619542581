import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import { runWithAdal } from "react-adal";
//import  authContext  from "./services/Auth";

//const DO_NOT_LOGIN = true;

//comment out runWithAdal for iframe use in MTC
//runWithAdal(authContext,()=>{
    ReactDOM.render(<App />, document.getElementById('root'));
//}, DO_NOT_LOGIN)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
