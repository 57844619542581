import React, { useEffect, useState } from 'react';
import { DataGrid, Column, Button, HeaderFilter } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { sendRequest } from '../../_helper/util.js';
import TaxReturnDetails from '../../components/TaxReturnDetails/TaxReturnDetails.js';
import PopupTitle from '../TaxReturnDetails/PopupTitle.js';

const url = process.env.REACT_APP_API_URL + "/api";


function TaxReturnPendingReview(props) {

    const [taxReturnData, setTaxReturnData] = useState();
    const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
    const [selectedTaxReturnId, setSelectedTaxReturnId] = useState(null);
    const [selectedTaxReturn, setSelectedTaxReturn] = useState();

    useEffect(() => {
        refresh();
    }, []);

    const refresh = () => {
        setTaxReturnData(
            new CustomStore({
                key: 'taxReturnId',
                load: () => getTaxReturn()
            })
        )
    }

    const getTaxReturn = () => {
        //debugger;
        return sendRequest(url + "/TaxReturn/GetTaxReturnPendingReview", 'GET', '').then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    const handleDetailsClick = (e) => {
        setDetailsPopupVisible(true);
        setSelectedTaxReturnId(e.row.data.taxReturnId);
        setSelectedTaxReturn(e.row.data);
        //debugger;
    }
    const hideDetails = () => {
        setDetailsPopupVisible(false);
        setSelectedTaxReturnId(null);
        setSelectedTaxReturn(null);
        refresh();
    }

    const popupTitleRender = () => {
        return <div>
            <PopupTitle statusCode={selectedTaxReturn.taxReturnStatusCode} status={selectedTaxReturn.taxReturnStatus} onClick={hideDetails}>
            </PopupTitle>
        </div>
    }

    return (
        <div>
            <DataGrid
                dataSource={taxReturnData}
                keyExpr="taxReturnId"
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                showBorders={true}
                columnAutoWidth={true}
            >
                <HeaderFilter visible={true} />
                <Column dataField="certNumber" />
                <Column dataField="quarter" caption="Reporting Period" />
                <Column dataField="totalReceiptsAmt" dataType="number" format="currency" visible={false} />
                <Column dataField="taxPayableAmt" dataType="number" format="currency" />
                <Column dataField="status" caption="Payment Status" />
                <Column dataField="paymentDate" dataType="date" />
                <Column dataField="preparedBy" />
                <Column dataField="taxReturnStatus"></Column>
                <Column type="buttons">
                    <Button text="Details" onClick={handleDetailsClick}></Button>
                </Column>
            </DataGrid>
            {detailsPopupVisible && selectedTaxReturnId &&
                <Popup
                    visible={detailsPopupVisible}
                    onHiding={hideDetails}
                    maxWidth="1000px"
                    titleRender={popupTitleRender}
                >
                    <TaxReturnDetails
                        taxReturnId={selectedTaxReturnId}
                        fromPage="PendingReview"
                    />
                </Popup>}

        </div>
    );
}

export default TaxReturnPendingReview;