import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PublicRoute({ children, isLoggedIn, ...props }) {
   // debugger;
    return (        
        <>
            {!isLoggedIn && <Route exact path="/">
                <Redirect to="/login" />
            </Route>}
            {isLoggedIn && <Route exact path="/login">
                <Redirect to="/CertAppPage" />
            </Route>}
            <Route {...props}
                render={
                    ({ location }) => (
                        (children)
                    )
                }
            /></>
    );
}

export default PublicRoute;