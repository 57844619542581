import { DataGrid, TabPanel } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/tab-panel';
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatDate, formatPhoneNumber } from '../../_helper/helpers';
import { DetailsInfo, DetailsSection } from './TaxReturnComponents';
import { CheckBox } from 'devextreme-react/check-box';
import Popup from 'devextreme-react/popup';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import { sendRequest } from '../../_helper/util';
import AuthService from '../../services/AuthService';
import PropTypes from 'prop-types';

//TaxReturnPaymentDetails.propTypes = {
//    handleReturnCheck: PropTypes.func.isRequired
//};

const apiUrl = process.env.REACT_APP_API_URL;

function TaxReturnPaymentDetails(props) {
    //const { data } = props.data;
    //const { data, component } = props.data;
    const { data } = props;
    const [ displayInitReturnedCheck, setDisplayInitReturnedCheck ] = useState(false);
    const [ receiptDate, setReceiptDate ] = useState(new Date());
    const userId = AuthService.getTOTUser().user.employeeId;
    //debugger;

    const onChange_ReturnedCheck = (e) => {
        setDisplayInitReturnedCheck(true);
        return false;
    }

    const paymentInfo = () => {
        return <> <DetailsSection subtitle="Payer" className="mx-4 my-2">
            <Row>
                <Col md={6}>
                    <DetailsInfo label="Name" value={`${data.payerFirstName} ${data.payerLastName}`}></DetailsInfo>
                </Col>
                <Col md={6}>
                    <DetailsInfo label="Address" value={`${data.payerAddress} ${data.payerCity}, ${data.payerState} ${data.payerZipCode}`}></DetailsInfo>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <DetailsInfo label="Phone Number" value={formatPhoneNumber(data.payerPhoneNumber)}></DetailsInfo>
                </Col>
                <Col md={6}>
                    <DetailsInfo label="Email Address" value={data.payerEmailAddress}></DetailsInfo>
                </Col>
            </Row>
        </DetailsSection>
            <DetailsSection className="mx-4 my-2" subtitle="Payment">
                <Row>
                    <Col md={4}>
                        <DetailsInfo label="Date" value={formatDate(data.paidDate)}></DetailsInfo>
                    </Col>
                    <Col md={4}>
                        <DetailsInfo label="Amount" value={`$${data.paymentAmount}`}></DetailsInfo>
                    </Col>
                    <Col md={4}>
                        <DetailsInfo label="Type" value={data.paymentType}></DetailsInfo>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <DetailsInfo label="Tender" value={data.paymentTender}></DetailsInfo>
                    </Col>
                    <Col md={4}>
                        <DetailsInfo label="Check Number" value={data.checkNumber}></DetailsInfo>
                    </Col>
                    <Col md={4}>
                        <DetailsInfo label="Confirmation Number" value={data.sourceConfirmationNumber}></DetailsInfo>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <DetailsInfo label="Accounting Year Quarter" value={data.accountingYearQuarter}></DetailsInfo>
                    </Col>
                    <Col md={4}>
                        <DetailsInfo label="Receipt Date" value={formatDate(data.receiptDate)}></DetailsInfo>
                    </Col>
                    <Col md={4}>
                        {data.paymentTender == "Check" && <div style={{ flexDirection: "column", marginBottom: '8px' }} >
                            <span style={{color: '#DE3163', fontStyle: 'italic', fontSize: '12px'}} className="fw-bold">Returned Check</span>
                            <span style={{ color: "#757575" }}><div className=""><CheckBox type="checkbox" onValueChanged={onChange_ReturnedCheck} disabled={data.isReturnedCheck ? true : false} value={data.isReturnedCheck ? true : false} /></div></span>
                        </div>}
                    </Col>
                </Row>
                <Row>                   
                    <Col md={12} className='mt-2'>
                        {data.originatedFromAnotherTaxReturn && <span className='p-1 mb-2 bg-success text-white'>{`"** Payment made on ${data.reportingYearQuarter} Tax Return"`}</span>}
                        {data.creditFromAnotherTaxReturn && <span className='p-1 mb-2 bg-success text-white'>{`"** Credit applied from ${data.reportingYearQuarterOfCredit} Tax Return"`}</span>}
                    </Col>
                </Row>
            </DetailsSection>
            </>
    }

    const paymentItems = () => {
        return <>
            <DataGrid dataSource={data.paymentItems}>
                <Column dataField="quarterYear" caption="Tax Return" ></Column>
                <Column dataField="feeType" caption="Fee Type"></Column>
                <Column dataField="amount" dataType="number" format={{type: "currency", precision: 2}}></Column>
            </DataGrid>
            
        </>
    }

    const onChangeEffectiveDate = (e) => {
        //debugger;
        var newDate = new Date(e.value);
        setReceiptDate(newDate);
    }

    const handlePopupHidden = (e) => {
        setDisplayInitReturnedCheck(false);
    }

    const handleSubmitCheckReturned = (e) => {
        //debugger;
        //handleReturnCheck(e, receiptDate);
        const endpointUrl = apiUrl + `/api/TaxReturn/HandleReturnedCheck/${data.paymentId}/${receiptDate.toDateString()}/${userId}`;
        //return sendRequest(endpointUrl, "POST", '')
        return sendRequest(endpointUrl, "POST", '').then(myJson => {
            
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                setDisplayInitReturnedCheck(false);
                //debugger;
                //component.refresh();
                props.parentGrid.refresh();
                props.refreshDetails();
            }
        })
        .catch(err => console.error(err));
    }

    const renderPopup = () => {
        return <>
            <div>
                Are you sure you wish to mark this Check as returned?<br /><br /><b>There is no undoing this change and will result with a new payment record being creating and undoing the original payment.</b><br /><br />
                <div className="row align-items-center justify-content-begin" style={{textAlign:"left", margin:"0px 0px", padding:"0px"}}>
                    <div className="col-md-auto">Select Receipt Date:</div>                     
                    <div className="col-4">
                        <DateBox
                            defaultValue={new Date().getTime()}
                            //value={effectiveDate}
                            placeholder="00/00/0000"
                            useMaskBehavior={true}
                            type="date"
                            height={props.height}
                            displayFormat="shortdate"
                            applyValueMode="useButtons"
                            openOnFieldClick={true}
                            hint="Effective Date is the Paid Date of a payment and cannot be prior to the date of the latest payment."
                            stylingMode="filled"
                            onValueChanged={onChangeEffectiveDate}                    
                        >
                            <DateBoxButton name="dropDown" />
                        </DateBox>
                    </div>
                </div>
                <br />
                <div className='m-2 p-2 d-flex justify-content-center gap-2'>                    
                    <Button type="success" className="btn btn-sm" variant="primary" disabled={false} onClick={handleSubmitCheckReturned} >Confirm</Button>
                    <Button className='btn btn-sm' disabled={false} type="success" stylingMode="outlined" onClick={handlePopupHidden}>Cancel</Button>
                </div>
            </div>        
        </>
    }

    return (
        <>
            {data &&
                 <TabPanel>
                 <Item title="Payment Info" render={paymentInfo} />
                 <Item title="Items Paid" render={paymentItems} />
             </TabPanel>
            }
            <Popup
                width={500}
                height={300}
                showTitle={true}
                title={"Confirm Returned Check"}
                dragEnabled={false}
                visible={displayInitReturnedCheck}
                onHiding={handlePopupHidden}
                contentRender={renderPopup}
            />
        </>
    );
}

export default TaxReturnPaymentDetails;