import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import FeeTypeList from '../../components/feetype/feetype_overview';

function ManageFeeType(props) {
    return (
        <div className="content-block">
        
            <PageHeader header="Manage Fee Type" />
            <FeeTypeList></FeeTypeList>
        </div>
    );
}

export default ManageFeeType;