import React from 'react';
import { DataGrid, Column, Button, HeaderFilter, Editing, Lookup } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { sendRequest } from '../../_helper/util.js';
import TaxReturnDetails from '../../components/TaxReturnDetails/TaxReturnDetails.js';
import { Popup } from 'devextreme-react';
import PopupTitle from '../../components/TaxReturnDetails/PopupTitle.js';
import AuthService from '../../services/AuthService';

const url = process.env.REACT_APP_API_URL + "/api";

class DetailTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            taxReturnData: new CustomStore({
                key: 'taxReturnId',
                load: () => this.getTaxReturns(props.data.key),
                update: this.updateTaxReturn
            }),
            detailsPopupVisible: false,
            selectedTaxReturnId: null,
            selectedTaxReturnStatus: null
        }
    }

    componentDidMount() {
        sendRequest(url + "/Lookup/TaxReturnPayment", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    paymentStatusData: myJson.listLookup.paymentStatus,
                    yearQuarterData: myJson.listLookup.listReportingYearQuarter
                });
            }
        });

        
        sendRequest(url + "/Users/GetAdminRoleId", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    adminRoleId: myJson
                });
            }
        });
    }

    getTaxReturns(propertyId) {
        return sendRequest(url + "/TaxReturn/GetTaxReturnByPropertyId?propertyId=" + propertyId, 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    updateTaxReturn = (key, values) => {
        const { taxReturnData, paymentStatusData, yearQuarterData } = this.state;
        var yearQuarterId = "";
        var paymentStatusId = "";
        if (values.reportingYearQuarterId) 
            yearQuarterId = values.reportingYearQuarterId; // values.quarter if add valueExpr
        if (values.status) 
            paymentStatusId = paymentStatusData.find(x => x.description === values.status).paymentStatusId;

        var employeeID = AuthService.getTOTUser().user.employeeId;

        return sendRequest(url + "/TaxReturn/UpdateTaxReturnInfo?taxReturnId=" + key + "&yearQuarterId=" + yearQuarterId + "&paymentStatusId=" + paymentStatusId + "&userid=" + employeeID, 'POST', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });     
    }

    handleDetailsClick = (e) => {
        this.setState({ selectedTaxReturnId: e.row.data.taxReturnId, selectedTaxReturnStatus: e.row.data.taxReturnStatus }, this.setState({ detailsPopupVisible: true }))
    }
    hideDetails = () => {
        this.setState({ detailsPopupVisible: false, selectedTaxReturnId: null , selectedTaxReturnStatus: null})
    }

    popupTitleRender = () => {
        const { selectedTaxReturnStatus } = this.state;
        return <div>
            <PopupTitle statusCode={selectedTaxReturnStatus.code} status={selectedTaxReturnStatus.description} onClick={this.hideDetails}>
            </PopupTitle>
        </div>
    }

    onInitialized = (e) => {
        //get user role
        if (AuthService.isAuthorized()) {
            var employeeID = AuthService.getTOTUser().user.employeeId;
            return sendRequest(url + "/Users/GetUserRoleId?employeeID=" + employeeID, 'GET', '').then(myJson => {
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                }
                else {
                    this.setState({
                        userRoleId: myJson
                    });
                    return;
                }
            });
        } 
    }

    detailsButtonIsVisible = e => {
        if (e.row.isEditing) { // || e.row.isNewRow
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { taxReturnData, detailsPopupVisible, selectedTaxReturnId, userRoleId, paymentStatusData, yearQuarterData, adminRoleId } = this.state;

        return (
            <React.Fragment>
                <div className="master-detail-caption">
                    {`Tax Returns`}
                </div>
                <DataGrid
                    dataSource={taxReturnData}
                    keyExpr="taxReturnId"
                    showBorders={true}
                    columnAutoWidth={true}
                    onInitialized={this.onInitialized}
                >
                    <HeaderFilter visible={true} />
                    <Editing
                        mode="row"
                        allowUpdating={userRoleId === adminRoleId} 
                    />
                    <Column dataField="reportingYearQuarterId" caption="Reporting Period" allowEditing={true} defaultSortOrder="desc">
                        <Lookup dataSource={yearQuarterData} displayExpr="displayDesc" valueExpr="yearQuarterId"/>
                    </Column>
                    <Column dataField="totalReceiptsAmt"  dataType="number" format="currency" visible={false} allowEditing={false}/>
                    <Column dataField="taxPayableAmt" dataType="number" format="currency" visible={true} allowEditing={false}/>
                    <Column dataField="status" caption="Payment Status">
                        <Lookup dataSource={paymentStatusData} displayExpr="description" valueExpr="description"/> 
                    </Column>
                    <Column dataField="paymentDate" dataType="date"  allowEditing={false}/>
                    <Column dataField="preparedBy"  allowEditing={false}/>
                    <Column dataField="taxReturnStatus.description" caption="Tax Return Status" allowEditing={false}/>
                    <Column type="buttons">
                        <Button name="edit" hint="Edit" />
                        <Button text="Details" onClick={this.handleDetailsClick} visible={this.detailsButtonIsVisible}></Button>
                    </Column>
                </DataGrid>
                {detailsPopupVisible && selectedTaxReturnId &&
                 <Popup
                 visible={detailsPopupVisible}
                 onHiding={this.hideDetails}
                 maxWidth="1000px"
                 titleRender={this.popupTitleRender}
                 >
                    <TaxReturnDetails
                        taxReturnId={selectedTaxReturnId}
                        fromPage="Properties"
                    />
                    
                    </Popup>}
            </React.Fragment>
        );
    }
}

export default DetailTemplate;
