import React from 'react';
/*import { Form, Button, Col, Container } from 'react-bootstrap';*/
import Form from "react-bootstrap/Form";
//import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
//import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import RadioGroup from 'devextreme-react/radio-group';

import { sendPublicRequest } from '../../_helper/util.js';
//import CustomStore from 'devextreme/data/custom_store';
import RegisterCertWrapper from './registercert_wrapper.js';

const url = process.env.REACT_APP_API_URL + "/api";

//const typeOfRental = ['Business', 'Private Rental Home', 'Other'];

class MiscDetails extends React.Component {

    constructor() {
        super();

        this.state = {
            isDisabled: true,
            //typeOfRentalData: new CustomStore({
            //    key: 'TypeOfRental_ID',
            //    load: this.loadTypeOfRental
            //}),
        }        
    }

    componentDidMount() {
        sendPublicRequest(url + "/Lookup/GetTypeOfRentals", 'GET', '').then(myJson => {
            //alert(myJson.message);
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    typeOfRentalData: myJson.listTypeOfRental,
                });
                //alert(this.state.typeOfRentalData);
            }
        })
        .catch(err=>{
            console.error(err);
        });
        //debugger;
        this.setState({ isDisabled: this.props.inputValues.typeOfRentalOther_Disabled });
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    saveAndContinue = (e) => {
        //debugger;
        e.preventDefault();

        let errors = [];

        if (this.props.inputValues.ownedBusLength === "") {
            errors.push("ownedBusLength");
        }

        if (this.props.inputValues.typeOfRental === "") {
            errors.push("typeOfRental");
        }

        if (this.props.inputValues.typeOfRental === "Other" && this.props.inputValues.typeOfRentalOther === "") {
            errors.push("typeOfRentalOther");
        }

        this.props.handleHasError(errors);

        if (errors.length > 0) {
            return false;
        } else {
            this.props.nextStep();  /*All good*/
        }
    };

    hasError(key) {
        return this.props.hasError(key); 
    }

    changeRentalSelection = (e) => {
        //alert(e.event.currentTarget.textContent);
        //debugger;
        //if (e.value == 'OTHER') {
        //if (e.event.currentTarget.textContent.toLowerCase() == 'other') {
        //    //alert('Other');
        //    //this.setState({ this.props.inputValues.typeOfRentalOther_Disabled: false })
        //    { this.props.inputValues.typeOfRentalOther_Disabled = false }
        //    this.setState({ isDisabled: false })
        //} else {
        //    //alert('Non Other');
        //    this.setState({ isDisabled: true })
        //    { this.props.inputValues.typeOfRentalOther_Disabled = true }
        //}
        this.props.handleTypeOfRentalChange(e);
    }

    findOther = (data) => {
        //debugger;
        if (data) {
            return data.find(x => x.typeOfRentalCode === "OT");
        } else {
            return true;
        }
    }
    
    render() {
        //const { isDisabled } = this.state;
        return (
            <RegisterCertWrapper subtitle="SHORT-TERM RENTAL INFORMATION" step="Step 3 of 4" onBackClick={this.back} onNextClick={this.saveAndContinue}>
                <Form>
                    <Row>                        
                        <Form.Group as={Col} md="6" lg="6" controlId="formOwnedBusLength">
                            <Form.Label className="label"><b>How long have you owned or operated this business?</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("ownedBusLength")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="text"
                                defaultValue={this.props.inputValues.ownedBusLength}
                                name="ownedBusLength"
                                required
                                onChange={this.props.handleChange}
                                maxLength="255"
                                
                            />
                            <div className={"inline-errormsg"}>{this.hasError("ownedBusLength") ? "Please enter a value" : null}</div>
                        </Form.Group>
                    </Row>                   
                    <br />
                    <Row>
                        <Form.Group as={Col} controlId="formTypeOfRental">
                            <Form.Label className="label"><b>Type of Rental</b></Form.Label>
                            {/*<div className="form">*/}
                                {/*<div className="dx-fieldset" style={{alignContent: "left"}}>*/}
                            {/*<div className="dx-field" style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>*/}
                            <div className="dx-field">
                                <div className="dx-field-label" style={{ height: "100%", width: "100%", alignItems: "center" }}>
                                    <div style={{ height: "100%", float: "left"}}>
                                        {/*<RadioGroup items={typeOfRental} layout="horizontal" onValueChanged={this.changeRentalSelection} defaultValue={this.props.inputValues.typeOfRental} />*/}
                                        <RadioGroup 
                                            className={
                                                this.hasError("typeOfRental")
                                                    ? "form-control is-invalid"
                                                    : ""
                                            }
                                            items={this.state.typeOfRentalData} 
                                            layout="horizontal" 
                                            onValueChanged={this.changeRentalSelection}
                                            valueExpr="typeOfRentalId" 
                                            displayExpr="description" 
                                            defaultValue={this.props.inputValues.typeOfRentalId} 
                                        />
                                        <div className={"inline-errormsg"}>{this.hasError("typeOfRental") ? "Please enter a value" : null}</div>
                                    </div>
                                    { this.findOther(this.state.typeOfRentalData) && <div style={{ float: "left", paddingLeft: "20px" }}>
                                        <Form.Control
                                            className={
                                                this.hasError("typeOfRentalOther")
                                                    ? "form-control is-invalid"
                                                    : "form-control"
                                            }
                                            type="text"
                                            defaultValue={this.props.inputValues.typeOfRentalOther}
                                            name="typeOfRentalOther"
                                            required
                                            onChange={this.props.handleChange}
                                            disabled={this.props.inputValues.typeOfRentalOther_Disabled}
                                            maxLength="255"
                                        />
                                        <div className={"inline-errormsg"}>{this.hasError("typeOfRentalOther") ? "Please enter a value" : null}</div>
                                    </div>
                                    }
                                </div>
                            </div>
                                {/*</div>*/}
                            {/*</div>*/}
                        </Form.Group>
                        {/*<Form.Group as={Col} lg="2" controlId="formTypeOfRentalOther">*/}
                        {/*    <Form.Control*/}
                        {/*        type="text"*/}
                        {/*        defaultValue={this.props.inputValues.typeOfRentalOther}*/}
                        {/*        name="typeOfRentalOther"*/}
                        {/*        required*/}
                        {/*        onChange={this.props.handleChange}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                    </Row>   
                </Form>
                {/*<div className="form">*/}
                {/*    <div className="dx-fieldset">*/}
                {/*        <div className="dx-field">*/}
                {/*            <div className="dx-field-value">*/}
                {/*                <RadioGroup items={typeOfRental} layout="horizontal" />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </RegisterCertWrapper>
            
        );
    }
}

export default MiscDetails;