import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { formatCurrency } from '../../_helper/helpers';

const TaxReturnInfo = (props) => {
    const { data, taxRate, penaltyAmt, interestAmt, taxReturnOnlinePlatform, displayUploadedFiles} = props;

    // const formatCurrency = (e) => {
    //     return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e);
    // }
    
    return (
        <div>
            <Row>
                <Col md={1} lg={1} >
                    <b>1.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Total Receipts From Room Rentals:&nbsp;</b>
                    </span>

                    <span >
                        Receipts for the occupancy of all rooms including receipts through online platforms.
                        <u>(Receipts include room rate and cleaning fees as the taxable amounts)</u><b>(GROSS EARNINGS)</b>
                    </span>
                    <br /><br />
                    <Row>
                        <Col md={2} lg={8}><p><b>Number of nights:&emsp;&emsp;</b>{data.numberOfNights}</p></Col>
                        {/* <Col md={2} lg={3}>
                            <div >{data.numberOfNights}</div>
                        </Col> */}
                    </Row>
                </Col>
                <Col md={2} lg={2} >
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.totalReceiptsAmt)}</div>
                </Col>
            </Row>
            <br />
            <hr className="separateLine" />
            <br />
            {/*Exemptions*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>2.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Exemptions/Exclusions:&nbsp;</b>
                    </span>
                    <span>
                        Appropriate documentation must be included
                    </span>
                    <br /><br />
                    a. Amount collected for rooms occupied longer than 30 Days continuously<br />
                    b. Amount collected for rooms occupied by government officials or employees on official business; exempt certificate is required, must be attached to the Quarterly Tax Return and is available online at www.mytaxcollector.com
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>a. {formatCurrency(data.exemptLonger30DaysAmt)}</div>
                    <div style={{ textAlign: "right" }}>b. {formatCurrency(data.exemptGovOffBusAmt)}</div>
                    <br />
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.exemptionsAmt)}</div>
                </Col>
            </Row>
            {displayUploadedFiles && <Row >
                {data.selectedFilesExemptions.length > 0 &&
                    <div className="uploadContainerTaxReturn">
                        {
                            data.selectedFilesExemptions.map((a, i) =>
                                <div className="divFileName" key={i}>{a.name}</div>
                            )
                        }
                    </div>
                }
            </Row>}
            <br />
            <hr className="separateLine" />
            <br />
            {/*Taxable Rents*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>3.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Taxable Rents:&nbsp;</b>
                    </span>
                    <span>Line 1 minus Line 2.</span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.taxableRentsAmt)}</div>
                </Col>
            </Row>
            <br />
            <hr className="separateLine" />
            <br />
            {/*Tax Due*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>4.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Tax Due:&nbsp;</b>
                    </span>
                    <span>Line 3 x {(taxRate * 100).toFixed(2).replace(/[.,]00$/, "")}%</span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.taxDueAmt)}</div>
                </Col>
            </Row>
            <br />
            <hr className="separateLine" />
            <br />
            {/*Online Platform*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>5.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Tax collected and remitted through Online Platform:&nbsp;</b>
                    </span>
                    <span>
                        <u>(must include host page with gross receipts as stated above and must state the amount of any taxes remitted on your behalf)</u>
                        <br />*It is your responsibility to remit payment for rentals occurring on all rental platforms such as, but not limited to: HomeAway, VRBO, Expedia, and if no collection or remittance was done by Airbnb.
                    </span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.taxCollectedOnlinePlatformAmt)}</div>
                </Col>
            </Row>
            <br />

            {taxReturnOnlinePlatform.length > 0 &&
                <Row>
                    <Col md={1} lg={1} >
                    </Col>
                    <Col md={11} lg={7}>
                        <Table bordered >
                            <thead>
                                <tr>
                                    <th>Online Platform</th>
                                    <th style={{ textAlign: "right" }}>Remitted to County Amount</th>
                                    <th style={{ textAlign: "right" }}>Remitted to Host Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {taxReturnOnlinePlatform.map((a, i) =>

                                    <tr key={a.onlinePlatformId}>
                                        <td>
                                            {a.description}{a.onlinePlatformOtherValue === undefined ? "" : ":"}&nbsp;
                                            {a.onlinePlatformOtherValue === undefined ? "" :
                                                a.onlinePlatformOtherValue
                                            }
                                        </td>
                                        <td>
                                            {a.remittedToCounty &&
                                                <div style={{ textAlign: "right" }}>
                                                    {formatCurrency(a.onlinePlatformRemittedToCountyAmount)}
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            {a.remittedToHost &&
                                                <div style={{ textAlign: "right" }}>

                                                    {formatCurrency(a.onlinePlatformRemittedToHostAmount)}
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>}

            {displayUploadedFiles && <Row>
                {data.selectedFilesOnlinePlatform.length > 0 &&
                    <div className="uploadContainerTaxReturn">
                        {
                            data.selectedFilesOnlinePlatform.map((a, i) =>
                                <div className="divFileName" key={i}>{a.name}</div>
                            )
                        }
                    </div>
                }
            </Row>}
            <br />
            <hr className="separateLine" />
            <br />
            {/*Total payable by Operator*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>6.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Total payable by Operator:&nbsp;</b>
                    </span>
                    <span>Line 4 minus Line 5</span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.taxPayableAmt)}</div>
                </Col>
            </Row>
            <br />
            <hr className="separateLine" />
            <br />
            {/*Penalty for Late Payment*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>7.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Penalty for Late Payment:&nbsp;</b>
                    </span>
                    <br />
                    <span >
                        A 10% penalty is assessed on payments received/postmarked after Delinquent Date; A second 10% penalty is added one month after the Delinquent Date based on amount in line 6.
                    </span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(penaltyAmt)}</div>
                </Col>
            </Row>
            <br />
            <hr className="separateLine" />
            <br />
            {/*Interest*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>8.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Interest:&nbsp;</b>
                    </span>
                    <br />
                    <span >
                        Interest of 0.5% per month (or portion of) is assessed on the amount of tax due from the date it became delinquent until paid based on amount in line 6
                    </span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(interestAmt)}</div>
                </Col>
            </Row>
            <br />
            <hr className="separateLine" />
            <br />
            {/*Credit or Charge from Previous Return*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>9.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Credit or Charge from Previous Return:&nbsp;</b>
                    </span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.previousReturnCreditChargeAmt)}</div>
                </Col>
            </Row>
            <br />
            <hr className="separateLine" />
            <br />
            {/*Total Payment*/}
            <Row>
                <Col md={1} lg={1} >
                    <b>10.</b>
                </Col>
                <Col md={9} lg={9}>
                    <span>
                        <b>Total Payment:&nbsp;</b>
                    </span>
                    <span>Add lines 6 through 9.</span>
                </Col>
                <Col md={2} lg={2}>
                    <div style={{ textAlign: "right" }}>{formatCurrency(data.totalPaymentAmt)}</div>
                </Col>
            </Row>
        </div>
    )
}

export default TaxReturnInfo;

TaxReturnInfo.propTypes = {};