import React from 'react';
import { Button } from 'react-bootstrap';
import { getAdalToken } from '../../_helper/adalToken';


const url = process.env.REACT_APP_API_URL + "/api";

class ExportPdfComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            templateContent: this.props.templateContent,
            objectdata: ""
        }
        this.handleRefresh = this.handleRefresh.bind(this);
        this.getPreview = this.getPreview.bind(this);
    }

    componentDidMount() {
        this.getPreview();
    }

    handleRefresh() {
        this.getPreview();
    }

    getPreview() {
        const { templateId, templateContent } = this.props;
        const myObj = {
            templateId: templateId,
            templateContent: templateContent
        }
        const body = JSON.stringify(myObj);
        return fetch(url + "/Template/Preview", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAdalToken()
            },
            body: body
        })
            .then(response => response.arrayBuffer())
            .then(arrayBufferData => {
                const blob = new Blob([arrayBufferData], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                //window.open(url, '_blank', 'location=yes,height=650,width=1000,scrollbars=yes,status=yes');

                this.setState({
                    objectdata: url
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const { height, width, allowRefresh = false } = this.props;
        const { objectdata } = this.state;
        return (
            <>
                <object id="pdfviewer" data={objectdata} type="application/pdf" height={height || " 500px"} width={width || "500px"} aria-label="pdf"></object>
                {allowRefresh &&
                    <div>
                        <Button variant="secondary" onClick={this.handleRefresh}> <i className="dx-icon-refresh"></i> Preview </Button>
                    </div>}
            </>
        );
    }
}

export default ExportPdfComponent;