import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';


const TemplateButtons = (props) => {
    const { handleCancel, handleSave, saveButtonLabel, message = ""} = props;
    return (
        <div className='row' style={{ position: "absolute", bottom: "-125px", right: "15px" }} >
            <div className={"col-xs-12 mt-2"}>
                <Button variant="btn btn-outline-secondary" onClick={handleCancel} className={"mx-2"}> Cancel </Button>
                <Button variant="primary" onClick={handleSave} className={"mx-2"}> {saveButtonLabel} </Button>
                {message}
            </div>
        </div>
    )
}

export default TemplateButtons;

TemplateButtons.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    saveButtonLabel: PropTypes.string.isRequired
};
