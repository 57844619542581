import React, { Component } from 'react';
import CheckBox from 'devextreme-react/check-box';
import CustomStore from 'devextreme/data/custom_store';
import { confirm } from 'devextreme/ui/dialog';
import { sendRequest } from '../../_helper/util.js';
import SecureLS from "secure-ls";
import './users_overview.scss';
import AuthService from '../../services/AuthService';
import DataGrid,
{
    Column,
    Editing,
    Button,
    Paging,
    FilterRow,
    Lookup,
    Item,
    Popup,
    Form,
    RequiredRule,
    EmailRule,
    PatternRule
} from 'devextreme-react/data-grid';

const url = process.env.REACT_APP_API_URL + "/api";

class users extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userData: new CustomStore({
                key: 'employeeId',
                load: this.loadUsers,
                update: this.updateUser,
                insert: this.insertUser
            }),
            includeInactiveRecords: false,
            rolesData: []
        };

        this.idPattern = /[A-Z]\d{4}$/;
    }    

    componentDidMount() {
        sendRequest(url + "/Role/GetAllRoles", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({                    
                    rolesData: myJson.listRoles,
                });
            }
        })
        .catch(err=>{
            console.error(err);
        });

        sendRequest(url + "/Users/GetAdminRoleId", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    adminRoleId: myJson
                });
            }
        });
    }

    loadUsers = () => {
        return sendRequest(url + "/Users/GetAllUsers?includeInactive=" + this.state.includeInactiveRecords, 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson.listUser;
            }
        });
    }

    updateUser = (key, values) => {
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        values.employeeId = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);

        return sendRequest(url + "/Users/UpdateUser", 'POST', body).then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });        
    }

    insertUser = (values) => {
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        let employeeId = userinfo.user.employeeId;
        values.CreatedBy = employeeId;
        
        const body = JSON.stringify(values);
        
        return sendRequest(url + "/Users/InsertUser", 'POST', body).then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    onInitNewRow = e => {        
        e.data.isActive = true;
    }

    onEditorPreparing = e => {
        if (e.dataField === "employeeId" && e.parentType === "dataRow" && !e.row.isNewRow)
        {
            e.editorOptions.disabled = true;
        }
    }

    disableButtonIsVisible = e => {
        if (!e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            if (this.state.userRoleId === this.state.adminRoleId)
            {
                return true;
            }
            else
            {
                return false;
            }            
        }
    }

    enableButtonIsVisible = e => {
        if (e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            if (this.state.userRoleId === this.state.adminRoleId)
            {
                return true;
            }
            else
            {
                return false;
            }
        }
    }

    includeInactiveRecordsValueChanged = e => {
        this.setState({ includeInactiveRecords: e.value });
        this.loadUsers();
        this.dataGrid.instance.refresh();
    }

    disableUser = (e) => {
        let params;
        let employeeId = e.row.data.employeeId;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to disable this Employee: " + employeeId + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { employeeId: e.row.data.employeeId };
                    const body = JSON.stringify(params);
                    return sendRequest(url + "/Users/DisableUser", 'POST', body).then(myJson => {
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    enableUser = (e) => {
        let params;
        let employeeId = e.row.data.employeeId;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to enable this Employee: " + employeeId + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { employeeId: e.row.data.employeeId };
                    const body = JSON.stringify(params);
                    return sendRequest(url + "/Users/EnableUser", 'POST', body).then(myJson => {
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    onInitialized = (e) => {
        //get user role
        if (AuthService.isAuthorized()) {
            var employeeID = AuthService.getTOTUser().user.employeeId;
            return sendRequest(url + "/Users/GetUserRoleId?employeeID=" + employeeID, 'GET', '').then(myJson => {
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                }
                else {
                    this.setState({
                        userRoleId: myJson
                    });
                    return;
                }
            });
        } 
    }
    
    render() {
        const { userData, userRoleId, adminRoleId } = this.state;

        return (            
            <div className={'content-block'}>   
                <div className="option">
                    <CheckBox text="Include InActive Records" style={{marginLeft: "0"}}
                        value={this.state.includeInactiveRecords}
                        onValueChanged={this.includeInactiveRecordsValueChanged} />
                </div>
                <br />             
                <DataGrid
                    id="gridContainer"
                    dataSource={userData}
                    allowColumnReordering={false}
                    allowColumnResizing={true}
                    columnResizingMode="widget"
                    showBorders={true}
                    onInitNewRow={this.onInitNewRow}
                    onEditorPreparing={this.onEditorPreparing}
                    ref={ref=>this.dataGrid=ref}
                    onInitialized={this.onInitialized}
                >                   
                    <FilterRow visible={false} />
                    <Paging enabled={true} pageSize={25} />

                    <Editing
                        mode="popup"
                        // useIcons={true}
                        allowUpdating={userRoleId === adminRoleId}
                        allowAdding={true}
                    >
                        <Popup title="User Information" showTitle={true} //width={1200} height={800}
                        />
                        <Form >                            
                            <Item itemType="group" caption="" colCount={2} colSpan={2}>
                                <Item dataField="employeeId"
                                    // editorOptions={{
                                    //     mask: 'A0000',
                                    //     maskRules: {
                                    //         'A': /[A-Z]/,
                                    //         '0': /[0-9]/
                                    //     }  
                                    // }}
                                /> 
                                <Item dataField="workPhone"
                                    editorOptions={{
                                        mask: '(000) 000-0000',
                                        // useMaskedValue: true
                                    }}
                                />
                                <Item dataField="firstName" />
                                <Item dataField="lastName" />
                                <Item dataField="emailAddress" /> 
                                <Item itemType="empty" />  
                                <Item dataField="division" />
                                <Item dataField="title" />
                                <Item dataField="roleId" />
                                <Item itemType="empty" /> 
                                <Item dataField="isActive" /> 
                            </Item>
                        </Form>
                    </Editing>

                    <Column dataField="employeeId" dataType="string" caption="Employee ID" allowEditing={true} allowSorting={true}> 
                        <RequiredRule /> 
                        <PatternRule message="ID is invalid" pattern={this.idPattern}/>
                    </Column>
                    <Column dataField="emailAddress" dataType="string" caption="E-Mail" allowEditing={true} allowSorting={true}> 
                        <RequiredRule /> 
                        <EmailRule />
                    </Column>
                    <Column dataField="firstName" dataType="string" caption="First Name" allowEditing={true} allowSorting={true}> <RequiredRule /> </Column>
                    <Column dataField="lastName" dataType="string" caption="Last Name" allowEditing={true} allowSorting={true}> <RequiredRule /> </Column>
                    <Column dataField="title" dataType="string" caption="Title" allowSorting={true} />
                    <Column dataField="division" dataType="string" caption="Division" allowSorting={true} />
                    <Column dataField="workPhone" dataType="string" caption="Work Phone" allowSorting={true} />
                    <Column dataField="isActive" dataType="boolean" allowSorting={true} />
                    <Column dataType="number" dataField="roleId" caption="Role" width={125}>
                        <Lookup dataSource={this.state.rolesData} displayExpr="name" valueExpr="roleId" />
                        <RequiredRule />
                    </Column>
                    <Column type="buttons">
                        <Button name="edit" hint="Edit" onClick={this.editUser} />
                        <Button name="delete" hint="Delete"/>
                        <Button text="Disable" hint="Disable" onClick={this.disableUser} visible={this.disableButtonIsVisible} />
                        <Button text="Enable" hint="Enable" onClick={this.enableUser} visible={this.enableButtonIsVisible} />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}

export default users;