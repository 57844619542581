import React from 'react';
//import { Container } from 'react-bootstrap';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import TRAList from '../../components/tax-rate-area/taxratearea_overview';

function ManageTRA(props) {
    return (
        <div className="content-block">
        
             <PageHeader header="Manage TRA" />
             <TRAList></TRAList>
        </div>
    );
}

export default ManageTRA;