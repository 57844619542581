import React, { Component } from 'react';
import DataGrid,
{
    Column,
    Lookup,
    Editing,
    Button,
    Paging,
    FilterRow
} from 'devextreme-react/data-grid';

import { confirm } from 'devextreme/ui/dialog';

import CustomStore from 'devextreme/data/custom_store';

import { sendRequest } from '../../_helper/util.js';
import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";

const url = process.env.REACT_APP_API_URL + "/api";

class taxratearea extends Component {
    constructor(props) {
        super(props);

        this.state = {

            traData: new CustomStore({
                key: 'taxRateAreaId',
                load: this.loadAllTRA,
                update: this.updateTRA,
                insert: this.insertTRA
            }),
            supervisorDistrictsData: []
        };
    }

    componentDidMount() {
        //debugger;               
        sendRequest(url + "/Lookup/GetSupervisorDistricts", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    supervisorDistrictsData: myJson.listSupervisorDistrict,
                });
            }
        })
        .catch(err=>{
            console.error(err);
        });
    }

    loadAllTRA() {

        return sendRequest(url + "/Lookup/GetAllTaxRateAreas", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                return myJson.listTRA;
            }
        });
    }

    updateTRA = (key, values) => {
        //debugger;
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        values.TaxRateAreaId = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);

        return sendRequest(url + "/Lookup/UpdateTRA", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });        
    }

    insertTRA = (values) => {

        const body = JSON.stringify(values);
        
        return sendRequest(url + "/Lookup/InsertTRA", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    disableTRA = (e) => {
        //debugger;
        let params;
        let tra = e.row.data.tra;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to disable this TRA: " + tra + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { TaxRateAreaId: e.row.data.taxRateAreaId };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/DisableTRA", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    enableTRA = (e) => {
        //debugger;
        let params;
        let tra = e.row.data.tra;
        const isCanceled = new Promise((resolve, reject) => {
            const promptPromise = confirm("Are you sure you wish to enable this TRA: " + tra + "?", "Confirm changes");
            promptPromise.then((dialogResult) => {
                if (dialogResult) {  
                    params = { TaxRateAreaId: e.row.data.taxRateAreaId };
                    const body = JSON.stringify(params);
                    //debugger;
                    return sendRequest(url + "/Lookup/EnableTRA", 'POST', body).then(myJson => {
                        //debugger;
                        if (typeof myJson.message !== "undefined") {
                            reject(myJson.message);
                        }
                        else {
                            //debugger;
                            resolve(false);
                            this.dataGrid.instance.refresh();
                        }
                    });
                } else {
                    return resolve(true);
                }
            });
        });
        e.cancel = isCanceled;
    }

    onInitNewRow = e => {        
        //debugger;
        e.data.isActive = true;
    }

    onEditorPreparing = e => {
        if (e.dataField === "tra" && e.parentType === "dataRow" && !e.row.isNewRow)
        {
            e.editorOptions.disabled = true;
        }
    }

    disableButtonIsVisible = e => {
        //debugger;
        if (!e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    enableButtonIsVisible = e => {
        //debugger;
        if (e.row.data.isActive || e.row.isEditing || e.row.isNewRow) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const { traData } = this.state;

        return (
            <div className={'content-block'}>
                    <DataGrid
                        id="gridContainer"
                        dataSource={traData}
                        //keyExpr="taxRateAreaId"
                        allowColumnReordering={true}
                        showBorders={true}
                        onInitNewRow={this.onInitNewRow}
                        onEditorPreparing={this.onEditorPreparing}
                        ref={ref=>this.dataGrid=ref}                    
                    >

                    {/*<SearchPanel visible={true} />*/}
                    <FilterRow visible={true} />
                    <Paging enabled={true} />

                    <Editing
                        mode="row"
                        allowUpdating={true}
                        allowDeleting={true}
                        allowAdding={true} />

                    <Column dataField="tra" dataType="string" caption="TRA" width={150} defaultSortOrder="asc" />
                    <Column dataType="number"
                        dataField="supervisorDistrictId"
                        caption="Supervisor District"
                        width={200}
                    >
                        <Lookup dataSource={this.state.supervisorDistrictsData} displayExpr="districtName" valueExpr="supervisorDistrictId" />
                    </Column>

                    <Column dataField="isActive" dataType="boolean" width={130} filterValue={true}  />

                    <Column type="buttons">
                        <Button name="save" hint="Save" />
                        <Button name="edit" hint="Edit" />
                        <Button text="Disable" hint="Disable" onClick={this.disableTRA} visible={this.disableButtonIsVisible} />
                        <Button text="Enable" hint="Enable" onClick={this.enableTRA} visible={this.enableButtonIsVisible} />
                    </Column>
                </DataGrid>
            </div>
        );
    }
}

export default taxratearea;