import React, { Component } from 'react';
import './CertAppPage.scss';
import DataGrid,
{
    Column,
    Lookup,
    Editing,
    Popup,
    ToolbarItem,
    //Position,
    Form,
    RequiredRule,
    EmailRule,
    AsyncRule,
    StringLengthRule,
    //PatternRule,
    SearchPanel,
    HeaderFilter,
    Button,
    CustomRule
} from 'devextreme-react/data-grid';

import { Item } from 'devextreme-react/form';

import Attachment from '../home/Attachment.js';
import NotesHistory from '../home/NotesHistory.js';

import CustomStore from 'devextreme/data/custom_store';

import { sendRequest } from '../../_helper/util.js';
import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";
import PageHeader from '../../components/header/PageHeader/PageHeader';
import AuthService from '../../services/AuthService';
import { formatAPN } from '../../_helper/helpers';

const url = process.env.REACT_APP_API_URL + "/api";
let currentKey = -1;
var isApproved = true;
var validationNeeded = true;

class CertAppPage extends Component {
    constructor(props) {
        super(props);
        
        this.onSaved = this.onSaved.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.checkAPN = this.checkAPN.bind(this);
        // this.onSaving = this.onSaving.bind(this);
        this.customRuleAPN = this.customRuleAPN.bind(this);

        this.state = {
            certData: new CustomStore({
                key: 'propertyId',
                load: this.loadAllCert,
                update: this.updateCert
            })
        };
    }
    
    componentDidMount() {
        //debugger;
        sendRequest(url + "/Lookup/GetAllLookup", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    communityData: myJson.listLookup.listCommunity,
                    traData: myJson.listLookup.listTRA,
                    typeOfRentalData: myJson.listLookup.listTypeOfRental,
                    statusData: myJson.listLookup.listStatusApp
                });

            }
        });
    }

    loadAllCert() {

        return sendRequest(url + "/Registration/GetAllCertApp", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson.listCert
            }
        });
    }

    updateCert = (key, values) => {
        //debugger;

        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        if (values.apn) {
            values.apn = formatAPN(values.apn);
        }

        values.PropertyId = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);


        return sendRequest(url + "/Registration/UpdateCert", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {

                //if (myJson.redirectToEditor) {
                //    //redirect to pdf editor page
                //    this.props.history.push("/CertTemplate/" + myJson.rowToUpdate.propertyId);
                //}
               
                //this.setState({
                //    rowToUpdate: myJson.rowToUpdate 
                //});

                return myJson;

            }
        });

    }

    onEditingStart(e) {
        // debugger;
        validationNeeded = true;
        currentKey = e.key;
    }
    onEditorPreparing(e) {
        // debugger;
        if (e.parentType === 'dataRow') {

            if (e.dataField === "typeOfRentalOther") {
                if (e.row.data.typeOfRentalId === 6) {
                    e.editorOptions.disabled = false;
                }
                else {
                    e.editorOptions.disabled = true;
                }
            }
            if (e.dataField === "apn") {
                if (e.row.data.multipleAPN === true) { // change requiredRule for APN based on value of multipleAPN
                    e.editorOptions.disabled = true;
                    if (e.validationRules.length >= 2) { // hardcoded number of validations
                        e.validationRules.shift(); // delete validations
                        e.validationRules.shift(); 
                    }
                } else {
                    e.editorOptions.disabled = false;
                    if (e.validationRules.length < 2) { // add back validations
                        e.validationRules.unshift({type:'custom',
                                                   message: "Please enter a valid APN.",
                                                   validationCallback: this.customRuleAPN, 
                                                   reevaluate:'true'});
                        e.validationRules.unshift({type:'async',                           
                                                   message: "Assessor Parcel Number already exists in another certificate.",
                                                   validationCallback: this.checkAPN,
                                                   reevaluate:'true'}); 
                    }
                }
            }
            if (e.dataField === "taxRateAreaId") {
                if (e.row.data.multipleAPN === true) { // change requiredRule for APN based on value of multipleAPN
                    e.editorOptions.disabled = true;
                    if (e.validationRules.length >= 1) { 
                        e.validationRules.shift(); // delete the custom validation
                    }
                } else {
                    e.editorOptions.disabled = false;
                    if (e.validationRules.length < 1) {
                        e.validationRules.unshift({type:'custom',
                                                   message: "Tax Rate Area is required.",
                                                   validationCallback: this.customRuleTRA, 
                                                   reevaluate:'true'}); // add the custom validation
                    }
                }
            }
        }
    }
    onEditorPrepared(e) {
        if (e.parentType === 'dataRow' && e.dataField === "paperlessCommunication") {
            e.setValue(e.row.data.paperlessCommunication) // change something in the form to trigger validations when opening form for the first time
        }
    }

    onClickSaveApprove = (e) => {
        isApproved = true;
        validationNeeded = true;
        this.dataGrid.instance.saveEditData();
    }
    onClickSaveReject = (e) => {
        isApproved = false;
        validationNeeded = false;
        this.dataGrid.instance.saveEditData();
    }
    onClickCancel = (e) => {
        this.dataGrid.instance.cancelEditData();
    }

    onRowUpdating(e) {
        //debugger;
        if (e.newData.multipleAPN === true) {
            e.newData.apn = "";
            e.newData.taxRateAreaId = null;
            e.newData.communityId = null;
        }
    }

    onSaved(e) {
        //debugger;
        const { user } = AuthService.getTOTUser();
        if (isApproved) { // trigger status to Approved Pending Notification
            let myObj = {
                PropertyId: currentKey,
                StatusId: 5,
                ModifiedBy: user.employeeId
            }
            let body = JSON.stringify(myObj);
            return sendRequest(url + "/Registration/ApproveCert", 'POST', body).then(myJson => {
                //debugger;
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                }
                else {
                    this.props.history.push("/CertWrapper/" + currentKey);
                }
            }); 
        } else { // trigger status to Rejected Pending Notification
            let myObj = {
                PropertyId: currentKey,
                StatusId: 6,
                ModifiedBy: user.employeeId
            }
            let body = JSON.stringify(myObj);
            return sendRequest(url + "/Registration/ApproveCert", 'POST', body).then(myJson => {
                //debugger;
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                }
                else {
                    this.props.history.push("/DenialWrapper/" + currentKey);
                }
            }); 
        }
    }

    editCellRenderForAttachment = e => {
        //debugger;
        return (
            <div>
                <Attachment propertyId={e.data.propertyId} />
            </div>
        )
    }

    editCellRenderForNotes = (e) => {
        //debugger;
        return (
            <div>
                <NotesHistory propertyId={e.data.propertyId} />
            </div>
        )
    }

    setCellValueTypeOfRentalId = (newData, value, currentRowData) => {
        newData.typeOfRentalId = value;
    }
    setCellValueMultipleAPN = (newData, value, currentRowData) => {
        newData.multipleAPN = value;
    }

    checkAPN = (params) => {
        // debugger;
        let propertyId = params.data.propertyId;
        let apn = params.value;

        const myObj = {
            propertyId: propertyId,
            apn: apn
        }
        const body = JSON.stringify(myObj);

        return sendRequest(url + "/Registration/CheckAPN", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson;
            }
        });
    }

    customRuleAPN = (params) => {
        // debugger;
        if (validationNeeded) {
            var requiredRule = params.data.apn != null && params.data.apn !== "";
            var stringLengthRule = params.data.apn.length <= 20;
            return requiredRule && stringLengthRule;        
        } else {
            return true;
        }
    }
    customRuleTRA = (params) => {
        // debugger;
        if (validationNeeded) {
            return params.data.taxRateAreaId != null && params.data.taxRateAreaId !== ""; 
        } else {
            return true;
        }
    }

    render() {
        const { certData, communityData, traData, typeOfRentalData, statusData } = this.state;

        return (
            <div className={'content-block'}>
              <PageHeader header="Certificate Applications"/>

                <DataGrid id="grid-container"
                    dataSource={certData}
                    keyExpr="propertyId"
                    rowAlternationEnabled={true}
                    hoverStateEnabled={true}
                    showBorders={true}
                    onEditingStart={this.onEditingStart}
                    onEditorPreparing={this.onEditorPreparing}
                    onEditorPrepared={this.onEditorPrepared}
                    onRowUpdating={this.onRowUpdating}
                    onSaved={this.onSaved}
                    ref={ref=>this.dataGrid=ref}
                >
                    <SearchPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <Editing
                        mode="popup"
                        //useIcons={true}
                        allowUpdating={true}
                        //allowAdding={true}
                        //allowDeleting={true}
                    >
                        <Popup title="Edit" showTitle={true}>
                            <ToolbarItem
                                toolbar="bottom"
                                widget="dxButton"
                                location="before"
                                options={{ text: 'Cancel', onClick: this.onClickCancel }}>
                            </ToolbarItem>
                            <ToolbarItem
                                toolbar="bottom"
                                widget="dxButton"
                                location="after"
                                options={{
                                    text: 'Save & Reject',
                                    onClick: this.onClickSaveReject,
                                    elementAttr: {
                                        class: 'denialButton'
                                    }
                                }}>
                            </ToolbarItem>
                            <ToolbarItem
                            toolbar="bottom"
                            widget="dxButton"
                            location="after"
                                options={{
                                    text: 'Save & Approve',
                                    onClick: this.onClickSaveApprove,
                                    elementAttr: {
                                        class: 'approveButton'
                                    }
                                }}>
                            </ToolbarItem>
                            {/*<Position my="top" at="top" of={window} /> */}
                        </Popup>
                       
                        <Form colCount={12}>

                            <Item itemType="group" caption="Owner Info" colCount={12} colSpan={12}>
                                <Item dataField="ownerName" colSpan={4} />
                                <Item dataField="certNumber" colSpan={4} />

                                <Item dataField="certificationDate" colSpan={4} />

                                <Item dataField="ownerPhoneNumber"
                                    editorOptions={{
                                        mask: '(000) 000-0000',
                                        //useMaskedValue: true
                                    }}
                                    colSpan={4}
                                />
                                <Item dataField="ownerEmail" colSpan={4} />
                                <Item dataField="paperlessCommunication" colSpan={4} />
                            </Item>

                            <Item itemType="group" caption="Property Address" colCount={12} colSpan={12}>
                                <Item dataField="rentalAddress" colSpan={4} />
                                <Item dataField="rentalAddress2" colSpan={8} />
                                <Item dataField="rentalCity" colSpan={4} />
                                <Item dataField="rentalState" colSpan={4} />
                                <Item dataField="rentalZip" colSpan={4} />
                                <Item dataField="apn"
                                    editorOptions={{
                                        mask: '0000-000-00-X000',
                                        maskRules: {
                                            X: /[A-Za-z0-9]/,
                                        }
                                        //useMaskedValue: true,
                                    }}
                                    colSpan={4}
                                    cssClass="required"
                                />
                                <Item dataField="multipleAPN" colSpan={4} />
                                <Item itemType="empty" colSpan={4} />
                                <Item dataField="communityId" 
                                    colSpan={4} 
                                />
                                <Item dataField="taxRateAreaId" 
                                    colSpan={4} 
                                    cssClass="required"
                                />
                                <Item itemType="empty" colSpan={4} />
                            </Item>
                            <Item itemType="group" caption="Mailing Address" colCount={12} colSpan={12}>
                                <Item dataField="mailingAddress" colSpan={4} />
                                <Item dataField="mailingAddress2" colSpan={8} />
                                <Item dataField="mailingCity" colSpan={4} />
                                <Item dataField="mailingState" colSpan={4} />
                                <Item dataField="mailingZip" colSpan={4} />
                            </Item>
                            <Item itemType="group" caption="Rental Data" colCount={12} colSpan={12} >
                                <Item dataField="busMgmtName" colSpan={8} />
                                <Item dataField="lengthOfOwnership" colSpan={4} />

                                <Item dataField="typeOfRentalId" colSpan={8} />
                                <Item dataField="typeOfRentalOther" colSpan={4} />

                                <Item dataField="occupancyUnitsNumber" colSpan={4} />
                                <Item itemType="empty" colSpan={8} />
                                <Item dataField="preparedBy" colSpan={4} />
                                <Item dataField="title" colSpan={4} />
                                <Item dataField="preparedByPhoneNumber"
                                    editorOptions={{
                                        mask: '(000) 000-0000',
                                        //useMaskedValue: true
                                    }}
                                    colSpan={4}
                                />
                            </Item>
                            <Item itemType="group" colCount={12} colSpan={12}><hr /></Item>
                            <Item itemType="group" colCount={12} colSpan={12}>

                                <Item dataField="Attachment" colSpan={12} />
                                {/*<Item dataField="statusId" colSpan={4} />*/}
                                <Item itemType="empty" colSpan={12} />

                                <Item dataField="Notes" colSpan={12} />
                                {/*<Item dataField="Option"  colSpan={4} /> */}
                            </Item>
                        </Form>
                    </Editing>

                    <Column dataField="certNumber" caption="Cert #" allowEditing={false} width="100px" />
                    <Column dataField="certificationDate" dataType="date" caption="Cert Date" allowEditing={false} width="100px" />
                    <Column dataField="apn" caption="Assessor Parcel Number" width="200px" >
                        {/* <RequiredRule />
                        <StringLengthRule max={20} /> */}
                        <AsyncRule
                            message="Assessor Parcel Number already exists in another certificate."
                            validationCallback={this.checkAPN}
                            reevaluate={true}
                        />
                        <CustomRule 
                            message="Please enter a valid Assessor Parcel Number." 
                            validationCallback={this.customRuleAPN} 
                            reevaluate={true}
                        />
                    </Column>

                    <Column dataField="ownerName" caption="Owner Name">
                        <RequiredRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="mailingAddress" caption="Address" >
                        <RequiredRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="mailingAddress2" caption="Address 2" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="mailingCity" caption="City" >
                        <RequiredRule />
                        <StringLengthRule max={75} />
                    </Column>
                    <Column dataField="mailingState" caption="State" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={25} />
                    </Column>
                    <Column dataField="mailingZip" caption="Zip" >
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>


                    <Column dataField="ownerPhoneNumber" caption="Phone Number" visible={false}>
                        <StringLengthRule max={25} />
                        {/*<PatternRule message="Your phone must have "(555) 555-5555" format!" pattern={/^\(\d{3}\) \d{3}-\d{4}$/i} />*/}

                    </Column>
                    <Column dataField="ownerEmail" caption="Email" visible={false}>
                        <EmailRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="paperlessCommunication" caption="Paperless" visible={false}>

                    </Column>
                    <Column dataField="rentalAddress" caption="Address" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="rentalAddress2" caption="Address 2" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="rentalCity" caption="City" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={75} />
                    </Column>
                    <Column dataField="rentalState" caption="State" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={25} />
                    </Column>
                    <Column dataField="rentalZip" caption="Zip Code" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>
                    
                    <Column dataField="multipleAPN" caption="Multiple APN?" setCellValue={this.setCellValueMultipleAPN} visible={false} >
                    </Column>

                    <Column dataField="communityId" caption="Community" visible={false}>
                        <Lookup dataSource={communityData} valueExpr="communityId" displayExpr="description" />
                        {/* <CustomRule message="Community is required." validationCallback={this.customRuleCommunity} reevaluate={true}/> */}
                    </Column>
                    <Column dataField="taxRateAreaId" caption="TRA" visible={false} >
                        <Lookup dataSource={traData} valueExpr="taxRateAreaId" displayExpr="tra" />
                        <CustomRule message="Tax Rate Area is required." validationCallback={this.customRuleTRA} reevaluate={true}/>
                    </Column>

                    <Column dataField="busMgmtName" caption="Business/Management Co Name" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="lengthOfOwnership" caption="How Long?" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>

                    <Column dataField="typeOfRentalId" caption="Type of Rental" setCellValue={this.setCellValueTypeOfRentalId} visible={false} >
                        <Lookup dataSource={typeOfRentalData} valueExpr="typeOfRentalId" displayExpr="description" />
                    </Column>
                    <Column dataField="typeOfRentalOther" caption="Type of Rental Other" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="occupancyUnitsNumber" caption="Number of Units" dataType="number" visible={false} />
                    <Column dataField="preparedBy" caption="Prepared By" visible={false} >
                        <StringLengthRule max={100} />
                    </Column>
                    <Column dataField="title" caption="Title" visible={false} >
                        <StringLengthRule max={100} />
                    </Column>
                    <Column dataField="preparedByPhoneNumber" caption="Prepared By Phone Number" visible={false} >
                        <StringLengthRule max={25} />
                    </Column>

                    <Column dataField="Attachment" visible={false}
                        editCellRender={this.editCellRenderForAttachment}
                    />
                    <Column dataField="dateSigned" dataType="date" caption="Date Signed"></Column>
                    <Column dataField="statusId" caption="Status">
                        <Lookup dataSource={statusData} valueExpr="statusId" displayExpr="description" />
                    </Column>
                    
                    <Column dataField="Notes" visible={false}
                        editCellRender={this.editCellRenderForNotes}
                    />

                    <Column type="buttons">
                        <Button name="edit" icon="edit" hint="Edit" />
                    </Column>

                  
                </DataGrid>
            </div>
        );
    }
}

export default CertAppPage;