import React from 'react';
import { Button, Container } from 'react-bootstrap';

function SearchCertWrapper(props) {
    const { children, title, onBackClick, onNextClick, onSubmit } = props;

    return (
        <Container>            
            <h2 className='step-title'><b>{title || "Search for a Certificate"}</b></h2>
            <>
                {children}
            </>
            <div className='action-button-wrapper float-end'>
                {onBackClick &&
                    <Button variant="secondary" className='action-button' onClick={(e) => onBackClick(e)}>Back</Button>
                }
                {onNextClick &&
                    <Button variant="primary" className='action-button' onClick={(e) => onNextClick(e)}>Next</Button>
                }
                {onSubmit &&
                    <Button variant="primary" className='action-button' onClick={(e) => onSubmit(e)}>New Tax Return</Button>
                }
            </div>
        </Container>
    );
}

export default SearchCertWrapper;