import React from "react";
import { Col, Row, InputGroup } from 'react-bootstrap';

export const SectionHeader = ({ label, value, ...props }) => {
    return (
        <div>
            <span style={{color:"#616161", fontSize:"22px"}}>Payer Information</span>
            <hr style={{margin: "8px 0 16px 0"}}/>
        </div>
    )
}

export const Text = ({ label, input, isRequired, ...props }) => {
    return (
        <Row style={{ color: "#616161", padding: "4px", fontSize: "15px", className: "row justify-content-between" }}>
            <Col className="col-4">{label}: {isRequired && <span style={{color: "red"}}>*</span>}</Col>
            <Col style={{paddingLeft:"40px"}}>
                <InputGroup className="container-fluid">{input}</InputGroup>
            </Col>
        </Row>
    )
}




