import React, { Component } from 'react';
import './home.scss';
import DataGrid,
{
    Column,
    Lookup,
    Editing,
    Popup,
    Form,
    RequiredRule,
    EmailRule,
    AsyncRule,
    CustomRule,
    StringLengthRule,
    //PatternRule,
    SearchPanel,
    HeaderFilter,
    MasterDetail,
    Button
} from 'devextreme-react/data-grid';
//import TextArea from 'devextreme-react/text-area';
import { Item } from 'devextreme-react/form';
//import { Button as Button1 } from 'devextreme-react/button';
//import notify from 'devextreme/ui/notify';
//import { confirm } from 'devextreme/ui/dialog';
//import { Popup as Popup1 } from 'devextreme-react/popup';

import Attachment from './Attachment.js';
import NotesHistory from './NotesHistory.js';
import DetailTemplate from './DetailTemplate.js';
//import ExportPdfComponentForCert from '../../components/exportpdfcomponent/ExportPdfComponentForCert.js';

import CustomStore from 'devextreme/data/custom_store';

import { sendRequest } from '../../_helper/util.js';
import SecureLS from "../../../node_modules/secure-ls/dist/secure-ls";
import PageHeader from '../../components/header/PageHeader/PageHeader';
import { formatAPN } from '../../_helper/helpers';

const url = process.env.REACT_APP_API_URL + "/api";
var validationNeeded = true;

class homepage extends Component {
    constructor(props) {
        super(props);

        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.checkAPN = this.checkAPN.bind(this);

        this.state = {
           
            certData: new CustomStore({
                key: 'propertyId',
                load: this.loadAllCert,
                update:this.updateCert
            }),
            //showCertPopup:false,
            currentPropertyId:0
        };

    }

    componentDidMount() {
        //debugger;
        sendRequest(url + "/Lookup/GetAllLookup", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                this.setState({
                    communityData: myJson.listLookup.listCommunity,
                    traData: myJson.listLookup.listTRA,
                    typeOfRentalData: myJson.listLookup.listTypeOfRental,
                    statusData: myJson.listLookup.listStatus
                });

            }
        });

    }

    loadAllCert() {

        return sendRequest(url + "/Registration/GetAllCert", 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson.listCert
            }
        });
    }

    updateCert=(key, values)=> {
        //debugger;
        
        const ls = new SecureLS();
        const userinfo = ls.get('userinfo');

        if (values.apn) {
            values.apn = formatAPN(values.apn);
        }

        values.PropertyId = key;
        values.ModifiedBy = userinfo.user ? userinfo.user.employeeId : "";

        const body = JSON.stringify(values);
        

        return sendRequest(url + "/Registration/UpdateCert", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                
                //if (myJson.redirectToEditor) {
                //    //redirect to pdf editor page
                //    this.props.history.push("/CertTemplate/" + myJson.rowToUpdate.propertyId);
                //}
                return myJson;
                
            }
        });

    }

    //isPreviewVisible = (e) => {
    //    //debugger;
    //    return !(e.row.data.certNumber===null)
    //}

    //isSendEmailWithCertificateVisible = (e) => {
    //    return !(e.row.data.certNumber === null)
    //}

    //onClickPreviewCert = (e) => {
    //    //debugger;
    //    //window.open(url + "/Registration/PreviewCert?propertyId=" + e.row.data.propertyId);

    //    this.setState({
    //        showCertPopup: true,
    //        currentPropertyId: e.row.data.propertyId
    //    });
    //}

    //onClickSendEmail = (e) => {
        
    //    //debugger;
    //    let result = confirm("Are you sure you want to send email with certificate attached to " + e.row.data.ownerEmail+"?", "Confirm");
    //    result.then((dialogResult) => {
    //        if (dialogResult) {
    //            sendRequest(url + "/Registration/SendEmailWithCertificate?propertyId=" + e.row.data.propertyId, 'POST', "", getAdalToken()).then(myJson => {
    //                //debugger;
    //                if (typeof myJson.message !== "undefined") {
    //                    notify(myJson.message, "error", 3000);
    //                }
    //                else {
    //                    notify("Send Email successfully.", "success", 3000);
    //                }
    //            });
    //        }
    //    });
       
    //}

    //editCellRenderForOption = e => {
    //    return (
    //        <div>

    //            <Button1 className="optionButtons" text="Preview Certificate" type="default" onClick={() => this.onClickPreviewCert(e.data.propertyId)} />

    //            <Button1 className="optionButtons" text="Send Email With Certificate" hint="Send Email to Operator with Certificate Attached." type="default" onClick={() => this.onClickSendEmail(e)} />
                
    //        </div>
    //    )
    //}

    editCellRenderForAttachment = e => {
        //debugger;
        return (
            <div>
                <Attachment propertyId={e.data.propertyId} />
            </div>
        )
    }

    editCellRenderForNotes = (e) => {
        //debugger;
        return (
            <div>
                
                <NotesHistory propertyId={e.data.propertyId} />
            </div>
        )
    }

    onEditingStart = (e) => {
        // debugger;
        var d = this.state.statusData.find(({ statusId }) => statusId === e.data.statusId).description;
        validationNeeded = (d !== 'REJECTED' && d !== 'Rejected Pending Notification');
    }

    onEditorPreparing(e) {
        // debugger;
        if (e.parentType === 'dataRow') {

            if (e.dataField === "typeOfRentalOther") {
                //debugger;
                if (e.row.data.typeOfRentalId === 6) {
                    e.editorOptions.disabled = false;
                }
                else {
                    e.editorOptions.disabled = true;
                }
            }
            if (e.dataField === "apn") {
                if (e.row.data.multipleAPN === true) { // change requiredRule for APN based on value of multipleAPN
                    e.editorOptions.disabled = true;
                    if (e.validationRules.length >= 2) { // hardcoded number of validations
                        e.validationRules.shift(); // delete validations
                        e.validationRules.shift(); 
                    }
                } else {
                    e.editorOptions.disabled = false;
                    if (e.validationRules.length < 2) { // add back validations
                        e.validationRules.unshift({type:'custom',
                                                   message: "Please enter a valid APN.",
                                                   validationCallback: this.customRuleAPN, 
                                                   reevaluate:'true'});
                        e.validationRules.unshift({type:'async',                           
                                                   message: "Assessor Parcel Number already exists in another certificate.",
                                                   validationCallback: this.checkAPN,
                                                   reevaluate:'true'}); 
                    }
                }
            }
            if (e.dataField === "taxRateAreaId") {
                if (e.row.data.multipleAPN === true) { // change requiredRule for APN based on value of multipleAPN
                    e.editorOptions.disabled = true;
                    if (e.validationRules.length >= 1) { 
                        e.validationRules.shift(); // delete the custom validation
                    }
                } else {
                    e.editorOptions.disabled = false;
                    if (e.validationRules.length < 1) {
                        e.validationRules.unshift({type:'custom',
                                                   message: "Tax Rate Area is required.",
                                                   validationCallback: this.customRuleTRA, 
                                                   reevaluate:'true'}); // add the custom validation
                    }
                }
            }
        }
    }
    onEditorPrepared(e) {
        if (e.parentType === 'dataRow' && e.dataField === "paperlessCommunication") {
            e.setValue(e.row.data.paperlessCommunication) // change something in the form to trigger validations when opening form for the first time
        }
    }

    setCellValueTypeOfRentalId = (newData, value, currentRowData) => {
        newData.typeOfRentalId = value;
    }
    setCellValueMultipleAPN = (newData, value, currentRowData) => {
        newData.multipleAPN = value;
    }

    checkAPN = (params) => {
        //debugger;
        let propertyId = params.data.propertyId;
        let apn = params.value;

        const myObj = {
            propertyId: propertyId,
            apn: apn
        }
        const body = JSON.stringify(myObj);

        return sendRequest(url + "/Registration/CheckAPN", 'POST', body).then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                return myJson
            }
        });

    }

    customRuleAPN = (params) => {
        // debugger;
        if (validationNeeded) {
            var requiredRule = params.data.apn != null && params.data.apn !== "";
            var stringLengthRule = params.data.apn.length <= 20;
            return requiredRule && stringLengthRule;        
        } else {
            return true;
        }
    }
    customRuleTRA = (params) => {
        // debugger;
        if (validationNeeded) {
            return params.data.taxRateAreaId != null && params.data.taxRateAreaId !== ""; 
        } else {
            return true;
        }
    }


    render() {
        const { certData, communityData, traData, typeOfRentalData, statusData//, showCertPopup, currentPropertyId
        } = this.state;

        return (
            <div className={'content-block'}>
               <PageHeader header="Certificates"/>

                <DataGrid id="grid-container"
                    dataSource={certData}
                    keyExpr="propertyId"
                    rowAlternationEnabled={true}
                    hoverStateEnabled={true}
                    showBorders={true}
                    onEditingStart={this.onEditingStart}
                    onEditorPreparing={this.onEditorPreparing}
                    onEditorPrepared={this.onEditorPrepared}
                    ref={ref=>this.dataGrid=ref}
                >
                    <SearchPanel visible={true} />
                    <HeaderFilter visible={true} />
                    <Editing
                        mode="popup"
                        //useIcons={true}
                        allowUpdating={true}
                        //allowAdding={true}
                        //allowDeleting={true}
                    >
                        <Popup title="Edit" showTitle={true} //width={1200} height={800}
                        />
                        <Form colCount={12}>

                            <Item itemType="group" caption="Owner Info" colCount={12} colSpan={12}>
                                <Item dataField="ownerName" colSpan={4}/>
                                <Item dataField="certNumber" colSpan={4}/>
                                
                                <Item dataField="certificationDate" colSpan={4} />
                                
                                <Item dataField="ownerPhoneNumber"
                                    editorOptions={{
                                    mask: '(000) 000-0000',
                                    //useMaskedValue: true
                                    }}
                                    colSpan={4}
                                />
                                <Item dataField="ownerEmail" colSpan={4}/>
                                <Item dataField="paperlessCommunication" colSpan={4}/>
                            </Item>

                            <Item itemType="group" caption="Property Address" colCount={12} colSpan={12}>
                                <Item dataField="rentalAddress" colSpan={4}/>
                                <Item dataField="rentalAddress2" colSpan={8} />
                                <Item dataField="rentalCity" colSpan={4}/>
                                <Item dataField="rentalState" colSpan={4}/>
                                <Item dataField="rentalZip" colSpan={4}/>
                                <Item dataField="apn"
                                    editorOptions={{
                                        mask: '0000-000-00-X000',
                                        maskRules: {
                                            X: /[A-Za-z0-9]/,
                                        }
                                    //useMaskedValue: true,
                                    }}
                                    colSpan={4}
                                    cssClass="required"
                                />
                                <Item dataField="multipleAPN" colSpan={4}/>
                                <Item itemType="empty" colSpan={4}/>
                                <Item dataField="communityId" 
                                    colSpan={4} 
                                />
                                <Item dataField="taxRateAreaId" 
                                    colSpan={4} 
                                    cssClass="required"
                                />
                                <Item itemType="empty" colSpan={4}/>
                            </Item>
                            <Item itemType="group" caption="Mailing Address" colCount={12} colSpan={12}>
                                <Item dataField="mailingAddress" colSpan={4}/>
                                <Item dataField="mailingAddress2" colSpan={8} />
                                <Item dataField="mailingCity" colSpan={4}/>
                                <Item dataField="mailingState" colSpan={4}/>
                                <Item dataField="mailingZip" colSpan={4}/>
                            </Item>
                            <Item itemType="group" caption="Rental Data" colCount={12} colSpan={12} >
                                <Item dataField="busMgmtName" colSpan={8} />
                                <Item dataField="lengthOfOwnership" colSpan={4}/>
                               
                                <Item dataField="typeOfRentalId" colSpan={8} />
                                <Item dataField="typeOfRentalOther" colSpan={4} />

                                <Item dataField="occupancyUnitsNumber" colSpan={4} />
                                <Item itemType="empty" colSpan={8} />
                                <Item dataField="preparedBy" colSpan={4}/>
                                <Item dataField="title" colSpan={4}/>
                                <Item dataField="preparedByPhoneNumber"
                                    editorOptions={{
                                    mask: '(000) 000-0000',
                                    //useMaskedValue: true
                                    }}
                                    colSpan={4}
                                />
                            </Item>
                            <Item itemType="group" colCount={12} colSpan={12}><hr /></Item>
                            <Item itemType="group" colCount={12} colSpan={12}>
                              
                                <Item dataField="Attachment" colSpan={12} />
                                <Item dataField="statusId" colSpan={4} />
                                <Item itemType="empty" colSpan={8} />

                                <Item dataField="Notes" colSpan={12} />
                                {/*<Item dataField="Option"  colSpan={4} /> */}
                            </Item>
                        </Form>
                    </Editing>

                    <Column dataField="certNumber" caption="Cert #" allowEditing={false} width="100px" />
                    <Column dataField="certificationDate" dataType="date" caption="Cert Date" allowEditing={false} width="100px" />
                    <Column dataField="apn" caption="Assessor Parcel Number" width="200px" >
                        <AsyncRule
                            message="Assessor Parcel Number already exists in another certificate."
                            validationCallback={this.checkAPN}
                            reevaluate={true}
                        />
                        <CustomRule 
                            message="Please enter a valid Assessor Parcel Number." 
                            validationCallback={this.customRuleAPN} 
                            reevaluate={true}
                        />
                    </Column>

                    <Column dataField="ownerName" caption="Owner Name">
                        <RequiredRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="mailingAddress" caption="Address" >
                        <RequiredRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="mailingAddress2" caption="Address 2" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="mailingCity" caption="City" >
                        <RequiredRule />
                        <StringLengthRule max={75} />
                    </Column>
                    <Column dataField="mailingState" caption="State" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={25} />
                    </Column>
                    <Column dataField="mailingZip" caption="Zip" allowSearch={false} >
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>

                    
                    <Column dataField="ownerPhoneNumber" caption="Phone Number" visible={false}>
                        <StringLengthRule max={25} />
                        {/*<PatternRule message="Your phone must have "(555) 555-5555" format!" pattern={/^\(\d{3}\) \d{3}-\d{4}$/i} />*/}

                    </Column>
                    <Column dataField="ownerEmail" caption="Email" visible={false}>
                        <EmailRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="paperlessCommunication" caption="Paperless" visible={false}>
                       
                    </Column>
                    <Column dataField="rentalAddress" caption="Address" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="rentalAddress2" caption="Address 2" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="rentalCity" caption="City" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={75} />
                    </Column>
                    <Column dataField="rentalState" caption="State" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={25} />
                    </Column>
                    <Column dataField="rentalZip" caption="Zip Code" visible={false} >
                        <RequiredRule />
                        <StringLengthRule max={10} />
                    </Column>
                    <Column dataField="multipleAPN" caption="Multiple APN?" setCellValue={this.setCellValueMultipleAPN} visible={false} >
                    </Column>

                    <Column dataField="communityId" caption="Community" visible={false}>
                        <Lookup dataSource={communityData} valueExpr="communityId" displayExpr="description" />
                    </Column>
                    <Column dataField="taxRateAreaId" caption="TRA" visible={false} >
                        <CustomRule message="Tax Rate Area is required." validationCallback={this.customRuleTRA} reevaluate={true}/>
                        <Lookup dataSource={traData} valueExpr="taxRateAreaId" displayExpr="tra" />
                    </Column>


                    <Column dataField="busMgmtName" caption="Business/Management Co Name" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="lengthOfOwnership" caption="How Long?" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                  
                    <Column dataField="typeOfRentalId" caption="Type of Rental" setCellValue={this.setCellValueTypeOfRentalId}  visible={false} >
                        <Lookup dataSource={typeOfRentalData} valueExpr="typeOfRentalId" displayExpr="description" />
                    </Column>
                    <Column dataField="typeOfRentalOther" caption="Type of Rental Other" visible={false} >
                        <StringLengthRule max={255} />
                    </Column>
                    <Column dataField="occupancyUnitsNumber" caption="Number of Units" dataType="number" visible={false} />
                    <Column dataField="preparedBy" caption="Prepared By" visible={false} >
                        <StringLengthRule max={100} />
                    </Column>
                    <Column dataField="title" caption="Title" visible={false} >
                        <StringLengthRule max={100} />
                    </Column>
                    <Column dataField="preparedByPhoneNumber" caption="Prepared By Phone Number" visible={false} >
                        <StringLengthRule max={25} />
                    </Column>

                    <Column dataField="Attachment" visible={false}
                        editCellRender={this.editCellRenderForAttachment}
                    />
                    <Column dataField="statusId" caption="Status">
                        <Lookup dataSource={statusData} valueExpr="statusId" displayExpr="description" />
                    </Column>
                    <Column dataField="Notes" visible={false}
                        editCellRender={this.editCellRenderForNotes}
                    />
                    {/*
                     * <Column dataField="Option" visible={false} caption="Option" editCellRender={this.editCellRenderForOption} />
                     */}

                    <Column type="buttons">
                        <Button name="edit" icon="edit" hint="Edit" />
                        {/*<Button name="Preview" icon="pdffile" hint="Preview Certificate"
                            visible={this.isPreviewVisible}
                            onClick={this.onClickPreviewCert}
                        />
                        <Button name="SendEmailWithCertificate" icon="email" hint="Send Email with Certificate"
                            visible={this.isSendEmailWithCertificateVisible}
                            onClick={this.onClickSendEmail} />*/}
                    </Column>

                    <MasterDetail
                        enabled={true}
                        component={DetailTemplate}
                    />
                </DataGrid>
              
                {/*showCertPopup &&  <Popup1
                    visible={showCertPopup}
                    onHidden={this.hideCertPopup}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    title="Certificate"
                >
                    <ExportPdfComponentForCert propertyId={currentPropertyId} />
                </Popup1>
                */}

            </div>
        );
    }
}

export default homepage;

