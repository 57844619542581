import React from 'react';
import './TOT_LandingPad.scss';
import Button from 'react-bootstrap/Button';
import "../../../utils/bootstrap/bootstrap.min.css";
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import tcHeader from "../../../img/top_banner3.jpg"
//import PageHeader from '../../../components/header/PageHeader/PageHeader';

const TOTLandingPad = (props) => {
    const history = useHistory();

    return (
        <Container fluid className="pt-5" style={{backgroundColor:"white"}}>
            {/* <h3 className='mb-5'>San Bernardino County - Transient Occupancy Tax</h3> */}
            {/*<PageHeader header="Transient Occupancy Tax"></PageHeader>*/}
            
            <div className="image-container"><img src={tcHeader} alt="header" /></div><div style={{ fontSize: "42px", fontWeight: "500", color:"#012d74", margin: "20px" }} className="image-container">Transient Occupancy Tax</div>
            {/*<hr/>*/}
            <div className="image-container">
                <div className="mt-4 p-5 text-white rounded text-left " style={{ backgroundColor: "#002d73", color: "white", width: 1200 }}>
                    <Container>
                        <h4 className='mb-5'>What would you like to do today?</h4>
                        <div className="row">
                            <div className="col-md-6 col-lg-6">
                                <center>
                                    <Button variant="light" size="lg" className='w-100 m-1' style={{ minHeight: "100px" }} onClick={() => history.push('/CertificateRegistration')}>Register for a TOT Certificate &raquo;</Button>
                                </center>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <center>
                                    <Button variant="light" size="lg" className='w-100 m-1' style={{ minHeight: "100px" }} onClick={() => history.push('/CertNumberSearch')}>Submit TOT with an existing TOT Certificate &raquo;</Button>
                                </center>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </Container>
    );
};

export default TOTLandingPad;