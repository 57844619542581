import React, { useEffect, useState } from 'react';
import TemplateEditor from '../Template/TemplateEditor';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../../_helper/util';
import ExportPdfComponent from '../exportpdfcomponent/ExportPdfComponent';
import TemplateButtons from '../Template/TemplateButtons';
import { LoadPanel } from 'devextreme-react';
import AuthService from '../../services/AuthService';

const apiUrl = `${process.env.REACT_APP_API_URL}/api`;
function TaxReturnRejectTemplate(props) {
    const { id: taxReturnId , statusCode,from} = useParams();
    const history = useHistory();
    const { user } = AuthService.getTOTUser();
    const [isLoading, setIsLoading] = useState(true);
    const [template, setTemplate] = useState();
    const [content, setContent] = useState();

    useEffect(() => {
        getRejectionTemplate()
            .then(response => {
                setTemplate(response)
                return response;
            })
            .then(response => setContent(response.templateContent))
            .then(() => setIsLoading(false));
    }, [])

    const getRejectionTemplate = () => {
        return sendRequest(`${apiUrl}/TaxReturn/getRejectionTemplate/${taxReturnId}/${user.employeeId}`, "GET", '')
            .catch(err => console.error(err));
    }

    const handleContentChange = (e) => setContent(e.value);

    const handleCancel = () => history.goBack();

    const handleSave = () => {
        let data = {
            formData:{
            denialTemplateContent: content,
            taxReturnId: parseInt(taxReturnId),
            modifiedBy: user.employeeId
        },
        statusCode
    }
        return sendRequest(`${apiUrl}/TaxReturn/SaveRejection`, "POST", JSON.stringify(data))        
            .then(() => {
                if (from === 'PendingReview') {
                    history.push("/TaxReturnPendingReviewPage")
                }
                else if(from === 'Properties'){
                    history.push("/home")
                }
            }
            );
    };

    return (
        <>
            {!isLoading ? (
                <div className='content-block'>
                    <h1>Tax Return Rejection {template.isPaperless ? "Email" : "Letter"}</h1>
                    {template.isPaperless ??
                        <div className="alert alert-light border" role="alert">
                            The document can be printed using the print button on the PDF preview.
                        </div>
                    }
                    <div className='d-flex' style={{ minHeight: "650px" }}>
                        <div className='col-md-6 p-1'>
                            <TemplateEditor
                                templateContent={template.templateContent}
                                onContentChange={handleContentChange}
                            ></TemplateEditor>
                        </div>
                        <div className='col-md-6 p-1 position-relative'>
                            <ExportPdfComponent
                                templateContent={content}
                                allowRefresh={true}
                                height='100%'
                                width='100%'
                            ></ExportPdfComponent>
                            <TemplateButtons
                                handleCancel={handleCancel}
                                handleSave={handleSave}
                                saveButtonLabel={template.isPaperless ? "Send" : "Mark Complete"}>
                            </TemplateButtons>
                        </div>
                    </div>
                </div>
            ) :
                <LoadPanel></LoadPanel>}
        </>
    );
}

export default TaxReturnRejectTemplate;