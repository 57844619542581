import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Info } from './TaxReturnComponents';
import { DataGrid, TreeList } from 'devextreme-react';
import { Column, Editing, Form, Lookup, MasterDetail } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Item, RequiredRule, EmailRule } from 'devextreme-react/form';
import { sendRequest } from '../../_helper/util';
import AuthService from '../../services/AuthService';
import TaxReturnPaymentDetails from './TaxReturnPaymentDetails';
import './PopupDetails.scss'



TaxReturnPayments.propTypes = {
    taxReturnId: PropTypes.number.isRequired,
    paymentStatusCode: PropTypes.string.isRequired,
    refreshDetails: PropTypes.func.isRequired,
    saveAmounts: PropTypes.func.isRequired,
    effectiveDate: PropTypes.object.isRequired
};

const apiUrl = process.env.REACT_APP_API_URL;
// var isSavePayment = false;

function TaxReturnPayments({ taxReturnId, paymentStatusCode, refreshDetails, saveAmounts, effectiveDate, isRefreshPayments, ...props }) {
    const userId = AuthService.getTOTUser().user.employeeId;
    const [payments, setPayments] = useState();
    const [paymentLookups, setPaymentLookups] = useState([]);
    const dataGrid = useRef(null);

    useEffect(() => {
        getPaymentLookups().then(response => setPaymentLookups(response.listLookup));
        setPayments(new CustomStore({
            key: 'paymentId',
            load: getPayments,
            insert: () => { return; }
        }))
    }, [taxReturnId])

    // This function is here to allow the parent (TaxReturnDetails) to call this component and 
    // refresh the payments.   The situation of when this would need to happen is when 
    // a credit is applied to this tax return.  Which is triggered by a button on the (TaxReturnDetails)
    // screen.
    useEffect(() => {
        if (isRefreshPayments) { refreshPayments() };
    }, [isRefreshPayments]);

    const getPayments = () => {
        const endpointUrl = apiUrl + `/api/TaxReturn/Payments/${taxReturnId}`;
        return sendRequest(endpointUrl, "GET", '')
    }

    const getPaymentLookups = () => {
        const endpointUrl = apiUrl + `/api/Lookup/TaxReturnPayment`;
        return sendRequest(endpointUrl, "GET", '');
    }

    // This function is here to allow the parent (TaxReturnDetails) to call this component and 
    // refresh the payments.   The situation of when this would need to happen is when 
    // a credit is applied to this tax return.  Which is triggered by a button on the (TaxReturnDetails)
    // screen.
    const refreshPayments = () => {        
        setPayments(new CustomStore({
            key: 'paymentId',
            load: getPayments,
            insert: () => { return; }
        }))
    }

    const handleAddPayment = (payment) => {
        payment.recordCreatedDate = new Date();
        payment.recordCreatedUser = userId;
        payment.taxReturnId = taxReturnId;

        const endpointUrl = apiUrl + `/api/TaxReturn/Payments/${taxReturnId}`;
        return sendRequest(endpointUrl, "POST", JSON.stringify(payment)).then(res => {
            refreshDetails();
            dataGrid.current.instance.refresh();
        });
    }

    const onSaving = (e) => {
        e.changes[0].data.paidDate = effectiveDate;
        saveAmounts().then(res => handleAddPayment(e.changes[0].data)); // if saveAmounts() is called inside handleAddPayment, the fees obtained by refreshing frontend is not used somehow
    }

    const onInitNewRow = (e,yearQuarterId) => {
        e.data.accountingYearQuarterId = yearQuarterId;
        e.data.receiptDate = new Date();
    }

    const taxReturnPaymentDetails_Render = (props) => {
        props.refreshDetails = refreshDetails;
        props.parentGrid = dataGrid.current.instance;
        return <TaxReturnPaymentDetails {...props} />;
    }

    return (
        <div className='mt-3 mb-3 p-3 bg-light rounded'>
            <DataGrid id="grid-container" 
                dataSource={payments} 
                keyExpr="paymentId" 
                key="paymentId" 
                showBorders={true}
                onInitNewRow={(e) => onInitNewRow(e, paymentLookups.defaultAccountingYearQuarter.yearQuarterId)} 
                onSaving={onSaving}
                ref={dataGrid}
            > 
                <Editing mode="form" allowAdding={paymentStatusCode !== "PA"}>
                    <Form>
                        <Item itemType="group" caption="Payer Information" colCount={2} colSpan={2}>
                            <Item dataField="payerFirstName" editorOptions={{ maxLength: "25" }}><RequiredRule/></Item>
                            <Item dataField="payerLastName" editorOptions={{ maxLength: "50" }}><RequiredRule/></Item>
                            <Item dataField="payerAddress" editorOptions={{ maxLength: "255" }}><RequiredRule/></Item>
                            <Item dataField="payerCity" editorOptions={{ maxLength: "75" }}><RequiredRule/></Item>
                            <Item dataField="payerState"><RequiredRule/></Item>
                            <Item dataField="payerZipCode"><RequiredRule/></Item>
                            <Item dataField="payerPhoneNumber"></Item>
                            <Item dataField="payerEmailAddress"><EmailRule/></Item>
                        </Item>
                        <Item itemType="group" caption="Payment Information" colCount={2} colSpan={2}>
                            <Item dataField="paidDate" ><RequiredRule /></Item>
                            <Item dataField="receiptDate"><RequiredRule /></Item>
                            <Item dataField="paymentTypeId"><RequiredRule/></Item>
                            <Item dataField="paymentTenderId"><RequiredRule/></Item>
                            <Item dataField="checkNumber" editorOptions={{ maxLength: "10" }}/>
                            <Item dataField="paymentAmount" format="currency"><RequiredRule/></Item>
                            <Item dataField="sourceConfirmationNumber" editorOptions={{ maxLength: "50" }}/>
                            <Item dataField="accountingYearQuarterId"><RequiredRule/></Item>
                        </Item>
                    </Form>
                </Editing>
                <Column dataField="paymentId" visible={false}></Column>
                <Column dataField="payerFirstName" caption="First Name" />
                <Column dataField="payerLastName" caption="Last Name" />
                <Column dataField="reportingYearQuarterOfCredit" visible={false} />
                <Column dataField="payerAddress" caption="Address" visible={false} />
                <Column dataField="payerCity" caption="City" visible={false} />
                <Column dataField="payerState" caption="State" visible={false} >
                    <Lookup dataSource={paymentLookups.states} valueExpr="abbreviation" displayExpr="abbreviation"></Lookup>
                </Column>
                <Column dataField="payerZipCode" caption="Zip Code" visible={false} editorOptions={{ mask: '00000', maskChar: ' ' }} />
                <Column dataField="payerPhoneNumber" caption="Phone Number" visible={false} editorOptions={{ mask: '+1 (000) 000-0000' }} />
                <Column dataField="payerEmailAddress" caption="Email Address" visible={false} />
                <Column dataField="paidDate" dataType="date" caption="Effective Date" editorOptions={{ value: effectiveDate, disabled: "true" }} />
                <Column dataField="receiptDate" dataType="date" visible={false} 
                />
                <Column dataField="paymentTenderId" caption="Payment Tender" visible={false}>
                    <Lookup dataSource={paymentLookups.paymentTender} valueExpr="paymentTenderId" displayExpr="description" />
                </Column>
                <Column dataField="checkNumber" visible={false}/>
                <Column dataField="paymentAmount" dataType="number" format={{ type: "currency", precision: 2 }} editorOptions={{ format: "$ #,##0.00", min: "0" }} />
                <Column dataField="paymentTypeId" caption="Payment Type" visible={false}>
                    <Lookup dataSource={paymentLookups.paymentType} valueExpr="paymentTypeId" displayExpr="description" />
                </Column>
                <Column dataField="sourceConfirmationNumber" visible={false} />
                <Column dataField="accountingYearQuarterId" caption="Accounting Year Quarter" visible={false}>
                    <Lookup dataSource={paymentLookups.listAccountingYearQuarter} valueExpr="yearQuarterId" displayExpr="displayDesc" />
                </Column>
                <Column dataField="isReturnedCheck" visible={true} />
              
                {/*<MasterDetail enabled={true} component={TaxReturnPaymentDetails}></MasterDetail>*/}
                <MasterDetail enabled={true} render={taxReturnPaymentDetails_Render}></MasterDetail>

            </DataGrid>
        </div>
    );
}

export default TaxReturnPayments;