import {
    HomePage,
    CertificateRegistrationPage,
    TOTReturnPage,
    TaxReturnPendingReviewPage,
    ManageTemplates,
    CertAppPage,
    ManageTRA,
    ManageCommunity,
    ManageTypeOfRental,
    ManageOnlinePlatform,
    ManageQuarters,
    ManageUsers,
    ManageFeeType,
    ReportReconciliation,
    ReportMailMerge,
    OverpaymentsOverview
} from '../pages';
//import TemplateEditor from '../components/Template/TemplateEditor';
import EditTemplate from '../components/Template/EditTemplate';
import CertWrapper from '../pages/CertApp/CertWrapper';
import DenialWrapper from '../pages/CertApp/DenialWrapper';
import TaxReturnRejectTemplate from '../components/TaxReturnDetails/TaxReturnRejectTemplate';

export default [
    {
        path: '/Home',
        component: HomePage
    },
    {
        path: '/CertificateRegistrationPage',
        component: CertificateRegistrationPage
    },
    {
        path: '/TOTReturnPage',
        component: TOTReturnPage
    },
    {
        path: '/TaxReturnPendingReviewPage',
        component: TaxReturnPendingReviewPage
    },
    {
        path: '/CertAppPage',
        component: CertAppPage
    },
    {
        path: '/ManageTemplates',
        component: ManageTemplates
    },
    {
        path: '/ManageTemplate/edit/:id',
        component: EditTemplate
    },
    {
        path: '/CertWrapper/:id',
        component: CertWrapper
    },
    {
        path: '/DenialWrapper/:id',
        component: DenialWrapper
    },
    {
        path: '/ManageCommunity',
        component: ManageCommunity
    },
    {
        path: '/ManageTRA',
        component: ManageTRA
    },
    {
        path: '/ManageTypeOfRental',
        component: ManageTypeOfRental
    },
    {
        path: '/ManageOnlinePlatform',
        component: ManageOnlinePlatform
    },
    {
        path: '/ManageQuarters',
        component: ManageQuarters
    },
    {
        path: '/ManageUsers',
        component: ManageUsers
    },
    {
        path: '/ManageFeeType',
        component: ManageFeeType
    },
    {
        path: '/ReportReconciliation',
        component: ReportReconciliation
    },
    {
        path: '/ReportMailMerge',
        component: ReportMailMerge
    },
    {
        path: '/RejectTaxReturn/:id/:statusCode/:from',
        component: TaxReturnRejectTemplate
    },
    {
        path: '/Overpayments',
        component: OverpaymentsOverview
    }
];