import React from 'react';
import { Button } from 'react-bootstrap';
import "../../utils/bootstrap/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import SearchCertWrapper from './searchcert_wrapper';
import InputMask from 'react-input-mask';
import { sendPublicRequest } from '../../_helper/util.js';
import { withRouter } from 'react-router-dom';

const url = process.env.REACT_APP_API_URL + "/api";

class SearchCertNumber extends React.Component {   
    constructor(props) {
        super(props);

        this.state = {
            divisionPhoneNumber:""
        };
    }
    back = (e) => {
        this.props.history.push("/TransientOccupancyTax");
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        let errors = [];
        //owner name
        if (this.props.inputValues.certNumber === "") {
            errors.push("enteredCertNumber");
        }
       
        this.props.handleHasError(errors);

        if (errors.length > 0) {
            return false;
        } else {
            // Retrieve the property ID if cert exists, otherwise error.
            this.retrievePropertyInfoByCertNumber(errors).then((errors) =>
            {
                if (errors.length !== 0) {
                    this.props.handleHasError(errors);
                } else {
                    //this.props.nextStep();

                    if (this.props.inputValues.unpaidTaxReturnCount > 0) {
                        this.props.nextStep();
                    }
                    else {
                        //check if this property has new tax return year quarters
                        sendPublicRequest(url + "/TaxReturn/CheckNewTaxReturnYearQuarter?propertyId=" + this.props.inputValues.propertyId, 'GET', '').then(myJson => {
                            if (typeof myJson.message !== "undefined") {
                                throw myJson.message;
                            }
                            else {
                                //debugger;
                                if (myJson.hasNewTaxReturnYearQuarter) {
                                    this.props.nextStep();
                                }
                                else {
                                    this.setState({ divisionPhoneNumber: myJson.divisionPhoneNumber});
                                    
                                    this.props.handleHasError("HasNoNewTaxReturnQuarter");
                                }

                            }
                        });
                    }

                    
                }       
            })
        }
    };

    hasError(key) {
        return this.props.hasError(key); 
    }

    retrievePropertyInfoByCertNumber = (errors) => {
        return sendPublicRequest(url + "/Property/RetrievePropertyIdUnpaidTaxReturnCountByCert?certNumber=" + this.props.inputValues.certNumber, 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                if(myJson.PropertyId === undefined)
                {
                    errors.push("certNumberNotFound");
                } else {
                    //debugger;
                    this.props.handlePropertyIdChange(myJson.PropertyId);
                    this.props.handleUnpaidTaxReturnCountChange(myJson.UnpaidTaxReturnCount);

                    if(myJson.StatusDescription === "CLOSED")
                    {
                        errors.push("certClosed");
                    }
                    else if(myJson.PropertyId === 0)
                    {
                        errors.push("certNumberNotFound");
                    }
                }
                
                return errors;
            }
        });
    }

    render() {
        const {divisionPhoneNumber } = this.state;
        return (
            
            <SearchCertWrapper title='Search for a Certificate' subtitle="" step="" onBackClick={this.back} onNextClick={this.saveAndContinue}>
                <Container className="pt-1">
                    <div className="p-5 rounded text-left border border-secondary" style={{}}>
                        <Container>
                            <h3 className='mb-3'>Enter certificate number of the property you will be making a payment for:</h3>
                            <InputMask
                                className={
                                    this.hasError("enteredCertNumber")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                formatChars={{
                                    "9": "[0-9]"
                                }}
                                mask="99999"
                                maskChar=" "
                                onChange={this.props.handleCertNumberChange}
                                value={this.props.inputValues.certNumber}
                            ></InputMask>
                            <div className={"inline-errormsg"}>{this.hasError("enteredCertNumber") ? "Please enter a value" : null}</div>
                            <div className={"inline-errormsg"}>{this.hasError("certClosed") ? "Certificate is closed. Tax returns cannot be filed for this certificate." : null}</div>
                            <div className={"inline-errormsg"}>{this.hasError("certNumberNotFound") ? "Certificate number was not found.  Please enter another certificate number or search by parcel number." : null}</div>
                            <div className={"inline-infomsg"}>{this.hasError("HasNoNewTaxReturnQuarter") ? "There are no outstanding unpaid Tax Returns and no reporting period to file. If you have any questions, please call: " + divisionPhoneNumber+"." : null}</div>

                       </Container>
                    </div>
                    <br />
                    <Button type="button" variant="secondary" onClick={this.props.searchAPN}>Lookup by parcel number</Button>
                    {/* <p className='mb-3' style={{fontWeight: 500, fontSize: 20}}>OR</p> */}
                    {/* <Button variant="light" size="lg" className='m-0' style={{ backgroundColor: "#003890", color: "white" }} onClick={this.props.searchAPN}>Lookup by parcel number</Button> */}

                </Container>
            </SearchCertWrapper>
        )        
    }
}

export default withRouter(SearchCertNumber);