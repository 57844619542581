import React, { useEffect, useState } from 'react';
// import { Button } from 'react-bootstrap';
import TemplateEditor from '../../components/Template/TemplateEditor';
import TemplateButtons from '../../components/Template/TemplateButtons';
import CertAppNotes from '../../components/cert-app/CertAppNotes';
import { LoadPanel } from 'devextreme-react';

import ExportPdfComponent from '../../components/exportpdfcomponent/ExportPdfComponent';

function DenialTemplate(props) {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        setIsLoading(false);

    }, [])


    return (
        <div className={'content-block'}>
            {!isLoading ?
                <div style={{ position: "relative", marginBottom: "135px" }}>
                    {props.formData.PaperlessCommunication ? <h1>Denial Email</h1> : <h1>Denial Letter</h1>}
                    {props.formData.PaperlessCommunication ? "" : <CertAppNotes />}

                    <div className='row h-100 mt-2' style={{ minHeight: "650px" }}>
                        <div className='col-md-6'>
                            <TemplateEditor
                            templateContent={props.formData.DenialTemplateContent}
                                height="100%"
                                valueType="html"
                            onContentChange={props.handleDenialContentChange}
                            >
                            </TemplateEditor>
                        </div>
                        <div className='col-md-6' style={{position: "relative"}}>
                            <ExportPdfComponent 
                                templateContent={props.formData.DenialTemplateContent}
                                allowRefresh={true} height="100%" width="100%" >
                            </ExportPdfComponent>

                            <TemplateButtons
                                handleCancel={props.handleCancelDenialTemplate}
                                handleSave={props.handleDenialSend}
                                saveButtonLabel={props.formData.PaperlessCommunication ? "Send" : "Mark Complete"} >
                            </TemplateButtons>

                            {/* <div className='row' style={{ position: "absolute", bottom: "-125px", right: "15px"}} >
                                <div className={"col-xs-12 mt-2"}>
                                    <Button variant="btn btn-outline-secondary" onClick={props.handleCancelDenialTemplate} className={"mx-2"}>Cancel</Button>
                                    <Button variant="primary" onClick={props.handleDenialSend} className={"mx-2"}>
                                        {props.formData.PaperlessCommunication ? "Send" : "Print Mark Complete"}  
                                    </Button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                : <LoadPanel></LoadPanel>}
        </div>
    );
}

export default DenialTemplate;