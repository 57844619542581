import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TOTRegistration from '../../../components/TOTRegistration/TOTRegistration';
import '../LandingPad/TOT_LandingPad.scss';
import tcHeader from "../../../img/top_banner3.jpg"

function CertificateRegistration(props) {
    return (
        <Container fluid className="h-100 overflow-scroll" style={{ backgroundColor: "white" }}>
            <Row className="justify-content-center pt-5">
                <Col>
                    <div className="image-container"><img src={tcHeader} alt="header" /></div><div style={{ fontSize: "42px", fontWeight: "500", color:"#012d74", marginTop: "20px" }} className="image-container">Transient Occupancy Tax</div>
                        <div style={{ margin: "20px" }}>
                            <TOTRegistration></TOTRegistration>   
                        </div>
                </Col>
            </Row>                  
        </Container>
    );
}

export default CertificateRegistration;

