import React, { useEffect, useRef, useState } from 'react';
//import { useHistory} from 'react-router-dom';
import { Container, Form, Col, Row, InputGroup, Table } from 'react-bootstrap';
import { DataGrid, LoadPanel, NumberBox, SelectBox, CheckBox, TextBox, DateBox, FileUploader, List, LoadIndicator, Button} from 'devextreme-react';
import { Column, HeaderFilter, Button as GridButton, Editing, Lookup, MasterDetail, Form as GridForm } from 'devextreme-react/data-grid';
import { Item, RequiredRule as RequiredRuleForm, EmailRule } from 'devextreme-react/form';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import { sendRequest, sendPublicRequest } from '../../_helper/util.js';
import { getAdalToken } from '../../_helper/adalToken';
import AuthService from '../../services/AuthService';
import Alert from "react-bootstrap/Alert";
import { formatDate, formatAPN } from '../../_helper/helpers';
import CustomStore from 'devextreme/data/custom_store';
import TaxReturnPaymentDetails from '../TaxReturnDetails/TaxReturnPaymentDetails.js';
import {SectionHeader, Text} from "./TaxReturn_PaymentComponents";
import "../TaxReturnDetails/PopupDetails.scss";
import EffectiveDate from '../TaxReturnDetails/EffectiveDate.js';

const url = process.env.REACT_APP_API_URL + "/api";
const amountFormat = "#,##0.00";

function TaxReturnFormAdmin(props) {

    //const history = useHistory();
    const uploaderRef = useRef();
    // const listRef = useRef();
    const {user} = AuthService.getTOTUser();
    const [errors, setErrors] = useState([]);
    const [saveError, setSaveError] = useState();
    const [isCalculated, setIsCalculated] = useState(false);
    const [attachmentTypes, setAttachmentTypes] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isAddingPayment, setIsAddingPayment] = useState();
    const [paymentLookups, setPaymentLookups] = useState([]);
    const [paymentInfo, setPaymentInfo] = useState({
        accountingYearQuarterId: null,
        checkNumber: null,
        paidDate: new Date(),
        payerAddress: null,
        payerCity: null,
        payerEmailAddress: null,
        payerFirstName: null,
        payerLastName: null,
        payerPhoneNumber: null,
        payerState: null,
        payerZipCode: null,
        paymentAmount: null,
        paymentTenderId: null,
        paymentTypeId: null,
        receiptDate: new Date(),
        sourceConfirmationNumber: null
    });
    const [latestDateOfFee, setLatestDateOfFee] = useState(new Date(1800, 1, 1));
    const [effectiveDate, setEffectiveDate] = useState(new Date());

    useEffect(() => {
        setEffectiveDate(new Date());
        if (props.formData.taxReturnId !== 0) {
            getAttachments(props.formData.taxReturnId).then(res => setAttachments(res));
            getLatestDateOfFee();
        }
        getAttachmentTypes("TaxReturn").then(response => setAttachmentTypes(response));
        getPaymentLookups().then(response => setPaymentLookups(response.listLookup));
    }, [])

    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }

    const scrollToElment = (id) => {
        let element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
    }

    const getAttachments = (taxReturnId) => {
        const endpointUrl = url + `/TaxReturn/Attachments/${taxReturnId}`;
        return sendRequest(endpointUrl, "GET", '');
    }

    const getAttachmentTypes = (sourceTable) => {
        const endpointUrl = url + `/Lookup/GetAttachmentTypesBySourceTable/${sourceTable}`;
        return sendRequest(endpointUrl, "GET", '');
    }

    const getPaymentLookups = () => {
        const endpointUrl = url + `/Lookup/TaxReturnPayment`;
        return sendRequest(endpointUrl, "GET", '');
    }

    const formatCurrency = (e) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e);
    }
    
    const handleMarkAsReviewed = (taxReturnId) => {
        const endpointUrl = url + `/TaxReturn/MarkAsReviewed/${taxReturnId}/${user.employeeId}`;
        return sendRequest(endpointUrl, "POST", '').then(res => {});
    }

    const onClickBacktoSearch = (e) => {
        props.backtoSearch();
    }

    const onClickCalculate = (e) => {
        let errors = [];
        if (props.formData.totalReceiptsAmt === null || props.formData.totalReceiptsAmt < 0) {
            errors.push("totalReceiptsAmt");
            scrollToElment("nbTotalReceiptAmt");
        }
        if (props.formData.numberOfNights === null) { // || props.formData.numberOfNights===0) {
            errors.push("numberOfNights");
            scrollToElment("nbNumberOfNights");
        }
        if (props.formData.yearQuarter.yearQuarterId === null || props.formData.yearQuarter.yearQuarterId === 0) {
            errors.push("yearQuarter");
            scrollToElment("sbYearQuarter");
        }
        if (props.formData.totalReceiptsAmt > 0 && props.formData.numberOfNights === 0) {
            errors.push("numberOfNights");
            scrollToElment("nbNumberOfNights");
        }
        if (props.formData.totalReceiptsAmt === 0 && props.formData.numberOfNights > 0) {
            errors.push("totalReceiptsAmt");
            scrollToElment("nbTotalReceiptAmt");
        }
        
        if (errors.length > 0) {
            setErrors(errors);
            return false;
        } else {
            calculateAmounts();
        }
    }

    const calculateAmounts = () => {
        // get tax rate from quarter instead of using 0.07
        let taxDueAmt = props.formData.taxableRentsAmt * props.formData.yearQuarter.taxRate;

        let tempArray = [...props.formData.arrSelectedPlatform];
        let taxCollectedOnlinePlatformAmt = 0;
        for (let obj of tempArray) {
            taxCollectedOnlinePlatformAmt += obj.onlinePlatformRemittedToCountyAmount;
        }

        // BUSINESS RULE: If (taxDueAmt - taxCollectedOnlinePlatformAmt) amount is less than 0, show a 0 for the Tax Payable by Operator field.
        // (This happens when the Online Platform collects more than the Operator owes.  Results in a negative amount.   But that is amount owed to 
        // Operator by the Online Platform NOT the county.)
        let taxPayableAmt = ((taxDueAmt - taxCollectedOnlinePlatformAmt) < 0 ? 0 : taxDueAmt - taxCollectedOnlinePlatformAmt);

        sendPublicRequest(url + "/TaxReturn/CalculateAmts?propertyId=" + props.formData.propertyId +
            "&taxReturnId=" + props.formData.taxReturnId +
            "&taxPayableAmt=" + taxPayableAmt +
            "&yearQuarterId=" + props.formData.yearQuarter.yearQuarterId +
            "&effectiveDate=" + effectiveDate.toJSON(), 'GET', '').then(myJson => {
                if (typeof myJson.message !== "undefined") {
                    throw myJson.message;
                }
                else {
                    let latePaymentPenaltyAmt = myJson.penaltyAmt;
                    let interestAmt = myJson.interestAmt;
                    let previousReturnCreditChargeAmt = myJson.previousReturnCreditChargeAmt;

                    let totalPaymentAmt = taxPayableAmt + latePaymentPenaltyAmt + interestAmt + previousReturnCreditChargeAmt;

                        props.setFormData({
                            ...props.formData,

                            taxDueAmt: taxDueAmt,
                            taxCollectedOnlinePlatformAmt: taxCollectedOnlinePlatformAmt,
                            taxPayableAmt: taxPayableAmt,
                            latePaymentPenaltyAmt: latePaymentPenaltyAmt,
                            interestAmt: interestAmt,
                            previousReturnCreditChargeAmt: previousReturnCreditChargeAmt,
                            totalPaymentAmt: totalPaymentAmt
                        });
                    setIsCalculated(true);
                }
            });
    }

    const onClickSubmit = (e) => {
        let docTypeValidation = e.validationGroup.validate();
        if (docTypeValidation.isValid) {
            let errors = [];

            // validate payment info
            if (isAddingPayment) {
                if (!paymentInfo.payerFirstName) 
                    errors.push("firstName");
                if (!paymentInfo.payerLastName)
                    errors.push("lastName");
                if (!paymentInfo.payerAddress)
                    errors.push("address");
                if (!paymentInfo.payerCity)
                    errors.push("city");
                if (!paymentInfo.payerState)
                    errors.push("state");
                if (!paymentInfo.payerZipCode)
                    errors.push("zipCode");
                // if (!paymentInfo.paidDate)
                //     errors.push("paidDate");
                if (!paymentInfo.receiptDate)
                    errors.push("receiptDate");
                if (!paymentInfo.paymentTypeId)
                    errors.push("paymentType");
                if (!paymentInfo.paymentTenderId)
                    errors.push("paymentTender");
                if (!paymentInfo.paymentAmount)
                    errors.push("paymentAmount");
                if (!paymentInfo.accountingYearQuarterId)
                    errors.push("accountingYearQuarter");
            }

            //require online platform file if remitted to County amount greater than 0
            var existOnlinePlatFormFiles = false;
            for (var i = 0; i < selectedFiles.length; i++) {
                if (selectedFiles[i].attachmentType.attachmentTypeCode === 'TT') {
                    existOnlinePlatFormFiles = true;
                    break;
                }
            }
            if (!existOnlinePlatFormFiles && props.existingOnlinePlatformFile.length === 0) {
                let tempArray = [...props.formData.arrSelectedPlatform];
                for (let obj of tempArray) {
                    if (obj.onlinePlatformRemittedToCountyAmount > 0) {
                        errors.push("OnlinePlatformFile");
                        scrollToElment("nbUploadAttachment");
                    };
                }
            }        
    
            if (errors.length > 0) {
                setErrors(errors);
                return false;
            }
            else {
                setErrors([]);
                //check negative total amount
                //if (props.formData.totalPaymentAmt < 0) {
                //    setSaveError("The Total Payment amount is negative. Please review and correct the information");
                //}
                //else {
                let formData = new FormData();
                formData.append('TaxReturnId', props.formData.taxReturnId);
                formData.append('PropertyId', props.formData.propertyId);
                formData.append('ReportingYearQuarterId', props.formData.yearQuarter.yearQuarterId);
                formData.append('TotalReceiptsAmt', props.formData.totalReceiptsAmt);
                formData.append('NumberOfNights', props.formData.numberOfNights);
                formData.append('ExemptionsAmt', props.formData.exemptionsAmt);
                formData.append('ExemptLonger30DaysAmt', props.formData.exemptLonger30DaysAmt);
                formData.append('ExemptGovOffBusAmt', props.formData.exemptGovOffBusAmt);
                formData.append('TaxableRentsAmt', props.formData.taxableRentsAmt);
                formData.append('TaxDueAmt', props.formData.taxDueAmt);
                formData.append('TaxCollectedOnlinePlatformAmt', props.formData.taxCollectedOnlinePlatformAmt);
                formData.append('TaxPayableAmt', props.formData.taxPayableAmt);
                formData.append('LatePaymentPenaltyAmt', props.formData.latePaymentPenaltyAmt);
                formData.append('InterestAmt', props.formData.interestAmt);
                formData.append('PreviousReturnCreditChargeAmt', props.formData.previousReturnCreditChargeAmt);
                formData.append('TotalPaymentAmt', props.formData.totalPaymentAmt);
                formData.append('PreparedBy', user.employeeId);
                formData.append('Title', props.formData.title ?? "");
                formData.append('PhoneNumber', props.formData.phoneNumber ?? "");
                formData.append('EffectiveDate', effectiveDate.toLocaleString("en-US"));
    
                //add OnlinePlatformAmounts 
                for (let i = 0; i < props.formData.arrSelectedPlatform.length; i++) {
    
                    formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformId]`, props.formData.arrSelectedPlatform[i].onlinePlatformId);
                    formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformRemittedToCountyAmount]`, props.formData.arrSelectedPlatform[i].onlinePlatformRemittedToCountyAmount);
                    formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformRemittedToHostAmount]`, props.formData.arrSelectedPlatform[i].onlinePlatformRemittedToHostAmount);
                    if (props.formData.arrSelectedPlatform[i].onlinePlatformOtherValue) {
                        formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformOtherValue]`, props.formData.arrSelectedPlatform[i].onlinePlatformOtherValue);
                    }
                }
    
                setIsSubmitting(true);
                fetch(url + "/TaxReturn/CreateTaxReturn", {
                    method: 'POST',
                    body: formData
                })
                .then(response => response.json())
                .then(myJson => {
                    if (typeof myJson.message !== "undefined") {
                        setSaveError(myJson.message);
                        setIsSubmitting(false);
                    } else {
                        props.setFormData({
                            ...props.formData,
                            taxReturnId: myJson.taxReturn.taxReturnId
                        });
    
                        // make as reviewed automatically if the one who fill out tax return is an admin
                        if (AuthService.isAuthorized() && !myJson.taxReturn.hasBeenReviewed) {
                            handleMarkAsReviewed(myJson.taxReturn.taxReturnId);
                        }
                        // props.nextStep();
                        handleUploadAttachments(e, myJson.taxReturn.taxReturnId);
                    }
                })
                .catch(error => {
                    setSaveError(error);
                    setIsSubmitting(false);
                });
            }
        }
    }

    // Effective Date stuff =======================
    const getLatestDateOfFee = () => {
        const endpointUrl = url + `/TaxReturn/GetLatestDateOfFee/${props.formData.taxReturnId}`;
        return sendPublicRequest(endpointUrl, "GET", '')
            .then(res => setLatestDateOfFee(new Date(res.latestDateOfFee.split("T")[0].replace(/-/g, "/"))));
    }

    // Attachments stuff ==========================
    const handleUploadAttachments = (e, taxReturnId) => {
        setErrors([]);
        setIsUploading(true);

        if (selectedFiles.length > 0) {
            let formData = new FormData();

            selectedFiles.map((s, i) => {
                formData.append(`Types[][${i}][SourceTableId]`, taxReturnId)
                formData.append(`Types[][${i}][AttachmentTypeId]`, s.attachmentTypeId)
                formData.append(`Types[][${i}][FileName]`, s.fileName)
                formData.append(`Types[][${i}][File]`, s.file)
            });

            const endpointUrl = url + `/TaxReturn/Attachments`;
            return fetch(endpointUrl, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + getAdalToken()
                },
                body: formData
            })
                .then(res => {
                    setIsUploading(false);
                    if (!res.ok) {
                        throw Error(`${res.status} ${res.statusText}`);
                    }
                    else {
                        handleClearSelectedFiles();
                        //D4647
                        //handleAddPayment(taxReturnId);
                        onSavingPayment(taxReturnId);
                    }
                })
                .catch(err => {
                    setErrors(["uploadAttachments"])
                });
        }
        else {
            //D4647
            //handleAddPayment(taxReturnId);
            onSavingPayment(taxReturnId);
        }
    }

    const onSelectedFilesChanged = (e) => {
        // debugger;
        // var fileArr = [];
        // e.value.map((v, i) => {
        //     var file = v;
        //     let t = {
        //         attachmentTypeId: null,
        //         fileName: file.name,
        //         file: file
        //     }
        //     fileArr.push(t);
        // });
        // setSelectedFiles(fileArr);
        if (e.value.length !== 0) {
            var file = e.value.slice(-1)[0];
            let newFile = {
                attachmentTypeId: null,
                fileName: file.name,
                file: file
            }
            let tempSelectedFiles = [...selectedFiles];
            tempSelectedFiles.push(newFile);
            setSelectedFiles(tempSelectedFiles);
        }
    }

    // const handleDeleteAttachment = (e) => {
    //     listRef.current.instance.deleteItem(e.itemIndex);
    // }

    const handleDocTypeChange = (value, i) => {
        let tempSelectedFiles = [...selectedFiles];
        tempSelectedFiles[i].attachmentType = value;
        tempSelectedFiles[i].attachmentTypeId = value.attachmentTypeId;
        setSelectedFiles(tempSelectedFiles);
    }

    const handleDownloadAttachment = (e) => {
        const endpointUrl = url + `/Registration/GetAttachmentByAttachmentId?attachmentId=${e.row.data.attachmentId}`;
        window.open(endpointUrl);
    }

    const handleClearSelectedFiles = () => {
        setSelectedFiles([]);
        uploaderRef.current.instance.reset();
    }

    const ListItem = (file, index) => {
        return <div className="d-flex justify-content-between align-items-center" >
            <p>{file.fileName}</p>
            <SelectBox
                // colSpan={2}
                id="nbSelectAttachmentType"
                dataField="attachmentTypeId"
                editorType="dxSelectBox"
                label="Document type"
                labelMode='static'
                dataSource={attachmentTypes}
                displayExpr="description"
                value={file.attachmentType}
                onValueChange={(val) => handleDocTypeChange(val, index)}
                onValidated={(e) => {scrollToElment("nbSelectAttachmentType")}}>
                <Validator>
                    <RequiredRule message="Choose a type"></RequiredRule>
                </Validator>
            </SelectBox>
        </div>
    }

    const onItemDeleting = (e) => {
        // delete the deleted item from selectedFiles
        if (e.itemIndex > -1) {
            let tempSelectedFiles = [...selectedFiles];
            tempSelectedFiles.splice(e.itemIndex, 1);
            setSelectedFiles(tempSelectedFiles);
        }
    }

    // Payments stuff ==========================
    const handleAddPayment = (taxReturnId) => {
        // debugger;
        paymentInfo.recordCreatedDate = new Date();
        paymentInfo.recordCreatedUser = user.employeeId;
        paymentInfo.taxReturnId = taxReturnId;

        const endpointUrl = url + `/TaxReturn/Payments/${taxReturnId}`;
        return sendRequest(endpointUrl, "POST", JSON.stringify(paymentInfo)).then(res => {
            props.nextStep();
        });
    }

    // D4647
    const onSavingPayment = (taxReturnId) => {
        //debugger;
        //e.changes[0].data.paidDate = effectiveDate;
        saveAmounts(taxReturnId).then(res => handleAddPayment(taxReturnId)); // if saveAmounts() is called inside handleAddPayment, the fees obtained by refreshing frontend is not used somehow
    }

    const saveAmounts = (taxReturnId) => {
        const endpointUrl = url + `/TaxReturn/SaveAmounts/${taxReturnId}
                                                                /${user.employeeId}
                                                                /${props.formData.yearQuarter.yearQuarterId}
                                                                /${props.formData.totalPaymentAmt}
                                                                /${props.formData.latePaymentPenaltyAmt}
                                                                /${props.formData.interestAmt}
                                                                /${effectiveDate.toJSON()}`;
        return sendRequest(endpointUrl, "POST", '');
    }
    // D4647

    const onChangeFirstName = (e) => {
        setPaymentInfo({...paymentInfo, payerFirstName: e});

        var index = errors.indexOf("firstName");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangeLastName = (e) => {
        setPaymentInfo({...paymentInfo, payerLastName: e});

        var index = errors.indexOf("lastName");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangeAddress = (e) => {
        setPaymentInfo({...paymentInfo, payerAddress: e});

        var index = errors.indexOf("address");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangeCity = (e) => {
        setPaymentInfo({...paymentInfo, payerCity: e});

        var index = errors.indexOf("city");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangeState = (e) => {
        setPaymentInfo({...paymentInfo, payerState: e});

        var index = errors.indexOf("state");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangeZipCode = (e) => {
        setPaymentInfo({...paymentInfo, payerZipCode: e});

        var index = errors.indexOf("zipCode");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangePhoneNumber = (e) => {
        setPaymentInfo({...paymentInfo, payerPhoneNumber: e});
    }
    const onChangeEmail = (e) => {
        setPaymentInfo({...paymentInfo, payerEmailAddress: e});
    }
    // const onChangePaidDate = (e) => {
    //     debugger;
    //     setPaymentInfo({...paymentInfo, paidDate: new Date(e)});

    //     var index = errors.indexOf("paidDate");
    //     if (index > -1) {
    //         errors.splice(index, 1);
    //         setErrors([...errors]);
    //     }
    // }
    const onChangeReceiptDate = (e) => {
        setPaymentInfo({ ...paymentInfo, receiptDate: new Date(e)});

        var index = errors.indexOf("receiptDate");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangePaymentType = (e) => {
        setPaymentInfo({...paymentInfo, paymentTypeId: e});

        var index = errors.indexOf("paymentType");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangePaymentTender = (e) => {
        setPaymentInfo({...paymentInfo, paymentTenderId: e});

        var index = errors.indexOf("paymentTender");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangeCheckNumber = (e) => {
        setPaymentInfo({...paymentInfo, checkNumber: e});
    }
    const onChangePaymentAmount = (e) => {
        setPaymentInfo({...paymentInfo, paymentAmount: e});

        var index = errors.indexOf("paymentAmount");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }
    const onChangeSourceNumber = (e) => {
        setPaymentInfo({...paymentInfo, sourceConfirmationNumber: e});
    }
    const onChangeAccountingYearQuarter = (e) => {
        setPaymentInfo({...paymentInfo, accountingYearQuarterId: e});

        var index = errors.indexOf("accountingYearQuarter");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }

    const onChangeNumberOfNights = (e) => {
        props.onChangeNumberOfNights(e);

        var index = errors.indexOf("numberOfNights");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }

        //It's possible that totalReceiptsAmt may be displayed
        var index = errors.indexOf("totalReceiptsAmt");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }

    const onChangeTotalReceiptsAmt = (e) => {
        props.onChangeTotalReceiptsAmt(e);

        var index = errors.indexOf("totalReceiptsAmt");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }

    const onValueChangedYearQuarter = (e) => {
        props.onValueChangedYearQuarter(e);

        var index = errors.indexOf("yearQuarter");
        if (index > -1) {
            errors.splice(index, 1);
            setErrors([...errors]);
        }
    }

    const onChangeEffectiveDate = (e) => {
        var newDate = new Date(e.value);
        setEffectiveDate(newDate);
        setPaymentInfo({...paymentInfo, paidDate: newDate});
    }

    return (
        <div>
            {!props.isLoading ? (
                <Container fluid>
                    <br /><br />
                    <h2 className='step-title'><b>Transient Occupancy Tax Payment Form for Admin</b> </h2>

                    <hr className="separateLine" />
                    <br />
                    <Form>
                        {/*Rental Property*/}
                        <Row>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>Owner</b></Form.Label>
                                <div>{props.propertyData.ownerName}</div>
                            </Form.Group>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>Cert</b></Form.Label>
                                <div>{props.propertyData.certNumber}</div>
                            </Form.Group>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label">
                                    <b>Due By</b>
                                </Form.Label>
                                <div>
                                    {props.formData.yearQuarter.yearQuarterId !== 0 ?
                                        formatDate(props.formData.yearQuarter.dueDate)
                                    : ""
                                }
                                </div>
                            </Form.Group>
                        </Row>
                        <br />
                        <Row>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>Address</b></Form.Label>
                                <div>{props.propertyData.mailingAddress}</div>
                                {
                                    props.propertyData.mailingAddress2 &&
                                    <div>{props.propertyData.mailingAddress2}</div>
                                }
                                <div>{props.propertyData.mailingCity + " " + props.propertyData.mailingState + ", " + props.propertyData.mailingZip}</div>
                            </Form.Group>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>APN</b></Form.Label>
                                <div>{formatAPN(props.propertyData.apn)}</div>
                            </Form.Group>

                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>Reporting Period</b></Form.Label>
                                {
                                    props.formData.taxReturnId===0?
                                        <SelectBox
                                            id="sbYearQuarter"
                                            items={props.listReportingYearQuarter}
                                            //displayExpr={function (item) { return item.year + ' ' + item.thruDescription}}
                                            displayExpr="displayDesc"
                                            valueExpr="yearQuarterId"
                                            defaultValue={props.formData.yearQuarter.yearQuarterId}
                                            onValueChanged={(e) => onValueChangedYearQuarter(e)}
                                            tabIndex={1}
                                            validationStatus={hasError("yearQuarter") ? "invalid" : "valid"}
                                            validationError={hasError("yearQuarter") ? { message: 'Please select a quarter.' } : ""}
                                            validationMessageMode={"always"}
                                        />
                                        :<div>{props.formData.yearQuarter.displayDesc}</div>
                                }
                               
                            </Form.Group>
                        </Row>

                        <br />
                        <hr className="separateLine" />
                        <br />

                        {/*Total Receipt*/}
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <span>
                                            <b>1. Total Receipts From Room Rentals:&nbsp;</b>
                                        </span>
                                        {/* <br />
                                        <span style={{fontSize:"16px"}}>
                                            Receipts for the occupancy of all rooms including receipts through online platforms.
                                            <u>(Receipts include room rate and cleaning fees as the taxable amounts only; host fees not applicable for calculations)</u>
                                        </span> */}
                                    </Col>
                                    <Col style={{paddingLeft:"40px"}}>
                                        <InputGroup >
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <NumberBox
                                                id="nbTotalReceiptAmt"
                                                style={{ width: "75%" }}
                                                step={0}
                                                format={amountFormat}
                                                defaultValue={props.formData.totalReceiptsAmt}
                                                onContentReady={(e) => {
                                                    e.component.focus();
                                                    e.element.classList.add("dx-state-focused");
                                                }}
                                                onValueChange={(e) => {
                                                    setIsCalculated(false);
                                                    /*props.onChangeTotalReceiptsAmt(e);*/
                                                    onChangeTotalReceiptsAmt(e);
                                                }}
                                                validationStatus={hasError("totalReceiptsAmt") ? "invalid" : "valid"}
                                                validationError={hasError("totalReceiptsAmt") ? { message: 'Please enter a valid value' } : ""}
                                                validationMessageMode={"always"}
                                        />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col><b>Number of nights:</b></Col>
                                    <Col>
                                        <NumberBox
                                            id="nbNumberOfNights"
                                            style={{width:"50%"}}
                                            step={0}
                                            format="0,###"
                                            min={0} 
                                            defaultValue={props.formData.numberOfNights}
                                            onValueChange={(e) => {
                                                setIsCalculated(false); 
                                                /*props.onChangeNumberOfNights(e);*/
                                                onChangeNumberOfNights(e);
                                            }}
                                            validationStatus={hasError("numberOfNights") ? "invalid" : "valid"}
                                            validationError={hasError("numberOfNights") ? { message: 'Please enter Number of nights' } : ""}
                                            validationMessageMode={"always"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <br />

                        {/*Exemptions*/}
                        <Row>
                            <span>
                                <b>2. Exemptions/Exclusions:&nbsp;</b>
                                {/* <span style={{fontSize:"16px"}}>
                                    Appropriate documentation must be included
                                </span> */}
                            </span>
                            <Col>
                                <Row>
                                    <Col style={{fontSize:"16px", paddingLeft:"40px"}}>
                                        a. Amount collected for rooms occupied longer than 30 Days continuously<br />
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text>a.</InputGroup.Text>
                                            <NumberBox
                                                style={{ width: "75%" }}
                                                step={0}
                                                format={amountFormat}
                                                defaultValue={props.formData.exemptLonger30DaysAmt}
                                                onValueChange={(e) => {
                                                    setIsCalculated(false); 
                                                    props.onChangeExemptLonger30DaysAmt(e);
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Col style={{fontSize:"16px"}}>
                                        b. Amount collected for rooms occupied by government officials or employees on official business
                                        {/* ; exempt certificate is required, must be attached to the Quarterly Tax Return and is available online at www.mytaxcollector.com */}
                                    </Col>
                                    <Col style={{fontSize:"16px"}}>
                                        <InputGroup>
                                            <InputGroup.Text>b.</InputGroup.Text>
                                            <NumberBox
                                                style={{ width: "75%" }}
                                                step={0}
                                                format={amountFormat}
                                                defaultValue={props.formData.exemptGovOffBusAmt}
                                                onValueChange={(e) => {
                                                    setIsCalculated(false); 
                                                    props.onChangeExemptGovOffBusAmt(e);
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <br />
                        <hr className="separateLine" />
                        <br />

                        {/*Online Platform*/}
                        <Row>
                            <Col>
                                <span>
                                    <b>5. Tax collected and remitted through Online Platform:&nbsp;</b>
                                </span>
                                {/* <span>
                                    <u>(must include host page with gross receipts as stated above and must state the amount of any taxes remitted on your behalf)</u>
                                    <br />*It is your responsibility to remit payment for rentals occurring on all rental platforms such as, but not limited to: HomeAway, VRBO, Expedia, and if no collection or remittance was done by Airbnb.
                                </span> */}
                                <Row>
                                    <Col style={{paddingLeft:"50px", paddingRight:"50px"}}>
                                        <Row>
                                            {props.onlinePlatForm.map((platform, i) =>
                                                <Form.Group as={Col} md="4" lg="3" style={{ marginTop: "5px"}} key={i}>
                                                    <CheckBox
                                                        defaultValue={props.formData.arrSelectedPlatform.filter(a => a.onlinePlatformId === platform.onlinePlatformId).length > 0}
                                                        id={platform.onlinePlatformId}
                                                        text={platform.description}
                                                        onValueChanged={(e) => {
                                                            setIsCalculated(false); 
                                                            props.onValueChangedPlatForm(e);
                                                        }}
                                                        elementAttr={ {
                                                            remittedToCounty: platform.remittedToCounty,
                                                            remittedToHost: platform.remittedToHost
                                                            }}
                                                    />
                                                </Form.Group>
                                            )}
                                        </Row>
                                    </Col>
                                    <Col>
                                        {props.formData.arrSelectedPlatform.length > 0 &&
                                        <Table bordered >
                                            <thead>
                                                <tr>
                                                    <th>Online Platform</th>
                                                    <th>Remitted to County Amount</th>
                                                    <th>Remitted to Host Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.formData.arrSelectedPlatform.map((a, i) =>

                                                    <tr key={a.onlinePlatformId}>
                                                        <td>
                                                            {a.description}{a.onlinePlatformOtherValue === undefined ? "" : ":"}&nbsp;
                                                            {a.onlinePlatformOtherValue === undefined ? "" :
                                                                <TextBox
                                                                        style={{ float: "right" }}
                                                                        defaultValue={a.onlinePlatformOtherValue}
                                                                        onValueChange={(e) => {
                                                                            setIsCalculated(false); 
                                                                            props.onChangePlatformOtherValue(e, a.onlinePlatformId);
                                                                        }}
                                                                        maxLength={50}
                                                                    />
                                                                
                                                            }	
                                                        </td>
                                                        <td>
                                                            {a.remittedToCounty&&
                                                            <InputGroup >
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <NumberBox
                                                                    style={{ width: "78%" }}
                                                                    step={0}
                                                                    format={amountFormat}
                                                                    defaultValue={a.onlinePlatformRemittedToCountyAmount}
                                                                    onValueChange={(e) => {
                                                                        setIsCalculated(false); 
                                                                        props.onChangeOnlinePlatformRemittedToCountyAmt(e, a.onlinePlatformId);
                                                                    }}
                                                                />
                                                                </InputGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {a.remittedToHost&&
                                                            <InputGroup >
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <NumberBox
                                                                    style={{ width: "78%" }}
                                                                    step={0}
                                                                    format={amountFormat}
                                                                    defaultValue={a.onlinePlatformRemittedToHostAmount}
                                                                    onValueChange={(e) => {
                                                                        setIsCalculated(false); 
                                                                        props.onChangeOnlinePlatformRemittedToHostAmt(e, a.onlinePlatformId);
                                                                    }}
                                                                />
                                                                </InputGroup>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>                               

                        <br />
                        <hr className="separateLine" />      

                        <div className="row align-items-center justify-content-end" style={{textAlign:"right", margin:"20px 0px", padding:"0px"}}>
                            <div className="col-md-auto" style={{ fontWeight: "bold", color: "#616161" }}> Effective Date </div>
                            <div className="col-2">
                                <EffectiveDate 
                                    latestDateOfFee={latestDateOfFee} 
                                    effectiveDate={effectiveDate} 
                                    setEffectiveDate={setEffectiveDate}
                                    readOnly={false}
                                    onChangeEffectiveDate={onChangeEffectiveDate}/>
                            </div>
                            <div className="col-md-auto" style={{ padding: "0px" }}>
                                <Button className="button-calculate" style={{ margin:"0px" }} onClick={(e) => onClickCalculate(e)}>Calculate</Button>                                     
                            </div>
                        </div>

                        {/* Calculated info */}
                        {/*Taxable Rents*/}
                        <Row>
                            <Col md={10} lg={10}>
                                <span>
                                    <b>3. Taxable Rents:&nbsp;</b>
                                </span>
                                <span style={{fontSize: "16px"}}>Line 1 minus Line 2.</span>
                            </Col>
                            <Col>
                                <div style={{ textAlign: "right" }}>{formatCurrency(props.formData.taxableRentsAmt)}</div>
                            </Col>
                        </Row>
                        <br />
                        {/*Tax Due*/}
                        <Row>
                            <Col md={9} lg={9}>
                                <span>
                                    <b>4. Tax Due:&nbsp;</b>
                                </span>
                                <span style={{fontSize: "16px"}}>Line 3 x {(props.formData.yearQuarter.taxRate * 100).toFixed(2).replace(/[.,]00$/, "")}%</span>
                            </Col>
                            <Col md={3} lg={3}>
                                <div style={{ textAlign: "right" }}>{formatCurrency(props.formData.taxDueAmt)}</div>
                            </Col>
                        </Row>

                        {/* <br />
                        <hr className="separateLine" /> */}
                        <br />

                        {/*Total payable by Operator*/}
                        <Row>
                            <Col md={9} lg={9}>
                                <span>
                                    <b>6. Total payable by Operator:&nbsp;</b>
                                </span>
                                <span style={{fontSize: "16px"}}>Line 4 minus Line 5</span>
                            </Col>
                            <Col>
                                <div style={{ textAlign: "right" }}>{formatCurrency(props.formData.taxPayableAmt)}</div>
                            </Col>
                        </Row>
                        <br />
                        {/*Penalty for Late Payment*/}
                        <Row>
                            <Col md={10} lg={10}>
                                <span>
                                    <b>7. Penalty for Late Payment:&nbsp;</b>
                                </span>
                                {/* <br />
                                <span >
                                    A 10% penalty is assessed on payments received/postmarked after Delinquent Date; A second 10% penalty is added one month after the Delinquent Date based on amount in line 6.
                                </span> */}
                            </Col>
                            <Col>
                                <div style={{ textAlign: "right" }}>{formatCurrency(props.formData.latePaymentPenaltyAmt)}</div>
                            </Col>
                        </Row>
                        <br />
                        {/*Interest*/}
                        <Row>
                            <Col md={9} lg={9}>
                                <span>
                                    <b>8. Interest:&nbsp;</b>
                                </span>
                                {/* <br />
                                <span >
                                    Interest of 0.5% per month (or portion of) is assessed on the amount of tax due from the date it became delinquent until paid based on amount in line 6
                                </span> */}
                            </Col>
                            <Col md={3} lg={3}>
                                <div style={{ textAlign: "right" }}>{formatCurrency(props.formData.interestAmt)}</div>
                            </Col>
                        </Row>
                        <br />
                        {/*Credit or Charge from Previous Return*/}
                        <Row>
                            <Col md={9} lg={9}>
                                <span>
                                    <b>9. Credit or Charge from Previous Return:&nbsp;</b>
                                </span>
                            </Col>
                            <Col md={3} lg={3}>
                                <div style={{ textAlign: "right" }}>{formatCurrency(props.formData.previousReturnCreditChargeAmt)}</div>
                            </Col>
                        </Row>
                        <br />
                        {/*Total Payment*/}
                        <Row>
                            <Col md={9} lg={9}>
                                <span>
                                    <b>10. Total Payment:&nbsp;</b>
                                </span>
                                <span style={{fontSize: "16px"}}>Add lines 6 through 9.</span>
                            </Col>
                            <Col md={3} lg={3}>
                                <div style={{ textAlign: "right" }}>{formatCurrency(props.formData.totalPaymentAmt)}</div>
                            </Col>
                        </Row>

                        <br />
                        <hr className="separateLine" />
                        <br />

                        {/* Attachments */}
                        <Row>
                            <Col>   
                                <span>
                                    <b>Attachments: </b>
                                </span>
                                <div className="uploadContainerTaxReturn">
                                    {attachments && attachments.length > 0 &&
                                        <DataGrid
                                            key="attachmentId"
                                            dataSource={attachments}
                                            showRowLines={true}
                                            rowAlternationEnabled={true}>
                                            <HeaderFilter visible={true} />
                                            <Column dataField="fileName"></Column>
                                            <Column dataField="attachmentDate" dataType="date"></Column>
                                            <Column dataField="attachmentTypeDescription" caption="Attachment Type"></Column>
                                            <Column type="buttons" caption="Download">
                                                <GridButton icon="download" onClick={handleDownloadAttachment} hint="Click to download" />
                                                {/* <GridButton icon="close" 
                                                    onClick={(e) => {
                                                        props.onClickDelete(e, e.row.data.attachmentId, '');
                                                        refreshAttachments();
                                                    }} 
                                                    hint="Click to delete" /> */}
                                            </Column>
                                        </DataGrid>
                                    } 
                                    <FileUploader
                                        id="nbUploadAttachment"
                                        ref={uploaderRef}
                                        selectButtonText="Click to Select Files"
                                        labelText="Or Drop Files Here"
                                        multiple={true}
                                        uploadMode={'useButtons'}//{'useForm'}
                                        accept={"*"}
                                        allowedFileExtensions={['.pdf', '.jpg', '.png', '.docx', '.xlsx']}
                                        onValueChanged={onSelectedFilesChanged}
                                        showFileList={false}
                                        validationStatus={hasError("OnlinePlatformFile") ? "invalid" : "valid"}
                                        validationError={hasError("OnlinePlatformFile") ? { message: 'Please upload supporting document(s).' } : ""}
                                        validationMessageMode={"always"}
                                        ></FileUploader>
                                  
                                    {selectedFiles.length > 0 && !isUploading && <> 
                                        <List
                                            // ref={listRef}
                                            className="attachmentDocType"
                                            dataSource={selectedFiles}
                                            noDataText=""
                                            itemRender={ListItem}
                                            allowItemDeleting={true}
                                            onItemDeleting={onItemDeleting}
                                            // onItemDeleted={handleDeleteAttachment}
                                            >
                                        </List>
                                        {/* <div className='m-2 p-2 d-flex justify-content-end gap-2'>
                                            <Button className='btn btn-sm' disabled={uploadDisabled} onClick={handleClearSelectedFiles}>Clear</Button>
                                            <Button type="success" useSubmitBehavior={true} className="btn btn-sm" variant="primary" disabled={uploadDisabled}  >Upload</Button>
                                        </div> */}
                                        </>
                                    }
                                    {isUploading &&
                                        <div className="d-flex justify-content-center"><LoadIndicator></LoadIndicator></div>
                                    }
                                </div>
                            </Col>
                        </Row> 

                        <br />
                        <hr className="separateLine" />
                        <br />

                        {/* Payments */}
                        <Row>
                            <Col>   
                                <span>
                                    <b>Payment: </b>
                                </span>
                                <br />
                                <CheckBox 
                                    style={{fontSize: "16px", margin: "10px 0 10px 0", color:"#616161"}}
                                    text="Add a Payment"
                                    iconSize={"30px"}
                                    onValueChanged={(e) => {setIsAddingPayment(e.value)}}
                                />
                                <br /><br />
                                { isAddingPayment && <Form>
                                    <div className="border" style={{padding:"40px"}}>
                                        <Row>
                                            <SectionHeader label="Payer Information"/>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Text 
                                                            label="First Name" 
                                                            input={<TextBox
                                                                maxLength={25}
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeFirstName(e);
                                                                }}
                                                                validationStatus={hasError("firstName") ? "invalid" : "valid"}
                                                                validationError={hasError("firstName") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text 
                                                            label="Last Name" 
                                                            input={<TextBox
                                                                maxLength={50}
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeLastName(e);
                                                                }}
                                                                validationStatus={hasError("lastName") ? "invalid" : "valid"}
                                                                validationError={hasError("lastName") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Text 
                                                            label="Address" 
                                                            input={<TextBox
                                                                maxLength={255}
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeAddress(e);
                                                                }}
                                                                validationStatus={hasError("address") ? "invalid" : "valid"}
                                                                validationError={hasError("address") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text 
                                                            label="City" 
                                                            input={<TextBox
                                                                maxLength={75}
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeCity(e);
                                                                }}
                                                                validationStatus={hasError("city") ? "invalid" : "valid"}
                                                                validationError={hasError("city") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Text 
                                                            label="State" 
                                                            input={<SelectBox
                                                                dataSource={paymentLookups.states}
                                                                valueExpr="abbreviation"
                                                                displayExpr="abbreviation"
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeState(e);
                                                                }}
                                                                validationStatus={hasError("state") ? "invalid" : "valid"}
                                                                validationError={hasError("state") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                                searchEnabled={true}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text 
                                                            label="Zip Code" 
                                                            input={<TextBox
                                                                mask="00000"
                                                                maskChar=" "
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeZipCode(e);
                                                                }}
                                                                validationStatus={hasError("zipCode") ? "invalid" : "valid"}
                                                                validationError={hasError("zipCode") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Text 
                                                            label="Phone Number" 
                                                            input={<TextBox
                                                                    mask="+1 (000) 000-0000"
                                                                    style={{width:"200%"}}
                                                                    step={0}
                                                                    onValueChange={(e) => {
                                                                        onChangePhoneNumber(e);
                                                                    }}/>} 
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text 
                                                            label="Email Address" 
                                                            input={<TextBox
                                                                    style={{width:"200%"}}
                                                                    step={0}
                                                                    onValueChange={(e) => {
                                                                        onChangeEmail(e);
                                                                    }}><Validator><EmailRule /></Validator></TextBox>} 
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br /><br />
                                        <Row>
                                            <SectionHeader label="Payment Information"/>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <Text 
                                                            label="Paid Date" 
                                                            hint="Changed by changing Effective Date above"
                                                            input={<DateBox
                                                                defaultValue={effectiveDate}
                                                                value={effectiveDate}
                                                                placeholder="00/00/0000"
                                                                useMaskBehavior={true}
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                disabled={true}
                                                                // onValueChange={(e) => {
                                                                //     onChangePaidDate(e);
                                                                // }}
                                                                // validationStatus={hasError("paidDate") ? "invalid" : "valid"}
                                                                // validationError={hasError("paidDate") ? { message: 'Please enter a valid value' } : ""}
                                                                // validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text 
                                                            label="Receipt Date" 
                                                            input={<DateBox
                                                                defaultValue={new Date().getTime()}
                                                                placeholder="00/00/0000"
                                                                useMaskBehavior={true}
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeReceiptDate(e);
                                                                }}
                                                                validationStatus={hasError("receiptDate") ? "invalid" : "valid"}
                                                                validationError={hasError("receiptDate") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Text 
                                                            label="Payment Type" 
                                                            input={<SelectBox
                                                                dataSource={paymentLookups.paymentType}
                                                                valueExpr="paymentTypeId"
                                                                displayExpr="description"
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangePaymentType(e);
                                                                }}
                                                                validationStatus={hasError("paymentType") ? "invalid" : "valid"}
                                                                validationError={hasError("paymentType") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                                searchEnabled={true}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text 
                                                            label="Payment Tender" 
                                                            input={<SelectBox
                                                                dataSource={paymentLookups.paymentTender}
                                                                valueExpr="paymentTenderId"
                                                                displayExpr="description"
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangePaymentTender(e);
                                                                }}
                                                                validationStatus={hasError("paymentTender") ? "invalid" : "valid"}
                                                                validationError={hasError("paymentTender") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                                searchEnabled={true}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Text 
                                                            label="Check Number" 
                                                            input={<TextBox
                                                                    maxLength={10}
                                                                    style={{width:"200%"}}
                                                                    step={0}
                                                                    onValueChange={(e) => {
                                                                        onChangeCheckNumber(e);
                                                                    }}/>} 
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Text 
                                                            label="Payment Amount" 
                                                            input={<NumberBox
                                                                style={{ width: "200%" }}
                                                                format={"$" + amountFormat}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangePaymentAmount(e);
                                                                }}
                                                                validationStatus={hasError("paymentAmount") ? "invalid" : "valid"}
                                                                validationError={hasError("paymentAmount") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                     <Col>
                                                        <Text 
                                                            label="Source Confirmation Number" 
                                                            input={<TextBox
                                                                    maxLength={50}
                                                                    style={{width:"200%"}}
                                                                    step={0}
                                                                    onValueChange={(e) => {
                                                                        onChangeSourceNumber(e);
                                                                    }}/>} 
                                                        />
                                                     </Col>    
                                                     <Col>
                                                        <Text 
                                                            label="Accounting Year Quarter" 
                                                            input={<SelectBox
                                                                dataSource={paymentLookups.listAccountingYearQuarter}
                                                                valueExpr="yearQuarterId"
                                                                displayExpr="displayDesc"
                                                                style={{ width: "200%" }}
                                                                step={0}
                                                                onValueChange={(e) => {
                                                                    onChangeAccountingYearQuarter(e);
                                                                }}
                                                                validationStatus={hasError("accountingYearQuarter") ? "invalid" : "valid"}
                                                                validationError={hasError("accountingYearQuarter") ? { message: 'Please enter a valid value' } : ""}
                                                                validationMessageMode={"always"}
                                                            />}
                                                            isRequired={true} 
                                                        />
                                                     </Col>   
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>}
                            </Col>
                        </Row> 

                        <br />
                        <div className="action-button-wrapper">
                            <Button className="button-back" onClick={(e) => onClickBacktoSearch(e)}>Back to Search</Button>
                            <Button className="button-save" onClick={(e) => onClickSubmit(e)} disabled={!isCalculated || isSubmitting}>
                                {isSubmitting ? "Saving..." : "Save"}
                            </Button>
                            {!isCalculated && (errors.length <= 0) && <p className={"inline-errormsg"} style={{margin:"3px"}}>Please click Calculate before saving.</p>}
                            {isAddingPayment && (errors.length > 0) && <p className={"inline-errormsg"} style={{margin:"3px"}}>Please correct error message(s) above.</p>}
                            {saveError && <p className={"inline-errormsg"} style={{margin:"3px"}}>Errors occured while saving. Please try again.</p>}
                        </div>
                    </Form>
                </Container>
            ) : <LoadPanel visible={true}></LoadPanel>}
        </div>
    );



}

export default TaxReturnFormAdmin;