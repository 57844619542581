import React, { useEffect,useState } from 'react';

import './TOTTaxReturn.scss'
import TaxReturnForm from "./TaxReturn_Form";
import TaxReturnFormAdmin from './TaxReturn_Form_Admin';
import TaxReturnReview from "./TaxReturn_Review";
import TaxReturnPayment from "./TaxReturn_Payment";
//import { useParams } from 'react-router-dom';
import { confirm } from 'devextreme/ui/dialog';
import { sendPublicRequest } from '../../_helper/util.js';
import AuthService from '../../services/AuthService';

const url = process.env.REACT_APP_API_URL + "/api";
function TOTTaxReturn(props) {
    
    const { propertyId, taxReturnId } = props//useParams();

    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [listReportingYearQuarter, setListYearQuarter] = useState();
    const [onlinePlatForm, setOnlinePlatForm] = useState([]);
    const [existingExemptionsFile, setExistingExemptionsFile] = useState([]);
    const [existingOnlinePlatformFile, setExistingOnlinePlatformFile] = useState([]);

    const [propertyData, setPropertyData] = useState();

    const [formData, setFormData] = useState({
        taxReturnId:parseInt(taxReturnId),
        propertyId: parseInt(propertyId),
        yearQuarter: {},
        numberOfNights:null,
        totalReceiptsAmt: 0,
        exemptLonger30DaysAmt: 0,
        exemptGovOffBusAmt: 0,
        exemptionsAmt: 0,
        selectedFilesExemptions: [],
        taxableRentsAmt: 0,
        taxDueAmt: null,
        taxCollectedOnlinePlatformAmt: null,
        arrSelectedPlatform: [],
        selectedFilesOnlinePlatform:[],
        taxPayableAmt: null,
        latePaymentPenaltyAmt: null,
        interestAmt: null,
        previousReturnCreditChargeAmt: null,
        totalPaymentAmt: null,
        preparedBy: null,
        title: null,
        phoneNumber: null,
        certified:false
    });

    const [errors, setErrors] = useState([]);
    const hasError = (key) => {
        return errors.indexOf(key) !== -1;
    }
    const hasAnyError = () => {
        return errors.length > 0;
    }


    useEffect(() => {
        //debugger;

        // set first step
        // if it's an admin, then take them to the one-page tax return filing form
        if (AuthService.isAuthorized()) {
            setStep(21);
        }
        else {
            setStep(1);
        }

        sendPublicRequest(url + "/TaxReturn/GetInitInfo?propertyId=" + formData.propertyId + "&taxReturnId=" + formData.taxReturnId, 'GET', '').then(myJson => {

            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                let property = myJson.property;
                setPropertyData({
                    propertyId: property.propertyId,
                    ownerName: property.ownerName,
                    certNumber: property.certNumber,
                    mailingAddress: property.mailingAddress,
                    mailingAddress2: property.mailingAddress2,
                    mailingCity: property.mailingCity,
                    mailingState: property.mailingState,
                    mailingZip: property.mailingZip,
                    apn: property.apn
                });
                setListYearQuarter(myJson.listYearQuarter);
                setOnlinePlatForm(myJson.listOnlinePlatform);

                if (formData.taxReturnId === 0) {
                    //pay new taxreturn
                    setFormData({
                        ...formData,

                        yearQuarter: myJson.defaultYearQuarter,
                    });
                }
                else {
                    //pay unpaid taxreturn

                    setExistingExemptionsFile(myJson.existingExemptionsFile);
                    setExistingOnlinePlatformFile(myJson.existingOnlinePlatformFile);

                    let tempArray = []
                    for (var i = 0; i < myJson.listTaxReturnOnlinePlatformAmounts.length; i++) {
                        let obj = {
                            "onlinePlatformId": myJson.listTaxReturnOnlinePlatformAmounts[i].onlinePlatformId,
                            "description": myJson.listTaxReturnOnlinePlatformAmounts[i].description,
                            "onlinePlatformRemittedToCountyAmount": myJson.listTaxReturnOnlinePlatformAmounts[i].onlinePlatformRemittedToCountyAmount,
                            "onlinePlatformRemittedToHostAmount": myJson.listTaxReturnOnlinePlatformAmounts[i].onlinePlatformRemittedToHostAmount,
                            "remittedToCounty": myJson.listTaxReturnOnlinePlatformAmounts[i].remittedToCounty,
                            "remittedToHost": myJson.listTaxReturnOnlinePlatformAmounts[i].remittedToHost
                        };
                        if (myJson.listTaxReturnOnlinePlatformAmounts[i].description.toLowerCase() === "other") {
                            obj.onlinePlatformOtherValue = myJson.listTaxReturnOnlinePlatformAmounts[i].onlinePlatformOtherValue;
                        }
                        tempArray.push(obj);
                    }

                    setFormData({
                        ...formData,

                        taxReturnId: myJson.taxReturn.taxReturnId,
                        propertyId: myJson.taxReturn.propertyId,
                        yearQuarter: myJson.defaultYearQuarter,
                        totalReceiptsAmt: myJson.taxReturn.totalReceiptsAmt,
                        numberOfNights: myJson.taxReturn.numberOfNights,
                        exemptLonger30DaysAmt: myJson.taxReturn.exemptLonger30DaysAmt,
                        exemptGovOffBusAmt: myJson.taxReturn.exemptGovOffBusAmt,
                        exemptionsAmt: myJson.taxReturn.exemptionsAmt,
                        taxableRentsAmt: myJson.taxReturn.taxableRentsAmt,
                        arrSelectedPlatform: [...tempArray],

                        preparedBy: myJson.taxReturn.preparedBy,
                        title: myJson.taxReturn.title,
                        phoneNumber: myJson.taxReturn.phoneNumber
                    });
                }

                setIsLoading(false);

            }
        });


    }, []);


    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        setStep(step- 1);
    }

    const goToStep3 = () => {
        setStep(3);
    }

    const onValueChangedYearQuarter = (e) => {
        //debugger;
        //let displayDesc = e.component.option("text");
        let newArray = listReportingYearQuarter.filter(function (el) { return el.yearQuarterId === e.value });

        setFormData({
            ...formData,
            yearQuarter: { yearQuarterId: e.value, displayDesc: newArray[0].displayDesc, dueDate: newArray[0].dueDate, taxRate: newArray[0].taxRate },

        });

        
        let yearQuarterToCheck = e.value;
        let index = errors.indexOf("yearQuarter");
        if (index > -1) {
            if (yearQuarterToCheck === null || yearQuarterToCheck === 0) {
                errors.push("yearQuarter");
            } else {
                errors.splice(index, 1);
            }
            
            setErrors([...errors]);
        }
        

    }
    const onChangeTotalReceiptsAmt = (e) => {
        let totalReceiptsAmt = e;
        let exemptionsAmt = formData.exemptLonger30DaysAmt + formData.exemptGovOffBusAmt;
        let taxableRentsAmt = totalReceiptsAmt - exemptionsAmt;


        setFormData({
            ...formData,
            totalReceiptsAmt: totalReceiptsAmt,
            exemptionsAmt: exemptionsAmt,
            taxableRentsAmt: taxableRentsAmt
        });

        let totalReceiptsAmtToCheck = e;
        let index = errors.indexOf("totalReceiptsAmt");
        if (index > -1) {
            if (totalReceiptsAmtToCheck === null || totalReceiptsAmtToCheck<0) {
                errors.push("totalReceiptsAmt");
            }
            else {
                errors.splice(index, 1);
            }
            setErrors([...errors]);
        }

    }
    const onChangeNumberOfNights = (e) => {
        setFormData({
            ...formData,
            numberOfNights: e
        });

        let numberOfNightsToCheck = e;
        let index = errors.indexOf("numberOfNights");
        if (index > -1) {
            if (numberOfNightsToCheck === null) {
                errors.push("numberOfNights");
            }
            else {
                errors.splice(index, 1);
            }
            setErrors([...errors]);
        }
    }
    const onChangeExemptLonger30DaysAmt = (e) => {
        let exemptLonger30DaysAmt = e;
        let exemptionsAmt = exemptLonger30DaysAmt + formData.exemptGovOffBusAmt;
        let taxableRentsAmt = formData.totalReceiptsAmt - exemptionsAmt;

        setFormData({
            ...formData,
            exemptLonger30DaysAmt: exemptLonger30DaysAmt,
            exemptionsAmt: exemptionsAmt,
            taxableRentsAmt: taxableRentsAmt
        });
    }
    const onChangeExemptGovOffBusAmt = (e) => {
        let exemptGovOffBusAmt = e;
        let exemptionsAmt = formData.exemptLonger30DaysAmt + exemptGovOffBusAmt;
        let taxableRentsAmt = formData.totalReceiptsAmt - exemptionsAmt;

        setFormData({
            ...formData,
            exemptGovOffBusAmt: exemptGovOffBusAmt,
            exemptionsAmt: exemptionsAmt,
            taxableRentsAmt: taxableRentsAmt
        });
    }
    const onselectedFilesExemptionsChanged = (e) => {
        //debugger;
        setFormData({
            ...formData,
            selectedFilesExemptions: e.value
        });
    }

    const onValueChangedPlatForm = (e) => {
        //debugger;
        let tempArray = [...formData.arrSelectedPlatform];
        if (e.value) {
            //checked
            let remittedToCounty = (e.event.currentTarget.attributes.remittedtocounty.value==='true');
            let remittedToHost = (e.event.currentTarget.attributes.remittedtohost.value==='true');

            let obj = {
                "onlinePlatformId": parseInt(e.event.currentTarget.id),
                "description": e.event.currentTarget.innerText,
                "onlinePlatformRemittedToCountyAmount": 0,
                "onlinePlatformRemittedToHostAmount": 0,
                "remittedToCounty": remittedToCounty,
                "remittedToHost": remittedToHost
            };
            if (e.event.currentTarget.innerText.toLowerCase() === "other") {
                obj.onlinePlatformOtherValue = "";
            }
            tempArray.push(obj);
        } else {
            //unchecked
            tempArray = tempArray.filter(el => el.onlinePlatformId !== parseInt(e.event.currentTarget.id));
        }

        setFormData({
            ...formData,
            arrSelectedPlatform: tempArray
        });

    }
    const onChangeOnlinePlatformRemittedToCountyAmt = (e, onlinePlatformId) => {

        let tempArray = [...formData.arrSelectedPlatform];
        for (let obj of tempArray) {
            if (obj.onlinePlatformId === onlinePlatformId) {
                obj.onlinePlatformRemittedToCountyAmount = e;
                break;
            }
        }

        setFormData({
            ...formData,
            arrSelectedPlatform: tempArray
        });
    }

    const onChangeOnlinePlatformRemittedToHostAmt = (e, onlinePlatformId) => {

        let tempArray = [...formData.arrSelectedPlatform];
        for (let obj of tempArray) {
            if (obj.onlinePlatformId === onlinePlatformId) {
                obj.onlinePlatformRemittedToHostAmount = e;
                break;
            }
        }

        setFormData({
            ...formData,
            arrSelectedPlatform: tempArray
        });
    }

    const onChangePlatformOtherValue = (e, onlinePlatformId) => {
        //debugger;
        let tempArray = [...formData.arrSelectedPlatform];
        for (let obj of tempArray) {
            if (obj.onlinePlatformId === onlinePlatformId) {
                obj.onlinePlatformOtherValue = e;
                break;
            }
        }

        setFormData({
            ...formData,
            arrSelectedPlatform: tempArray
        });
    }

    const onselectedFilesOnlinePlatformChanged = (e) => {
        //debugger;
        setFormData({
            ...formData,
            selectedFilesOnlinePlatform: e.value
        });

        let selectedFilesOnlinePlatformToCheck = e.value;
        let index = errors.indexOf("OnlinePlatformFile");
        if (index > -1) {
            if (selectedFilesOnlinePlatformToCheck.length === 0 && existingOnlinePlatformFile.length===0) {
                errors.push("OnlinePlatformFile");
            }
            else {
                errors.splice(index, 1);
            }
            setErrors([...errors]);
        }
    }

    const onClickDelete = (e, attachmentId, attachmentTypeCode) => {
        // debugger;
        let result = confirm("Are you sure you want to delete this file?", "Confirm delete");
        result.then((dialogResult) => {
            if (dialogResult) {
                sendPublicRequest(url + "/TaxReturn/DeleteAttachmentsByAttachmentId?attachmentId=" + attachmentId, 'POST', '').then(myJson => {
                    //debugger;
                    if (typeof myJson.message !== "undefined") {
                        throw myJson.message;
                    }
                    else {
                        let tempArray = [];
                        if (attachmentTypeCode === "TE") {
                            tempArray = [...existingExemptionsFile];
                            tempArray = tempArray.filter(el => el.attachmentId !== attachmentId);
                            setExistingExemptionsFile(tempArray);
                        }
                        else if (attachmentTypeCode === "TT") {
                            tempArray = [...existingOnlinePlatformFile];
                            tempArray = tempArray.filter(el => el.attachmentId !== attachmentId);
                            setExistingOnlinePlatformFile(tempArray);
                        }

                    }
                });
            }
        });
    }

    const backtoSearch = () => {
        props.backtoSearch();
    }

    const onValueChangeCertified = (e) => {
        setFormData({
            ...formData,
            certified: e
        });

        let certifiedToCheck = e;
        let index = errors.indexOf("certified");
        if (index > -1) {
            if (certifiedToCheck === false) {
                errors.push("certified");
            } else {
                errors.splice(index, 1);
            }

            setErrors([...errors]);
        }
    }
    const onChangePreparedBy = (e) => {
        setFormData({
            ...formData,
            preparedBy: e//e.target.value
        });

        let preparedByToCheck = e;
        let index = errors.indexOf("preparedBy");
        if (index > -1) {
            if (preparedByToCheck === null || preparedByToCheck==="") {
                errors.push("preparedBy");
            } else {
                errors.splice(index, 1);
            }

            setErrors([...errors]);
        }
    }
    const onChangeTitle = (e) => {
        setFormData({
            ...formData,
            title: e
        });
    }
    const onChangePhoneNumber = (e) => {
        setFormData({
            ...formData,
            phoneNumber: e
        });

        let phoneNumberToCheck = e;
        let index = errors.indexOf("phoneNumber");
        if (index > -1) {
            if (phoneNumberToCheck === null ) {
                errors.push("phoneNumber");
            } else {
                if (phoneNumberToCheck.length !== 10) {
                    errors.push("phoneNumber");
                }
                else {
                    errors.splice(index, 1);
                }
            }

            setErrors([...errors]);
        }
    }

    switch (step) {
        case 1:
            return <TaxReturnForm
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                formData={formData}
                setFormData={setFormData}
                isLoading={isLoading}
                listReportingYearQuarter={listReportingYearQuarter}
                onlinePlatForm={onlinePlatForm}
                existingExemptionsFile={existingExemptionsFile}
                existingOnlinePlatformFile={existingOnlinePlatformFile}
                nextStep={nextStep}
                onValueChangedYearQuarter={onValueChangedYearQuarter}
                onChangeTotalReceiptsAmt={onChangeTotalReceiptsAmt}
                onChangeNumberOfNights={onChangeNumberOfNights}
                onChangeExemptLonger30DaysAmt={onChangeExemptLonger30DaysAmt}
                onChangeExemptGovOffBusAmt={onChangeExemptGovOffBusAmt}
                onselectedFilesExemptionsChanged={onselectedFilesExemptionsChanged}
                onValueChangedPlatForm={onValueChangedPlatForm}
                onChangeOnlinePlatformRemittedToCountyAmt={onChangeOnlinePlatformRemittedToCountyAmt}
                onChangeOnlinePlatformRemittedToHostAmt={onChangeOnlinePlatformRemittedToHostAmt}
                onChangePlatformOtherValue={onChangePlatformOtherValue}
                onselectedFilesOnlinePlatformChanged={onselectedFilesOnlinePlatformChanged}
                onClickDelete={onClickDelete}
                backtoSearch={backtoSearch}
                hasAnyError={hasAnyError}
                hasError={hasError}
                setErrors={setErrors}
            >
            </TaxReturnForm>
        case 2:
            return <TaxReturnReview
                propertyData={propertyData}
                formData={formData}
                setFormData={setFormData}
                prevStep={prevStep}
                nextStep={nextStep}
                onValueChangeCertified={onValueChangeCertified}
                onChangePreparedBy={onChangePreparedBy}
                onChangeTitle={onChangeTitle}
                onChangePhoneNumber={onChangePhoneNumber}
                hasAnyError={hasAnyError}
                hasError={hasError}
                setErrors={setErrors}
            >
            </TaxReturnReview>
        case 3:
            return <TaxReturnPayment
                propertyData={propertyData}
                formData={formData}
                prevStep={prevStep}>
            </TaxReturnPayment>
        case 21:
            return <TaxReturnFormAdmin
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                formData={formData}
                setFormData={setFormData}
                isLoading={isLoading}
                listReportingYearQuarter={listReportingYearQuarter}
                onlinePlatForm={onlinePlatForm}
                existingExemptionsFile={existingExemptionsFile}
                existingOnlinePlatformFile={existingOnlinePlatformFile}
                nextStep={goToStep3}
                onValueChangedYearQuarter={onValueChangedYearQuarter}
                onChangeTotalReceiptsAmt={onChangeTotalReceiptsAmt}
                onChangeNumberOfNights={onChangeNumberOfNights}
                onChangeExemptLonger30DaysAmt={onChangeExemptLonger30DaysAmt}
                onChangeExemptGovOffBusAmt={onChangeExemptGovOffBusAmt}
                onselectedFilesExemptionsChanged={onselectedFilesExemptionsChanged}
                onValueChangedPlatForm={onValueChangedPlatForm}
                onChangeOnlinePlatformRemittedToCountyAmt={onChangeOnlinePlatformRemittedToCountyAmt}
                onChangeOnlinePlatformRemittedToHostAmt={onChangeOnlinePlatformRemittedToHostAmt}
                onChangePlatformOtherValue={onChangePlatformOtherValue}
                onselectedFilesOnlinePlatformChanged={onselectedFilesOnlinePlatformChanged}
                onClickDelete={onClickDelete}
                backtoSearch={backtoSearch}
            ></TaxReturnFormAdmin>
        default:
            return;
    }



}

export default TOTTaxReturn;