import React, { Component } from 'react';

import TaxReturnPendingReview from '../../components/TaxReturnPendingReview/TaxReturnPendingReview'
import PageHeader from '../../components/header/PageHeader/PageHeader';

class TaxReturnPendingReviewPage extends Component {

    render() {
        return (
            <div className='content-block'>
                <PageHeader header="TOT Return Pending Review"></PageHeader>
                <TaxReturnPendingReview/>
            </div>
        );
    }
}

export default TaxReturnPendingReviewPage;
