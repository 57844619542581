import { Button } from 'devextreme-react/button';
import React from 'react';

function PopupTitle({ statusCode, status, onClick, ...props }) {
    let bg = ""
    switch (statusCode) {
        case 'PNT':
        case 'PND':
        case 'RPN':
            bg = 'bg-warning text-dark'
            break;
        case 'REJ':
            bg = 'bg-danger'
            break;
        case 'ACT':
            bg = 'bg-success'
    }

    return (
    <div className='my-1 d-flex align-items-center justify-content-between'>
        <div className=' d-flex align-items-center'>
            <span className="h4 me-3">Tax Return</span>
            <span className={`badge rounded-pill ${bg}`}>{status}</span>
        </div>
        <div>
            <Button icon="close" onClick={() => onClick()}></Button>
        </div>
    </div>
    )
}

export default PopupTitle;