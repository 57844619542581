import React from 'react';
/*import { Form, Button, Col, Container } from 'react-bootstrap';*/
import Form from "react-bootstrap/Form";
//import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
//import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import InputMask from 'react-input-mask';
import Alert from "react-bootstrap/Alert";
import RegisterCertWrapper from './registercert_wrapper';
import ReviewInfo from './registercert_reviewinfo';

class ReviewDetails extends React.Component {
    /*export default function OwnerDetails(props) {*/
    constructor() {
        super();

        this.phoneEditorOptions = {
            mask: '+1 (X00) 000-0000',
            maskRules: {
                X: /[02-9]/,
            },
            maskInvalidMessage: 'The phone must have a correct USA phone format',
        };
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        let errors = [];
        //prepared by name
        if (this.props.inputValues.preparedBy === "") {
            errors.push("preparedBy");
        }

        //prepared by phone number
        if (this.props.inputValues.preparedByPhoneNumber === "") {
            errors.push("preparedByPhoneNumber");
        }

        //certified
        if (this.props.inputValues.certified === false) {
            errors.push("certified");
        }
        
        this.props.handleHasError(errors);

        if (errors.length > 0) {
            return false;
        } else {
            this.props.nextStep();  /*All good*/
        }

        //this.props.nextStep();
    };

    hasError(key) {
        return this.props.hasError(key);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let errors = [];
        //prepared by name
        if (this.props.inputValues.preparedBy === "") {
            errors.push("preparedBy");
        }

        //prepared by phone number
        var preparedByToCheck = this.props.inputValues.preparedByPhoneNumber;

        preparedByToCheck = preparedByToCheck.replace("+1 (", "");
        preparedByToCheck = preparedByToCheck.replace(") ", "");
        preparedByToCheck = preparedByToCheck.replace("-", "");
        preparedByToCheck = preparedByToCheck.replaceAll(" ", "");

        if (preparedByToCheck.length !== 10) {
            errors.push("preparedByPhoneNumber");
        }
        //if (this.props.inputValues.preparedByPhoneNumber === "") {
        //    errors.push("preparedByPhoneNumber");
        //}

        //certified
        if (this.props.inputValues.certified === false) {
            errors.push("certified");
        }

        this.props.handleHasError(errors);

        if (errors.length > 0) {
            return false;
        } else {
            this.props.handleSubmit();  /*All good*/
        }        
    }

    render() {
        return (
            <RegisterCertWrapper subtitle="REVIEW" step="Step 4 of 4" onBackClick={this.back} onSubmit={this.handleSubmit} isSubmitting={ this.props.inputValues.isSubmitting}>

                <h5>**Confirm the following details are correct**</h5>

                <ReviewInfo info={this.props.inputValues}></ReviewInfo>
                {/* <h5><u>Owner Information</u></h5>
                <div style={{marginLeft: "20px"}}>
                    <p><b>Owner Name</b>: {this.props.inputValues.ownerName}<br />
                        <b>Owner Phone Number</b>: {this.props.inputValues.ownerPhoneNumber}<br />
                        <b>Email Address</b>: {this.props.inputValues.email}<br />
                        <b>Business / Management Co.Name</b >: {this.props.inputValues.busMgmtCoName}</p>
                </div> 
                <h5><u>Rental Property Information</u></h5>
                <div style={{ marginLeft: "20px" }}>
                    <p><b>Address</b>: {this.props.inputValues.rentalPropAddress}&nbsp;<b>Address 2</b>: {this.props.inputValues.rentalPropAddress2}&nbsp;<br />
                        <b>City</b>: {this.props.inputValues.rentalPropCity}&nbsp;<b>State</b>: {this.props.inputValues.rentalPropState}&nbsp;<b>Zip Code</b>: {this.props.inputValues.rentalPropZip}<br />
                        <b>Assessor Parcel Number</b>: {this.props.inputValues.apn}<br />
                        <b># of Occupancy Units</b >: {this.props.inputValues.occUnitsNo}</p>
                </div>
                <h5><u>Mailing Address Information</u></h5>
                <div style={{ marginLeft: "20px" }}>
                    <p><b>Address</b>: {this.props.inputValues.mailingAddress}&nbsp;<b>Address 2</b>: {this.props.inputValues.mailingAddress2}&nbsp;<br />
                        <b>City</b>: {this.props.inputValues.mailingCity}&nbsp;<b>State</b>: {this.props.inputValues.mailingState}&nbsp;<b>Zip Code</b>: {this.props.inputValues.mailingZip}</p>
                </div>
                <h5><u>Business Information</u></h5>
                <div style={{ marginLeft: "20px" }}>
                    <p><b>How long have you owned/operated this business</b>: {this.props.inputValues.ownedBusLength}<br />
                        <b>Type of Rental</b>: {this.props.inputValues.typeOfRental}&nbsp;<b>Other</b>: {this.props.inputValues.typeOfRentalOther}</p>
                </div> */}

                {/*<hr style={{ borderTop: "2px solid rgba(0,45,115,.5)"}} />*/}
                <div className="border rounded" style={{padding: "20px"}}>
                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" lg="12" controlId="formCertify">
                                <Form.Check name="certified"
                                    className={
                                        this.hasError("certified")
                                            ? "is-invalid"
                                            : ""
                                    }
                                    type="checkbox" label="I certify under penalty of perjury that the foregoing is true and correct to the best of my knowledge" defaultChecked={this.props.inputValues.certified} onChange={this.props.handleChange} />
                                <div className={"inline-errormsg"}>{this.hasError("certified") ? "Please check the above before submitting" : null}</div>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="6" lg="6" controlId="formPreparedBy">
                                <Form.Label className="label"><b>Prepared By</b></Form.Label>
                                <Form.Control
                                    className={
                                        this.hasError("preparedBy")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    type="text"
                                    defaultValue={this.props.inputValues.preparedBy}
                                    name="preparedBy"
                                    required
                                    onChange={this.props.handleChange}
                                    maxLength="255"
                                />
                                <div className={"inline-errormsg"}>{this.hasError("preparedBy") ? "Please enter a value" : null}</div>
                            </Form.Group>
                            <Form.Group as={Col} md="6" lg="6" controlId="formTitle">
                                <Form.Label className="label">Title</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    defaultValue={this.props.inputValues.title}
                                    name="title"
                                    onChange={this.props.handleChange}
                                    maxLength="100"
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} md="4" lg="4" controlId="formPreparedByPhoneNumber">
                                <Form.Label className="label"><b>Phone Number</b></Form.Label>
                                <InputMask
                                    className={
                                        this.hasError("preparedByPhoneNumber")
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    formatChars={{
                                        "9": "[0-9]"
                                    }}
                                    mask="+1 (999) 999-9999"
                                    maskChar=" "
                                    onChange={this.props.handlePreparedByPhoneNumberChange}
                                    value={this.props.inputValues.preparedByPhoneNumber}
                                ></InputMask>
                                <div className={"inline-errormsg"}>{this.hasError("preparedByPhoneNumber") ? "Please enter a value" : null}</div>
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                <br />
                <Alert key="danger" variant="danger" show={this.props.inputValues.saveErrorEncountered}>
                    {this.props.inputValues.saveError}
                </Alert>
                   
            </RegisterCertWrapper>
        )
    }
}

export default ReviewDetails;