export const navigation = [
    {
        text: 'Certificate Applications',
        path: '/CertAppPage',
        icon: 'favorites'
    },
    {
        text: 'Tax Return Pending Review',
        path: '/TaxReturnPendingReviewPage',
        icon: 'check'
    },
    {
        text: 'Properties/Certificates',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'Forms',
        icon: 'file',
        expanded: false,
        items: [
            {
                text: 'Certificate Registration',
                path: '/CertificateRegistrationPage'
            },
            {
                text: 'TOT Return',
                path: '/TOTReturnPage'
            }
        ]
    },
    {
        text: 'Admin',
        icon: 'key',
        expanded: false,
        items: [
            {
                text: 'Templates',
                path: '/ManageTemplates'
            },
            {
                text: 'Communities',
                path: '/ManageCommunity'
            },
            {
                text: 'Tax Rate Areas',
                path: '/ManageTRA'
            },
            {
                text: 'Type Of Rental',
                path: '/ManageTypeOfRental'
            },
            {
                text: 'Online Platform',
                path: '/ManageOnlinePlatform'
            },
            {
                text: 'Quarters',
                path: '/ManageQuarters'
            },
            {
                text: 'Users',
                path: '/ManageUsers'
            },
            {
                text: 'Fee Types',
                path: '/ManageFeeType'
            },
            {
                text: 'Overpayments',
                path: '/Overpayments'
            }
        ]
    },
    {
        text: 'Reports',
        icon: 'folder',
        expanded: false,
        items: [
            {
                text: 'Reconciliation Report',
                path: '/ReportReconciliation'
            },
            {
                text: 'Mail Merge Report',
                path: '/ReportMailMerge'
            }
        ]
    },
];
