
import DataGrid, { Column, Button as GridButton } from 'devextreme-react/data-grid'
import React, { useEffect, useState } from 'react';
import { sendRequest } from '../../_helper/util';
import CustomStore from 'devextreme/data/custom_store';
import { useHistory } from 'react-router-dom';
import { Popup } from 'devextreme-react';
import ExportPdfComponent from '../exportpdfcomponent/ExportPdfComponent';
import PageHeader from '../header/PageHeader/PageHeader';

function TemplateList(props) {
    const history = useHistory();
    const [templates, setTemplates] = useState();
    const [previewPopupVisible, setPreviewPopupVisible] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();

    useEffect(() => {
        setTemplates(new CustomStore({
            key: "templateId",
            load: getTemplateList
        }))
    }, [])

    const getTemplateList = () => {
        const endpointUrl = process.env.REACT_APP_API_URL + "/api/Template";
        return sendRequest(endpointUrl, "GET", '')
            .then(response => response);
    }

    const handleClickEdit = (event) => {
        const id = event.row.data.templateId;
        history.push(`/ManageTemplate/edit/${id}`)
    }

    //const handleClickNew = () => {
    //    //do something
    //    console.log("history")
    //    history.push(`/ManageTemplate/add`)
    //}

    const handleView = (e) => {
        setSelectedTemplateId(e.row.data.templateId)
        setSelectedTemplate(e.row.data.type)
        setPreviewPopupVisible(true);
    }

    const handlePopupClose = () => {
        setSelectedTemplateId(0)
        setPreviewPopupVisible(false);
    }

    return (
        <>
            <PageHeader header="Manage Templates" />
            {/* <Button variant="success" onClick={handleClickNew}>Create new Template</Button> */}
            <DataGrid
                dataSource={templates}
                showBorders={true}
                showRowLines={true}
                rowAlternationEnabled={true}
            >
                <Column dataField="display" caption="Type" ></Column>
                {/* <Column dataField="templateContent" ></Column> */}
                <Column dataField="modifiedBy" ></Column>
                <Column dataField="modifiedDate" dataType="date"></Column>
                <Column type="buttons">
                    <GridButton
                        cssClass={"mx-1"}
                        text="Edit"
                        hint="Edit template"
                        onClick={handleClickEdit}
                    />
                    <GridButton
                        cssClass={"mx-1"}
                        text="View"
                        hint="View Template "
                        onClick={handleView}
                    />
                </Column>
            </DataGrid>
            {previewPopupVisible && <Popup visible={previewPopupVisible} onHiding={handlePopupClose} title={`View Template - ${selectedTemplate}`}>
                <ExportPdfComponent templateId={selectedTemplateId} height="100%" width="100%"></ExportPdfComponent>
            </Popup>}
        </>

    );
}

export default TemplateList;