import React from 'react';
import { DateBox, Button as DateBoxButton } from 'devextreme-react/date-box';
import '../../pages/CertApp/CertAppPage.scss';
import PropTypes from 'prop-types';


EffectiveDate.propTypes = {
    effectiveDate: PropTypes.object.isRequired,
    setEffectiveDate: PropTypes.func.isRequired
};

function EffectiveDate(props) {
    const {effectiveDate, setEffectiveDate} = props;

    return <>
        <DateBox
            defaultValue={new Date().getTime()}
            value={effectiveDate}
            placeholder="00/00/0000"
            useMaskBehavior={true}
            type="date"
            height={props.height}
            displayFormat="shortdate"
            applyValueMode="useButtons"
            openOnFieldClick={true}
            hint="Effective Date is the Paid Date of a payment and cannot be prior to the date of the latest payment."
            stylingMode="filled"
            onValueChanged={props.onChangeEffectiveDate}
            readOnly={props.readOnly}
            min={props.latestDateOfFee.getTime()}
        >
            <DateBoxButton name="dropDown" />
        </DateBox>
    </>
        
}

export default EffectiveDate;