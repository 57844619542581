import React, {  useState} from 'react';
//import { useHistory} from 'react-router-dom';
import { Container, Form, Col, Row, InputGroup, Table, Button } from 'react-bootstrap';
import { LoadPanel, NumberBox, SelectBox, CheckBox, FileUploader, TextBox, Button as Button1 } from 'devextreme-react';


import { sendPublicRequest } from '../../_helper/util.js';
import { formatDate, formatAPN } from '../../_helper/helpers';

const url = process.env.REACT_APP_API_URL + "/api";
const amountFormat = "#,##0.00";

function TaxReturnForm(props) {

    //const history = useHistory();


    const onClickBacktoSearch = (e) => {
        //history.push("/CertNumberSearch");
        props.backtoSearch();
    }

    const scrollToElment = (id) => {
        let element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
    }

    const onClickNext = (e) => {
        // debugger;
        let errors = [];
        if (props.formData.totalReceiptsAmt===null || props.formData.totalReceiptsAmt < 0) {
            errors.push("totalReceiptsAmt");
            scrollToElment("nbTotalReceiptAmt");
        }
        if (props.formData.numberOfNights === null) { // || props.formData.numberOfNights===0) {
            errors.push("numberOfNights");
            scrollToElment("nbNumberOfNights");
        }
        if (props.formData.yearQuarter.yearQuarterId === null || props.formData.yearQuarter.yearQuarterId === 0) {
            errors.push("yearQuarter");
            scrollToElment("sbYearQuarter");
        }
        if (props.formData.totalReceiptsAmt > 0 && props.formData.numberOfNights === 0) {
            errors.push("numberOfNights");
            scrollToElment("nbNumberOfNights");
        }
        if (props.formData.totalReceiptsAmt === 0 && props.formData.numberOfNights > 0) {
            errors.push("totalReceiptsAmt");
            scrollToElment("nbTotalReceiptAmt");
        }
        //require online platform file if remitted to County amount greater than 0
        if (props.formData.selectedFilesOnlinePlatform.length === 0 && props.existingOnlinePlatformFile.length === 0) {
            let tempArray = [...props.formData.arrSelectedPlatform];
            for (let obj of tempArray) {
                if (obj.onlinePlatformRemittedToCountyAmount > 0) {
                    errors.push("OnlinePlatformFile");
                };
            }
        }
        
        if (errors.length > 0) {
            props.setErrors(errors);

            return false;
        } else {
            calculateAmounts();
        }
    }
    const calculateAmounts = () => {
        //debugger;
        
        // get tax rate from quarter instead of using 0.07
        let taxDueAmt = props.formData.taxableRentsAmt * props.formData.yearQuarter.taxRate;

        let tempArray = [...props.formData.arrSelectedPlatform];
        let taxCollectedOnlinePlatformAmt = 0;
        for (let obj of tempArray) {
            taxCollectedOnlinePlatformAmt += obj.onlinePlatformRemittedToCountyAmount;
        }

        // BUSINESS RULE: If (taxDueAmt - taxCollectedOnlinePlatformAmt) amount is less than 0, show a 0 for the Tax Payable by Operator field.
        // (This happens when the Online Platform collects more than the Operator owes.  Results in a negative amount.   But that is amount owed to 
        // Operator by the Online Platform NOT the county.)
        let taxPayableAmt = ((taxDueAmt - taxCollectedOnlinePlatformAmt) < 0 ? 0 : taxDueAmt - taxCollectedOnlinePlatformAmt);

        sendPublicRequest(url + "/TaxReturn/CalculateAmts?propertyId=" + props.formData.propertyId +
            "&taxReturnId=" + props.formData.taxReturnId +
            "&taxPayableAmt=" + taxPayableAmt +
            "&yearQuarterId=" + props.formData.yearQuarter.yearQuarterId,
            'GET', '').then(myJson => {

            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                let latePaymentPenaltyAmt = myJson.penaltyAmt;
                let interestAmt = myJson.interestAmt;
                let previousReturnCreditChargeAmt = myJson.previousReturnCreditChargeAmt;

                let totalPaymentAmt = taxPayableAmt + latePaymentPenaltyAmt + interestAmt + previousReturnCreditChargeAmt;

                props.setFormData({
                    ...props.formData,

                    taxDueAmt: taxDueAmt,
                    taxCollectedOnlinePlatformAmt: taxCollectedOnlinePlatformAmt,
                    taxPayableAmt: taxPayableAmt,
                    latePaymentPenaltyAmt: latePaymentPenaltyAmt,
                    interestAmt: interestAmt,
                    previousReturnCreditChargeAmt: previousReturnCreditChargeAmt,
                    totalPaymentAmt: totalPaymentAmt
                });

                props.nextStep();
            }
        });
    }

    return (
        <div>
            {!props.isLoading ? (
                <Container fluid>
                    <br /><br />
                    <h2 className='step-title'><b>Transient Occupancy Tax Payment Form</b> </h2>

                    <hr className="separateLine" />
                    <br />
                    <Form>
                        {/*Rental Property*/}
                        <Row>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>Owner</b></Form.Label>
                                <div>{props.propertyData.ownerName}</div>
                            </Form.Group>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>Cert</b></Form.Label>
                                <div>{props.propertyData.certNumber}</div>
                            </Form.Group>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label">
                                    <b>Due By</b>
                                </Form.Label>
                                <div>
                                    {props.formData.yearQuarter.yearQuarterId !== 0 ?
                                        //(new Date(props.formData.yearQuarter.dueDate)).toISOString().slice(0, 10)
                                        formatDate(props.formData.yearQuarter.dueDate)
                                    : ""
                                }
                                </div>
                            </Form.Group>
                        </Row>
                        <br />
                        <Row>
                            <Form.Group as={Col} md={4} lg={4} >
                            <Form.Label className="label"><b>Address</b></Form.Label>
                                <div>
                                    <span >
                                        <i>Protected per CA Govt. Code Sect. 6254.21</i>
                                    </span>
                                </div>
                                {/*<div>{props.propertyData.mailingAddress}</div>
                                {
                                    props.propertyData.mailingAddress2 &&
                                    <div>{props.propertyData.mailingAddress2}</div>
                                }
                            <div>{props.propertyData.mailingCity + " " + props.propertyData.mailingState + ", " + props.propertyData.mailingZip}</div>*/}
                            </Form.Group>
                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>APN</b></Form.Label>
                                <div>{formatAPN(props.propertyData.apn)}</div>
                            </Form.Group>

                            <Form.Group as={Col} md={4} lg={4} >
                                <Form.Label className="label"><b>Reporting Period</b></Form.Label>
                                {
                                    props.formData.taxReturnId===0?
                                        <SelectBox
                                            id="sbYearQuarter"
                                            items={props.listReportingYearQuarter}
                                            //displayExpr={function (item) { return item.year + ' ' + item.thruDescription}}
                                            displayExpr="displayDesc"
                                            valueExpr="yearQuarterId"
                                            defaultValue={props.formData.yearQuarter.yearQuarterId}
                                            onValueChanged={(e) => props.onValueChangedYearQuarter(e)}
                                            tabIndex={1}
                                            validationStatus={props.hasError("yearQuarter") ? "invalid" : "valid"}
                                            validationError={props.hasError("yearQuarter") ? { message: 'Please select a quarter.' } : ""}
                                            validationMessageMode={"always"}
                                        />
                                        :<div>{props.formData.yearQuarter.displayDesc}</div>
                                }
                               
                            </Form.Group>

                        </Row>
                        <br />
                        <hr className="separateLine" />
                        <br />
                        {/*Total Receipt*/}
                        <Row>
                            <Col md={9} lg={10} >
                                <span>
                                    <b>Total Receipts From Room Rentals:&nbsp;</b>
                                </span>

                                <span >
                                    Receipts for the occupancy of all rooms including receipts through online platforms.
                                    <u>(Receipts include room rate and cleaning fees as the taxable amounts)</u><b>(GROSS EARNINGS)</b>
                                </span>
                                <br /><br />
                                <Row>
                                    <Col md={4} lg={2} ><b>Number of nights:</b></Col>
                                    <Col md={4} lg={2} >
                                        <NumberBox
                                            id="nbNumberOfNights"
                                            step={0}
                                            format="0,###"
                                            min={0} 
                                            defaultValue={props.formData.numberOfNights}
                                            onValueChange={props.onChangeNumberOfNights}
                                            validationStatus={props.hasError("numberOfNights") ? "invalid" : "valid"}
                                            validationError={props.hasError("numberOfNights") ? { message: 'Please enter Number of nights' } : ""}
                                            validationMessageMode={"always"}
                                            tabIndex={3}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                            <Col md={3} lg={2}>
                                <InputGroup >
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <NumberBox
                                        id="nbTotalReceiptAmt"
                                        style={{width:"75%"}}
                                        step={0}
                                        format={amountFormat}
                                        defaultValue={props.formData.totalReceiptsAmt}
                                        onValueChange={props.onChangeTotalReceiptsAmt}
                                        validationStatus={props.hasError("totalReceiptsAmt") ? "invalid" : "valid"}
                                        validationError={props.hasError("totalReceiptsAmt") ? { message: 'Please enter a valid value' } : ""}
                                        validationMessageMode={"always"}
                                        tabIndex={2}
                                />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br />
                        <hr className="separateLine" />
                        <br />
                        {/*Exemptions*/}
                        <Row>
                            <Col md={9} lg={10}>
                                <span>
                                    <b>Exemptions/Exclusions:&nbsp;</b>
                                </span>
                                <span>
                                    Appropriate documentation must be included
                                </span>
                                <br /><br />
                                a. Amount collected for rooms occupied longer than 30 Days continuously<br />
                                b. Amount collected for rooms occupied by government officials or employees on official business; exempt certificate is required, must be attached to the Quarterly Tax Return and is available online at www.mytaxcollector.com
                            </Col>
                            <Col md={3} lg={2}>
                                <InputGroup >
                                    <InputGroup.Text>a.</InputGroup.Text>
                                    <NumberBox
                                        style={{ width: "75%" }}
                                        step={0}
                                        format={amountFormat}
                                        defaultValue={props.formData.exemptLonger30DaysAmt}
                                        onValueChange={props.onChangeExemptLonger30DaysAmt}
                                        tabIndex={4}
                                    />
                                </InputGroup>
                                <InputGroup >
                                    <InputGroup.Text>b.</InputGroup.Text>
                                    <NumberBox
                                        style={{ width: "74%" }}
                                        step={0}
                                        format={amountFormat}
                                        defaultValue={props.formData.exemptGovOffBusAmt}
                                        onValueChange={props.onChangeExemptGovOffBusAmt}
                                        tabIndex={5}
                                    />
                                </InputGroup>

                            </Col>
                        </Row>
                        <Row>
                            {props.existingExemptionsFile.length > 0 && <div className="existingUploadedFile">
                                Uploaded Files:&nbsp;
                                    {props.existingExemptionsFile.map((a, i) =>
                                    <div  key={i}>
                                        <div className="dx-button-content">
                                                <Button1 icon="close" onClick={(e) => props.onClickDelete(e, a.attachmentId, '')} />&nbsp;{a.description}
                                        </div>
                                    </div>
                                )}
                            </div>
                            }
                        </Row>
                        <Row>
                            <div className="uploadContainerTaxReturn">
                             
                                <FileUploader
                                    selectButtonText="Click to Select Files"
                                    labelText="Or Drop Files Here"
                                    multiple={true}
                                    uploadMode={'useButtons'}//{'useForm'}
                                    accept={"*"}
                                    allowedFileExtensions={['.pdf', '.jpg', '.png', '.docx', '.xlsx','.csv']}
                                    onValueChanged={props.onselectedFilesExemptionsChanged}
                                    value={props.formData.selectedFilesExemptions}
                                />
                            </div>
                        </Row>
                        <br />
                        <hr className="separateLine" />
                        <br />
                        <Row>
                            <Col md={9} lg={9}>
                                <span>
                                    <b>Taxable Rents:&nbsp;</b>
                                </span>
                            </Col>
                            <Col md={3} lg={3}>
                                <div style={{ textAlign: "right" }}>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.formData.taxableRentsAmt)}
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <hr className="separateLine" />
                        <br />
                        {/*Online Platform*/}
                        <Row>
                            <Col md={9} lg={9}>
                                <span>
                                    <b>Tax collected and remitted through Online Platform:&nbsp;</b>
                                </span>
                                <span>
                                    <u>(must include host page with gross receipts as stated above and must state the amount of any taxes remitted on your behalf)</u>
                                    <br />*It is your responsibility to remit payment for rentals occurring on all rental platforms such as, but not limited to: HomeAway, VRBO, Expedia, and if no collection or remittance was done by Airbnb.
                                </span>
                            </Col>
                            <Col md={9} lg={3}>

                            </Col>
                        </Row>
                        <Row>
                            {props.onlinePlatForm.map((platform, i) =>
                                <Form.Group as={Col} md="3" lg="2" style={{ marginTop: "5px" }} key={i}>
                                    <CheckBox
                                        defaultValue={props.formData.arrSelectedPlatform.filter(a => a.onlinePlatformId === platform.onlinePlatformId).length > 0}
                                        id={platform.onlinePlatformId}
                                        text={platform.description}
                                        onValueChanged={props.onValueChangedPlatForm}
                                        elementAttr={ {
                                            remittedToCounty: platform.remittedToCounty,
                                            remittedToHost: platform.remittedToHost
                                            }}
                                    />
                                </Form.Group>
                            )}
                        </Row>
                        <br />
                       
                            {props.formData.arrSelectedPlatform.length > 0 &&
                                <Row>

                            <Col md={12} lg={7}>
                                        <Table bordered >
                                            <thead>
                                                <tr>
                                                    <th>Online Platform</th>
                                                    <th>Remitted to County Amount</th>
                                                    <th>Remitted to Host Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.formData.arrSelectedPlatform.map((a, i) =>

                                                    <tr key={a.onlinePlatformId}>
                                                        <td>
                                                            {a.description}{a.onlinePlatformOtherValue === undefined ? "" : ":"}&nbsp;
                                                            {a.onlinePlatformOtherValue === undefined ? "" :
                                                                <TextBox
                                                                        style={{ float: "right" }}
                                                                        defaultValue={a.onlinePlatformOtherValue}
                                                                        onValueChange={(e) => props.onChangePlatformOtherValue(e, a.onlinePlatformId)}
                                                                        maxLength={50}
                                                                    />
                                                             
                                                            }	
                                                        </td>
                                                        <td>
                                                            {a.remittedToCounty&&
                                                            <InputGroup >
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <NumberBox
                                                                    style={{ width: "78%" }}
                                                                    step={0}
                                                                    format={amountFormat}
                                                                    defaultValue={a.onlinePlatformRemittedToCountyAmount}
                                                                    onValueChange={(e) => props.onChangeOnlinePlatformRemittedToCountyAmt(e, a.onlinePlatformId)}
                                                                />
                                                                </InputGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {a.remittedToHost&&
                                                            <InputGroup >
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <NumberBox
                                                                    style={{ width: "78%" }}
                                                                    step={0}
                                                                    format={amountFormat}
                                                                    defaultValue={a.onlinePlatformRemittedToHostAmount}
                                                                    onValueChange={(e) => props.onChangeOnlinePlatformRemittedToHostAmt(e, a.onlinePlatformId)}
                                                                />
                                                                </InputGroup>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                            </Row>}
                       
                        <Row>
                            {props.existingOnlinePlatformFile.length > 0 && <div className="existingUploadedFile">
                                Uploaded Files:&nbsp;
                                    {props.existingOnlinePlatformFile.map((a, i) =>
                                    <div key={i}>
                                        <div className="dx-button-content">
                                                <Button1 icon="close" onClick={(e) => props.onClickDelete(e, a.attachmentId, 'TT')} />&nbsp;{a.description}
                                        </div>
                                    </div>
                                )}
                            </div>
                            }
                            <div className="uploadContainerTaxReturn">
                                <FileUploader
                                    selectButtonText="Click to Select Files"
                                    labelText="Or Drop Files Here"
                                    multiple={true}
                                    uploadMode={'useButtons'}//{'useForm'}
                                    accept={"*"}
                                    allowedFileExtensions={['.pdf', '.jpg', '.png', '.docx', '.xlsx', '.csv']}
                                    onValueChanged={props.onselectedFilesOnlinePlatformChanged}
                                    value={props.formData.selectedFilesOnlinePlatform}
                                    validationStatus={props.hasError("OnlinePlatformFile") ? "invalid" : "valid"}
                                    validationError={props.hasError("OnlinePlatformFile") ? { message: 'Please upload supporting document(s).' } : ""}
                                    validationMessageMode={"always"}
                                />
                                {/*<div className={"inline-errormsg"}>{props.hasError("OnlinePlatformFile") ? "Please upload supporting document(s)." : null}</div>*/}
                            </div>
                        </Row>
                        <br />

                        <div className="action-button-wrapper">
                            <Button variant="secondary" className='action-button' onClick={(e) => onClickBacktoSearch(e)}>Back to Search</Button>
                            <Button variant="primary" className='action-button' onClick={(e) => onClickNext(e)}>Next</Button>
                            <br /><br /><span className={"inline-errormsg"} style={{ fontSize: "16px", margin: "0 3px 0 3px" }}>{props.hasAnyError() ? "Please correct errors message(s)." : null}</span>
                        </div>
                    </Form>
                </Container>


            ) : <LoadPanel visible={true}></LoadPanel>}
        </div>
    );



}

export default TaxReturnForm;