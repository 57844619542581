import React from 'react';
/*import { Form, Button, Col, Container } from 'react-bootstrap';*/
import Form from "react-bootstrap/Form";
//import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
//import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import InputMask from 'react-input-mask';
//import { Box } from 'devextreme-react';
import RegisterCertWrapper from './registercert_wrapper';

class RentalPropDetails extends React.Component {

    constructor() {
        super();

        this.zipEditorOptions = {
            mask: '00000',
            maskRules: {
                X: /[02-9]/,
            },
            maskInvalidMessage: '5 digit zipcode is expected.',
        };

        this.occUnitsOption = {
            mask: '0000',
            maskRules: {
                X: /[02-9]/,
            },
            maskInvalidMessage: 'Max of 4 digits allowed.',
        };
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    saveAndContinue = (e) => {
        e.preventDefault();

        let errors = [];

        // PROPERTY ADDRESS
        //Address
        if (this.props.inputValues.rentalPropAddress === "") {
            errors.push("rentalPropAddress");
        }

        //City
        if (this.props.inputValues.rentalPropCity === "") {
            errors.push("rentalPropCity");
        }

        //Zip Code
        var rentalZipToCheck = this.props.inputValues.rentalPropZip;

        rentalZipToCheck = rentalZipToCheck.replaceAll(" ", "");

        if ((rentalZipToCheck.length < 5) || (rentalZipToCheck.length > 5 && rentalZipToCheck.length < 10)) {
            errors.push("rentalPropZip");
        }

        //Occ Units Number
        if (this.props.inputValues.occUnitsNo === "") {
            errors.push("occUnitsNo");
        }

        // MAILING ADDRESS
        //Address
        if (this.props.inputValues.mailingAddress === "") {
            errors.push("mailingAddress");
        }

        //City
        if (this.props.inputValues.mailingCity === "") {
            errors.push("mailingCity");
        }

        //State
        if (this.props.inputValues.mailingState === "") {
            errors.push("mailingState");
        }        

        //Zip Code
        var mailingZipToCheck = this.props.inputValues.mailingZip;

        mailingZipToCheck = mailingZipToCheck.replaceAll(" ", "");

        if ((mailingZipToCheck.length < 5) || (mailingZipToCheck.length > 5 && mailingZipToCheck.length < 10)) {
            errors.push("mailingZip");
        }

        this.props.handleHasError(errors);

        if (errors.length > 0) {
            return false;
        } else {
            this.props.nextStep();  /*All good*/
        }
    };

    hasError(key) {
        return this.props.hasError(key);
    }

    beforeMaskedValueChange_Mailing = (newState, oldState, userInput) => {
        //debugger;
        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;
     
        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.props.inputValues.mailingZip.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }
     
        return {
            value,
            selection
        };
    }

    beforeMaskedValueChange_Rental = (newState, oldState, userInput) => {
        //debugger;
        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;
     
        //debugger;
        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.props.inputValues.rentalPropZip.endsWith('-')) {
            //debugger;
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }
     
        return {
            value,
            selection
        };
    }

    render() {
        return (
            <RegisterCertWrapper title="Register for a Certificate" subtitle="RENTAL PROPERTY INFORMATION" step= "Step 2 of 4" onBackClick={this.back} onNextClick={this.saveAndContinue}>
             
                <Form>
                    <Row>                        
                        <Form.Group as={Col} md="6" lg="6" controlId="formRentalPropAddress">
                            <Form.Label className="label"><b>Address</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("rentalPropAddress")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="text"
                                defaultValue={this.props.inputValues.rentalPropAddress}
                                name="rentalPropAddress"
                                required
                                onChange={this.props.handleChange}
                                maxLength="255"
                            />
                            <div className={"inline-errormsg"}>{this.hasError("rentalPropAddress") ? "Please enter a value" : null}</div>
                        </Form.Group>
                        <Form.Group as={Col} md="6" lg="6" controlId="formRentalPropAddress2">
                            <Form.Label className="label">Address 2</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={this.props.inputValues.rentalPropAddress2}
                                name="rentalPropAddress2"
                                onChange={this.props.handleChange}
                                maxLength="255"
                            />
                        </Form.Group>
                    </Row>                   
                    <br />
                    <Row>
                        <Form.Group as={Col} md="4" lg="4" controlId="formRentalPropCity">
                            <Form.Label className="label"><b>City</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("rentalPropCity")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="text"
                                defaultValue={this.props.inputValues.rentalPropCity}
                                name="rentalPropCity"
                                required
                                onChange={this.props.handleChange}
                                maxLength="75"
                            />
                            <div className={"inline-errormsg"}>{this.hasError("rentalPropCity") ? "Please enter a value" : null}</div>
                        </Form.Group>  
                        <Form.Group as={Col} md="2" lg="2" controlId="formRentalPropState">
                            <Form.Label className="label"><b>State</b></Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={this.props.inputValues.rentalPropState}
                                name="rentalPropState"
                                required
                                onChange={this.props.handleChange}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="2" lg="2" controlId="formRentalPropZipCode">
                            <Form.Label className="label"><b>Zip Code</b></Form.Label>
                            <InputMask 
                                className={
                                    this.hasError("rentalPropZip")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                formatChars={{
                                    "9": "[0-9]"
                                }}
                                mask="99999-9999"
                                maskChar={null}
                                onChange={this.props.handleRentalZipChange}
                                value={this.props.inputValues.rentalPropZip}
                                beforeMaskedValueChange={this.beforeMaskedValueChange_Rental}
                            ></InputMask>
                            <div className={"inline-errormsg"}>{this.hasError("rentalPropZip") ? "Please enter a value" : null}</div>
                        </Form.Group>
                    </Row>
                    <br />
                    <Row>
                        <Form.Group as={Col} md="4" lg="4" controlId="formAPN">
                            <Form.Label className="label">Assessor Parcel Number</Form.Label>
                            <InputMask className="form-control"
                                formatChars={{
                                    "9": "[0-9]",
                                    '*': '[A-Za-z0-9]'
                                }}
                                mask="9999-999-99-*999"
                                maskChar=" "
                                onChange={this.props.handleAPNChange}
                                value={this.props.inputValues.apn}
                            ></InputMask>
                        </Form.Group>
                        <Form.Group as={Col} md="4" lg="4" controlId="formOccUnitsNo">
                            <Form.Label className="label"><b># of Occupancy Units</b></Form.Label>
                            <InputMask className="form-control"
                                formatChars={{
                                    "9": "[0-9]"
                                }}
                                mask="9999"
                                maskChar={null}
                                onChange={this.props.handleUnitNumberChange}
                                value={this.props.inputValues.occUnitsNo}
                            ></InputMask>
                            <div className={"inline-errormsg"}>{this.hasError("occUnitsNo") ? "Please enter a value" : null}</div>
                        </Form.Group>                        
                    </Row>               
               </Form>
               <br />
               <br />
               <h4 style={{ color: "rgba(0,45,115,.9)"}} ><b>MAILING ADDRESS</b></h4>
               <hr style={{ borderTop: "2px solid rgba(0,45,115,.9)"}} />
               <Form>
                    <Row>                        
                        <Form.Group as={Col} md="6" lg="6" controlId="formMailingAddress">
                            <Form.Label className="label"><b>Address</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("mailingAddress")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="text"
                                defaultValue={this.props.inputValues.mailingAddress}
                                name="mailingAddress"
                                required
                                onChange={this.props.handleChange}
                                maxLength="255"
                            />
                            <div className={"inline-errormsg"}>{this.hasError("mailingAddress") ? "Please enter a value" : null}</div>
                        </Form.Group>
                        <Form.Group as={Col} md="6" lg="6" controlId="formMailingAddress2">
                            <Form.Label className="label">Address 2</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={this.props.inputValues.mailingAddress2}
                                name="mailingAddress2"
                                required
                                onChange={this.props.handleChange}
                                maxLength="255"
                            />
                        </Form.Group>
                    </Row>                   
                    <br />
                    <Row>
                        <Form.Group as={Col} md="4" lg="4" controlId="formMailingCity">
                            <Form.Label className="label"><b>City</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("mailingCity")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="text"
                                defaultValue={this.props.inputValues.mailingCity}
                                name="mailingCity"
                                required
                                onChange={this.props.handleChange}
                                maxLength="75"
                            />
                            <div className={"inline-errormsg"}>{this.hasError("mailingCity") ? "Please enter a value" : null}</div>
                        </Form.Group>
                        <Form.Group as={Col} md="2" lg="2" controlId="formMailingState">
                            <Form.Label className="label"><b>State</b></Form.Label>
                            <Form.Control
                                className={
                                    this.hasError("mailingState")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                type="text"
                                defaultValue={this.props.inputValues.mailingState}
                                name="mailingState"
                                required
                                onChange={this.props.handleChange}
                                maxLength="2"
                            />
                            <div className={"inline-errormsg"}>{this.hasError("mailingState") ? "Please enter a value" : null}</div>
                        </Form.Group>
                        <Form.Group as={Col} md="2" lg="2" controlId="formMailingZipCode">
                            <Form.Label className="label"><b>Zip Code</b></Form.Label>
                            <InputMask 
                                className={
                                    this.hasError("mailingZip")
                                        ? "form-control is-invalid"
                                        : "form-control"
                                }
                                formatChars={{
                                    "9": "[0-9]"
                                }}
                                mask="99999-9999"
                                maskChar={null}
                                onChange={this.props.handleMailingZipChange}
                                value={this.props.inputValues.mailingZip}
                                beforeMaskedValueChange={this.beforeMaskedValueChange_Mailing}
                            ></InputMask>
                            <div className={"inline-errormsg"}>{this.hasError("mailingZip") ? "Please enter a value" : null}</div>
                        </Form.Group>
                    </Row>
                                   
                </Form>           
            </RegisterCertWrapper>
        );
    }
}

export default RentalPropDetails;