import React from 'react';
import { getAdalToken } from '../../_helper/adalToken';

const url = process.env.REACT_APP_API_URL + "/api";

class ExportPdfComponentForTaxReturn extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            objectdata: "",
        }
        this.getPDF = this.getPDF.bind(this);
    }

    componentDidMount() {
        this.getPDF();
    }

    getPDF() {
        //debugger;
        const { taxReturnId } = this.props;
        const myObj = {
            taxReturnId: taxReturnId
        }
        const body = JSON.stringify(myObj);
        //debugger;
        return fetch(url + "/TaxReturn/PreviewTaxReturn", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAdalToken()
            },
            body: body
        })
            .then(response => response.arrayBuffer())
            .then(arrayBufferData => {
                //debugger;

                const blob = new Blob([arrayBufferData], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                // window.open(url, '_blank', 'location=yes,height=650,width=1000,scrollbars=yes,status=yes');

                this.setState({
                    objectdata: url
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const { objectdata } = this.state;
        return (
            <object id="pdfviewer" data={objectdata} type="application/pdf" style={{ width: "100%", height: "100%" }} aria-label="pdf"></object>
        );
    }
}

export default ExportPdfComponentForTaxReturn;