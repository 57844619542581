import React, { useEffect, useState//, useMemo 
} from 'react';
import { Container, Form, Col, Row, Table, Button } from 'react-bootstrap';
import { LoadPanel, TextBox, CheckBox } from 'devextreme-react';
//import { Popup, ToolbarItem } from 'devextreme-react/popup';
import Alert from "react-bootstrap/Alert";
//import { sendPublicRequest } from '../../_helper/util.js';
import { formatDate } from '../../_helper/helpers';
import AuthService from '../../services/AuthService';
import { sendRequest } from '../../_helper/util';
import TaxReturnInfo from './TaxReturn_Info.js';
import { formatAPN } from '../../_helper/helpers';

const url = process.env.REACT_APP_API_URL + "/api";
function TaxReturnReview(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [saveError, setSaveError] = useState();

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const onClickSubmit = (e, PaymentTypeId) => {
        let errors = [];

        if (props.formData.certified === false) {
            errors.push("certified");
        }
        if (!props.formData.preparedBy || props.formData.preparedBy === "") {
            errors.push("preparedBy");
        }
        if (!props.formData.phoneNumber) {
            errors.push("phoneNumber");
        }
        else {
            if (props.formData.phoneNumber.length !== 10) {
                errors.push("phoneNumber");
            }
        }

        if (errors.length > 0) {
            props.setErrors(errors);
            return false;
        }
        else {
            props.setErrors([]);
            //check negative total amount
            //if (props.formData.totalPaymentAmt < 0) {
            //    setSaveError("The Total Payment amount is negative. Please review and correct the information");
            //}
            //else {

            // debugger;
            let formData = new FormData();
            formData.append('TaxReturnId', props.formData.taxReturnId);
            formData.append('PropertyId', props.formData.propertyId);
            formData.append('ReportingYearQuarterId', props.formData.yearQuarter.yearQuarterId);
            formData.append('TotalReceiptsAmt', props.formData.totalReceiptsAmt);
            formData.append('NumberOfNights', props.formData.numberOfNights);
            formData.append('ExemptionsAmt', props.formData.exemptionsAmt);
            formData.append('ExemptLonger30DaysAmt', props.formData.exemptLonger30DaysAmt);
            formData.append('ExemptGovOffBusAmt', props.formData.exemptGovOffBusAmt);
            formData.append('TaxableRentsAmt', props.formData.taxableRentsAmt);
            formData.append('TaxDueAmt', props.formData.taxDueAmt);
            formData.append('TaxCollectedOnlinePlatformAmt', props.formData.taxCollectedOnlinePlatformAmt);
            formData.append('TaxPayableAmt', props.formData.taxPayableAmt);
            formData.append('LatePaymentPenaltyAmt', props.formData.latePaymentPenaltyAmt);
            formData.append('InterestAmt', props.formData.interestAmt);
            formData.append('PreviousReturnCreditChargeAmt', props.formData.previousReturnCreditChargeAmt);
            formData.append('TotalPaymentAmt', props.formData.totalPaymentAmt);
            formData.append('PreparedBy', props.formData.preparedBy);
            formData.append('Title', props.formData.title ?? "");
            formData.append('PhoneNumber', props.formData.phoneNumber);

            formData.append('PaymentTypeId', PaymentTypeId);

            //add OnlinePlatformAmounts 
            for (let i = 0; i < props.formData.arrSelectedPlatform.length; i++) {

                formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformId]`, props.formData.arrSelectedPlatform[i].onlinePlatformId);
                formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformRemittedToCountyAmount]`, props.formData.arrSelectedPlatform[i].onlinePlatformRemittedToCountyAmount);
                formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformRemittedToHostAmount]`, props.formData.arrSelectedPlatform[i].onlinePlatformRemittedToHostAmount);
                if (props.formData.arrSelectedPlatform[i].onlinePlatformOtherValue) {
                    formData.append(`TaxReturnOnlinePlatformAmounts[][${i}][OnlinePlatformOtherValue]`, props.formData.arrSelectedPlatform[i].onlinePlatformOtherValue);
                }
            }

            //add Exemption Files
            for (let i = 0; i < props.formData.selectedFilesExemptions.length; i++) {
                formData.append('FilesExemptions', props.formData.selectedFilesExemptions[i]);
            }
            //add OnlinePlatform Files
            for (let i = 0; i < props.formData.selectedFilesOnlinePlatform.length; i++) {
                formData.append('FilesOnlinePlatform', props.formData.selectedFilesOnlinePlatform[i]);
            }

            setIsSubmitting(true);
            fetch(url + "/TaxReturn/CreateTaxReturn", {
                method: 'POST',
                body: formData

            })

                .then(response => response.json())
                .then(myJson => {
                    if (typeof myJson.message !== "undefined") {
                        setSaveError(myJson.message);
                        setIsSubmitting(false);
                    } else {
                        props.setFormData({
                            ...props.formData,
                            taxReturnId: myJson.taxReturn.taxReturnId
                        });

                        // make as reviewed automatically if the one who fill out tax return is an admin
                        if (AuthService.isAuthorized() && !myJson.taxReturn.hasBeenReviewed) {
                            handleMarkAsReviewed(myJson.taxReturn.taxReturnId, AuthService.getTOTUser().user.employeeId);
                        }

                        //Pay by check
                        if (PaymentTypeId === 2) {
                            // setShowPopup(true);
                            props.nextStep();
                        }
                        else {
                            //todo Pay Online
                            alert("Redirect to GSG");
                            window.location.reload();
                        }

                    }
                })
                .catch(error => {
                    setSaveError(error);
                    setIsSubmitting(false);
                });
            //}
        }
    }

    const onClickBack = (e) => {
        props.prevStep();
    }

    const handleMarkAsReviewed = (taxReturnId, userId) => {
        const endpointUrl = url + `/TaxReturn/MarkAsReviewed/${taxReturnId}/${userId}`;
        return sendRequest(endpointUrl, "POST", '').then(res => { });
    }

    return (
        <div>
            {!isLoading ? (
                <Container fluid>
                    <div>
                        <br />
                        <br />
                        <h2 className='step-title'><b>Transient Occupancy Tax Payment Form - Review</b> </h2>
                        <h5>**Confirm the following details are correct**</h5>
                        <hr className="separateLine" />
                        <br />
                        <Form>
                            {/*Rental Property*/}
                            <Row>
                                <Form.Group as={Col} md={4} lg="4" >
                                    <Form.Label className="label"><b>Owner</b></Form.Label>
                                    <div>{props.propertyData.ownerName}</div>
                                </Form.Group>
                                <Form.Group as={Col} md={4} lg="4" >
                                    <Form.Label className="label"><b>Cert</b></Form.Label>
                                    <div>{props.propertyData.certNumber}</div>
                                </Form.Group>
                                <Form.Group as={Col} md={4} lg="4" >
                                    <Form.Label className="label">
                                        <b>Due By</b>
                                    </Form.Label>
                                    <div>
                                        {
                                            //(new Date(props.formData.yearQuarter.dueDate)).toISOString().slice(0, 10)
                                            formatDate(props.formData.yearQuarter.dueDate)
                                        }
                                    </div>
                                </Form.Group>
                            </Row>
                            <br />
                            <Row>
                                <Form.Group as={Col} md={4} lg="4" >
                                    <Form.Label className="label"><b>Address</b></Form.Label>
                                    <div>{props.propertyData.mailingAddress}</div>
                                    {
                                        props.propertyData.mailingAddress2 &&
                                        <div>{props.propertyData.mailingAddress2}</div>
                                    }
                                    <div>{props.propertyData.mailingCity + " " + props.propertyData.mailingState + ", " + props.propertyData.mailingZip}</div>
                                </Form.Group>
                                <Form.Group as={Col} md={4} lg="4" >
                                    <Form.Label className="label"><b>APN</b></Form.Label>
                                    <div>{formatAPN(props.propertyData.apn)}</div>
                                </Form.Group>

                                <Form.Group as={Col} md={4} lg="4" >
                                    <Form.Label className="label"><b>Reporting Period</b></Form.Label>
                                    <div>{props.formData.yearQuarter.displayDesc}</div>
                                </Form.Group>
                            </Row>
                            
                            <br />
                            <hr className="separateLine" />
                            <br />
                            <TaxReturnInfo 
                                data={props.formData} 
                                taxRate={props.formData.yearQuarter.taxRate} 
                                penaltyAmt={props.formData.latePaymentPenaltyAmt} 
                                interestAmt={props.formData.interestAmt} 
                                taxReturnOnlinePlatform={props.formData.arrSelectedPlatform}
                                displayUploadedFiles={true}
                            />
                            <br />
                            <hr className="separateLine" />
                            <br />

                            {/*Certify*/}
                            <Row>
                                <Col md={12} lg={12}>
                                    <CheckBox
                                        text="I certify under penalty of perjury that the foregoing is true and correct to the best of my knowledge"
                                        defaultValue={props.formData.certified}
                                        onValueChange={props.onValueChangeCertified}
                                        validationStatus={props.hasError("certified") ? "invalid" : "valid"}
                                        validationError={props.hasError("certified") ? { message: 'Please check the above before submitting' } : ""}
                                        validationMessageMode={"always"}
                                    />
                                </Col>
                            </Row>
                            <div className="border rounded" style={{ padding: "20px", margin: "10px 0px" }}>
                                <Row>
                                    <Form.Group as={Col} md={6} lg={6} >
                                        <Form.Label ><b>Prepared By</b></Form.Label>
                                        <TextBox
                                            defaultValue={props.formData.preparedBy}
                                            onValueChange={props.onChangePreparedBy}
                                            maxLength={255}
                                            validationStatus={props.hasError("preparedBy") ? "invalid" : "valid"}
                                            validationError={props.hasError("preparedBy") ? { message: 'Please enter Prepared By' } : ""}
                                            validationMessageMode={"always"}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} lg={6} >
                                        <Form.Label >Title</Form.Label>
                                        <TextBox
                                            defaultValue={props.formData.title}
                                            onValueChange={props.onChangeTitle}
                                            maxLength={100}
                                        />
                                    </Form.Group>

                                </Row>
                                <Row>
                                    <Form.Group as={Col} md={6} lg={6} >
                                        <Form.Label ><b>Phone Number</b></Form.Label>
                                        {/*<InputMask
                                        className={"form-control"}
                                        formatChars={{
                                            "9": "[0-9]"
                                        }}
                                        mask="+1 (999) 999-9999"
                                        maskChar=" "
                                        onChange={onChangePhoneNumber}
                                        value={props.formData.phoneNumber}
                                    ></InputMask>*/}
                                        <TextBox
                                            mask="+1 (000) 000-0000"
                                            defaultValue={props.formData.phoneNumber}
                                            onValueChange={props.onChangePhoneNumber}
                                            maxLength={100}
                                            validationStatus={props.hasError("phoneNumber") ? "invalid" : "valid"}
                                            validationError={props.hasError("phoneNumber") ? { message: 'The phone must have a correct USA phone format' } : ""}
                                            validationMessageMode={"always"}
                                        />
                                    </Form.Group>
                                </Row>
                            </div>

                            {saveError && <Alert key="danger" variant="danger" >
                                {saveError}
                            </Alert>
                            }

                        </Form>
                    </div>
                    <div className={"action-button-wrapper"}>
                        <Button variant="secondary" className="action-button" onClick={(e) => onClickBack(e)}>Back</Button>

                        <Button variant="primary" className="action-button" onClick={(e) => onClickSubmit(e, 2)}
                            disabled={isSubmitting} >
                            {isSubmitting ? "Saving..." : "Save & Print"}
                        </Button>
                        {/*
                        Or*/}
                        {/*
                        <Button variant="primary" className={'action-button' + ' ' + 'no-print'} onClick={(e) => onClickSubmit(e, 1)}>Pay Online</Button>	*/}
                    </div>
                 
                </Container>
            ) : <LoadPanel visible={true}></LoadPanel>}
        </div>
    );
}


export default TaxReturnReview;