import React from 'react';
import { Button as ButtonBS } from 'react-bootstrap';
import "../../utils/bootstrap/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import SearchCertWrapper from './searchcert_wrapper';
import { sendPublicRequest } from '../../_helper/util.js';
import { withRouter } from 'react-router-dom';
import DataGrid,
{
    Column,
    //Lookup,
    Editing,
    Button,
    Paging,
    FilterRow
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

const url = process.env.REACT_APP_API_URL + "/api";

class SearchCertNumber extends React.Component {  
    
    constructor(props) {
        super(props);

        this.state = {
            unpaidTaxReturnsData: new CustomStore({
                key: 'taxReturnId',
                load: this.loadUnpaidTaxReturns
            }),
            divisionPhoneNumber:""
        };
    }

    componentDidMount() {
        //debugger;
        sendPublicRequest(url + "/TaxReturn/CheckNewTaxReturnYearQuarter?propertyId=" + this.props.inputValues.propertyId, 'GET', '').then(myJson => {
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                this.setState({
                    hasNewTaxReturnYearQuarter: myJson.hasNewTaxReturnYearQuarter,
                    divisionPhoneNumber: myJson.divisionPhoneNumber
                });

            }
        });

    }
    
    loadUnpaidTaxReturns = () => {
        //debugger;
        return sendPublicRequest(url + "/TaxReturn/GetUnpaidTaxReturnsByPropertyId?propertyId=" + this.props.inputValues.propertyId, 'GET', '').then(myJson => {
            //debugger;
            if (typeof myJson.message !== "undefined") {
                throw myJson.message;
            }
            else {
                //debugger;
                return myJson.unpaidTaxReturnList;
            }
        });
    }

    back = (e) => {
        //this.props.history.push("/TransientOccupancyTax");
        e.preventDefault();
        this.props.prevStep();
    }

    //saveAndContinue = (e) => {
    //    e.preventDefault();

    //    let errors = [];
        
    //    if (this.props.inputValues.certNumber === "") {
    //        errors.push("enteredCertNumber");
    //    }
       
    //    this.props.handleHasError(errors);

    //    if (errors.length > 0) {
    //        return false;
    //    } else {
    //        // Retrieve the property ID if cert exists, otherwise error.
    //        this.retrievePropertyInfoByCertNumber(errors).then((errors) =>
    //        {
    //            if (errors.length !== 0) {
    //                this.props.handleHasError(errors);
    //            } else {
    //                this.props.nextStep();
    //            }       
    //        })
    //    }
    //};

    //hasError(key) {
    //    return this.props.hasError(key); 
    //}

    //retrievePropertyInfoByCertNumber = (errors) => {
    //    //debugger;
    //    return sendPublicRequest(url + "/Property/RetrievePropertyIdUnpaidTaxReturnCountByCert?certNumber=" + this.props.inputValues.certNumber, 'GET', '').then(myJson => {
    //        if (typeof myJson.message !== "undefined") {
    //            throw myJson.message;
    //        }
    //        else {
    //            //debugger;
    //            if(myJson.PropertyId === undefined)
    //            {
    //                errors.push("certNumberNotFound");
    //            } else {
    //                this.props.inputValues.propertyId = myJson.PropertyId;
    //                this.props.inputValues.unpaidTaxReturnCount = myJson.UnpaidTaxReturnCount;

    //                if(this.props.inputValues.propertyId === 0)
    //                {
    //                    errors.push("certNumberNotFound");
    //                }
    //            }
                
    //            return errors;
    //        }
    //    });
    //}

    selectTaxReturn = (e) => {
        // debugger;        
        var id = e.row.data.taxReturnId;
        this.props.handleSelectedTaxReturnIdChange(id);
        //this.props.history.push("/TOTReturn/" + this.props.inputValues.propertyId + "/" + id);
        this.props.gotoTOTTaxReturn(this.props.inputValues.propertyId, id);
        //alert(id);
    }

    createNewTaxReturn = (e) => {
        //alert('Create new Tax Return.  unpaidtaxreturnlist.js line 119.');
        //this.props.history.push("/TOTReturn/" + this.props.inputValues.propertyId + "/0/");
        this.props.gotoTOTTaxReturn(this.props.inputValues.propertyId, 0);
    }

    render() {
        const { unpaidTaxReturnsData, hasNewTaxReturnYearQuarter,divisionPhoneNumber } = this.state;
        return (
            <SearchCertWrapper title='Search for a Certificate' subtitle="" step="" onBackClick={this.back} onSubmitClick={this.saveAndSubmit}>
                
                <Container className="pt-1">
                    <div className="p-5 rounded text-left border border-secondary" style={{}}>
                        <Container>
                            <h3 className='mb-3'>Unpaid Tax Return(s) exist.  You can choose to continue an existing Tax Return, by clicking on "Select", or start a new one by clicking the "New Tax Return" button.</h3>
                            <br />
                        </Container>
                        <Container>
                            <center>
                                <DataGrid
                                    id="gridUnpaidTaxReturns"
                                    dataSource={unpaidTaxReturnsData}
                                    allowColumnReordering={false}
                                    showBorders={true}
                                    ref={ref=>this.dataGrid=ref}                                                        
                                >

                                    <FilterRow visible={false} />
                                    <Paging enabled={true} />
                                    

                                    <Editing
                                        mode="row"
                                        allowUpdating={false}
                                        allowDeleting={false}
                                        allowAdding={false} />

                                    
                                    <Column dataType="string"
                                        dataField="period"
                                        caption="Period"
                                        width={200}
                                    >
                                    </Column>
                                    <Column dataField="amountDue" dataType="string" caption="Amount Due" width={150} />

                                    <Column type="buttons">
                                        <Button text="Select" hint="Select" onClick={this.selectTaxReturn} />
                                    </Column>
                                </DataGrid>
                            </center>
                        </Container>
                        <Container>
                            <div className={"inline-infomsg"}>{!hasNewTaxReturnYearQuarter ? "There are outstanding unpaid Tax Returns but no Reporting Period to file - Cannot create a New Tax Return.   If you have any questions, please call: " + divisionPhoneNumber + "." : ""}</div>
                        </Container>
                        <Container>
                            <div className='text-end'>
                                <ButtonBS variant="light" size="lg" className='m-1' style={{ backgroundColor: "#003890", color: "white" }}
                                    onClick={this.createNewTaxReturn}
                                    disabled={!hasNewTaxReturnYearQuarter}
                                >
                                    Create new tax return
                                </ButtonBS>
                            </div>
                        </Container>                        
                    </div>
                </Container>
            </SearchCertWrapper>            
        )        
    }
}

export default withRouter(SearchCertNumber);