import React, { Component } from 'react';

import SearchCertMain from '../../components/searchcertnumber/searchcert_main'
import PageHeader from '../../components/header/PageHeader/PageHeader';

class CertificateRegistrationPage extends Component {

    render() {
        return (
            <div className='content-block'>
               <PageHeader header="TOT Return"></PageHeader>
                <SearchCertMain />
            </div>
        );
    }
}

export default CertificateRegistrationPage;
