import React from 'react';
import './searchcert_main.scss';
import CertNumberSearch from "./searchcertnumber";
import APNSearch from "./searchapnnumber";
import UnpaidTaxReturnList from "./unpaidtaxreturnlist";
//import { sendPublicRequest } from '../../_helper/util.js';
import "../../utils/bootstrap/bootstrap.min.css";
import { withRouter } from 'react-router-dom';
//import TaxReturnForm from "../TOTTaxReturn/TaxReturn_Form";
//import TaxReturnReview from "../TOTTaxReturn/TaxReturn_Review";
import TOTTaxReturn from "../TOTTaxReturn/TOTTaxReturn";


require('dotenv').config({ debug: false })
//const url = process.env.REACT_APP_API_URL + "/api";

class SearchCert_Main extends React.Component {

    state = {
        step: 1,
        certNumber: '',
        apn: '',
        propertyId: 0,
        unpaidTaxReturnCount: 0,
        errors: [],
        saveErrorEncountered: false,
        saveError: '',
        selectedTaxReturnId: 0
    }

    nextStep = () => {
        const { step, propertyId, unpaidTaxReturnCount } = this.state
        //debugger;
        switch(step)
        {
            case 1:
            case 99:
                // Check to see if there are unpaid tax returns.  If there are, redirect to the unpaid tax return list page.
                if(unpaidTaxReturnCount !== 0)
                {
                    this.setState({
                        step: 2
                    })
                } else {
                    // If no unpaid Tax Returns, go right into a new Tax Return
                    //this.props.history.push("/TransientOccupancyTax");
                    //debugger;
                    //alert("searchcert_main line 44.  Add code for new Tax Return.")
                    //debugger;
                    //this.props.history.push("/TOTReturn/" + propertyId + "/0/");
                    this.gotoTOTTaxReturn(propertyId, 0);
                }
                break;
            default:
                this.setState({
                    step: step + 1
                })
        }
    }
  
    prevStep = () => {
        const { step } = this.state;
        switch(step) {
            case 99: 
                this.setState({
                    step: 1
                })
                break;
            default:
                this.setState({
                    step: step - 1
                })
        }                
    }

    gotoTOTTaxReturn = (propertyId,taxReturnId) => {
        this.setState({
            propertyId: propertyId,
            selectedTaxReturnId: taxReturnId,
            step: 3
        })
    }
    backtoSearch = () => {
        this.setState({
            step: 1
        })
    }

    searchAPN = () => {
        this.setState({
            step: 99
        })
    }

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    }

    handleHasError = (errors) => {
        this.setState({ errors })
    }

    handleSetStepTo2 = () => {
        //debugger;
        this.setState({
            step: 2
        })
    }

    handleCertNumberChange = (event) => {
        this.setState({ certNumber: event.target.value });

        var index_CertNumber = this.state.errors.indexOf("enteredCertNumber");
        var index_CertClosed = this.state.errors.indexOf("certClosed");
        var index_CertNotFound = this.state.errors.indexOf("certNumberNotFound");
        var index_NoNewQuarter = this.state.errors.indexOf("HasNoNewTaxReturnQuarter");

        if ((index_CertNumber > -1) || (index_CertClosed > -1) || (index_CertNotFound > -1) || (index_NoNewQuarter > -1)) {

            if (index_CertNumber > -1)
                { this.state.errors.splice(index_CertNumber, 1); }
                
            if (index_CertClosed > -1)
                { this.state.errors.splice(index_CertClosed, 1); } 

            if (index_CertNotFound > -1)
                { this.state.errors.splice(index_CertNotFound, 1); } 

            if (index_NoNewQuarter > -1)
                { this.state.errors.splice(index_NoNewQuarter, 1); }  
        }
    }

    handleAPNChange = (event) => {
        this.setState({ apn: event.target.value });

        var index_APN = this.state.errors.indexOf("enteredAPN");
        var index_CertClosed = this.state.errors.indexOf("certClosed");
        var index_APNNotFound = this.state.errors.indexOf("apnNotFound");
        var index_NoNewQuarter = this.state.errors.indexOf("HasNoNewTaxReturnQuarter");

        if ((index_APN > -1) || (index_CertClosed > -1) || (index_APNNotFound > -1) || (index_NoNewQuarter > -1)) {

            if (index_APN > -1)
                { this.state.errors.splice(index_APN, 1); }
                
            if (index_CertClosed > -1)
                { this.state.errors.splice(index_CertClosed, 1); } 

            if (index_APNNotFound > -1)
                { this.state.errors.splice(index_APNNotFound, 1); } 

            if (index_NoNewQuarter > -1)
                { this.state.errors.splice(index_NoNewQuarter, 1); }  
        }
    }

    handlePropertyIdChange = (value) => {
        this.setState({ propertyId: value });
    }

    handleUnpaidTaxReturnCountChange = (value) => {
        this.setState({ unpaidTaxReturnCount: value });
    }

    handleSelectedTaxReturnIdChange = (value) => {
        this.setState({ selectedTaxReturnId: value });
    }

    handleChange = (event) => {
        //debugger;
        this.setState({ [event.target.name]: event.target.value });
        
        //if (event.target.name != 'paperlessComm') {
        //    this.setState({ [event.target.name]: event.target.value })
        //} else {
        //    this.setState({ paperlessCommChecked: !this.state.paperlessCommChecked });
        //}
    }

    handleSubmit = () => {
        this.setState({ saveError: '' });
        this.setState({ saveErrorEncountered: false });

        //const myObj = {
        //    Apn: this.state.apn
        //};
        //debugger;
        //const body = JSON.stringify(myObj);

        //sendPublicRequest(url + '/Registration/????', 'POST', body).then(myJson => {
        //    if (typeof myJson.message !== "undefined") {
        //        debugger;
        //        this.setState({ saveError: myJson.message });
        //        this.setState({ saveErrorEncountered: true });
        //    }
        //    else {
        //        this.nextStep();
        //    }
        //});
    }


    render() {
        const { step, certNumber, apn, saveErrorEncountered, saveError, propertyId, unpaidTaxReturnCount, selectedTaxReturnId } = this.state;
        const inputValues = { certNumber, apn, saveErrorEncountered, saveError, propertyId, unpaidTaxReturnCount, selectedTaxReturnId };
        switch (step) {
            case 1:
                return <CertNumberSearch
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleCertNumberChange={this.handleCertNumberChange}
                    handlePropertyIdChange={this.handlePropertyIdChange}
                    handleUnpaidTaxReturnCountChange={this.handleUnpaidTaxReturnCountChange}
                    inputValues={inputValues}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                    searchAPN={this.searchAPN}
                />
            case 99:
                return <APNSearch
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleAPNChange={this.handleAPNChange}
                    handlePropertyIdChange={this.handlePropertyIdChange}
                    handleUnpaidTaxReturnCountChange={this.handleUnpaidTaxReturnCountChange}
                    inputValues={inputValues}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                    handleSetStepTo2={this.handleSetStepTo2}
                />
            case 2:
                return <UnpaidTaxReturnList
                    prevStep={this.prevStep}
                    inputValues={inputValues}
                    hasError={this.hasError}
                    handleHasError={this.handleHasError}
                    handleSelectedTaxReturnIdChange={this.handleSelectedTaxReturnIdChange}
                    gotoTOTTaxReturn={this.gotoTOTTaxReturn}
                />
            case 3:
                //this.props.history.push("/TOTReturn/" + propertyId + "/" + selectedTaxReturnId + "/");
                return <TOTTaxReturn
                    propertyId={propertyId}
                    taxReturnId={selectedTaxReturnId}
                    backtoSearch={this.backtoSearch} />
            default:
                return;
        }
    }
}

export default withRouter(SearchCert_Main);