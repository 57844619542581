import React from 'react';

class CertAppNotes extends React.Component {

    // constructor(props) {
    //    super(props);
    // }

    render() {
        return (
            <div className='row'>
                <div className='col-lg-8'>
                    <div className='card'>
                        <div className='card-body' >
                            <p className='fs-6 lh-3 mb-0' >
                                {`The document can be printed either`}
                                <ul className='mb-0'>
                                    <li>{`here using the print button on the PDF preview, or `}</li>
                                    <li>{`under Properties > Edit targeted certificate > Attachment section`}</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );  
    }
    
}

export default CertAppNotes;