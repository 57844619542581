import React from 'react';
import PageHeader from '../../components/header/PageHeader/PageHeader';
import QuarterList from '../../components/quarters/quarters_overview';

function Quarters(props) {
    return (
        <div className="content-block">
        
             <PageHeader header="Manage Quarters" />
             <QuarterList></QuarterList>
        </div>
    );
}

export default Quarters;